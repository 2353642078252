import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderAddRoutingModule } from './order-add-routing.module';
import { OrderAddComponent } from './order-add.component';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from '@app/shared/shared.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    NgbModule,
    OrderAddRoutingModule,
    Ng5SliderModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    SharedModule,
    NgZorroAntdModule
  ],
  declarations: [OrderAddComponent],
  providers: [DatePipe, { provide: NZ_I18N, useValue: en_US }],
  bootstrap: [OrderAddComponent]
})
export class OrderAddModule {}
