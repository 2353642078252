import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EnvironmentService } from '@app/environment.service';
import { Subscription } from 'rxjs';
import * as Constant from '../../constants/api';
import { OrderService } from '../orders/order.service';
import { CustomService } from '../services/custom.service';

@Component({
  selector: 'app-lead-marketplace',
  templateUrl: './lead-marketplace.component.html',
  styleUrls: ['./lead-marketplace.component.scss']
})
export class LeadMarketplaceComponent implements OnInit {
  accessToken: string;
  showLoadingIndicator = true;
  error: any;
  ROOT_URL: any = EnvironmentService.config.apiUrl;
  browseFringerprint: string;
  isAgree: boolean;
  subscription: Subscription;
  clientLogo: string | undefined;
  loadingLogo: string | undefined;
  isAllstateMA: boolean = false;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private service: CustomService
  ) {}

  ngOnInit() {
    this.accessToken = this.activatedRoute.snapshot.paramMap.get('accessToken');
    localStorage.setItem('accessToken', this.accessToken);

    if (this.accessToken != null && this.accessToken.length < 36) {
      let navigationExtras: NavigationExtras = {
        queryParams: { error: Constant.GUID_INCORRECT_ERROR_MSG }
      };
      this.router.navigate(['/error-page'], navigationExtras);
      return false;
    }

    this.orderService.getClietLogo().subscribe((data: any) => {
      if (data != null) {
        localStorage.setItem('clientLogo', data);
      } else {
        localStorage.setItem('clientLogo', Constant.DEFAULTLOGO);
      }
      this.clientLogo = localStorage.getItem('clientLogo');
    });

    this.orderService.IsAllstateMasterAccount().subscribe((data: any) => {
      if (data != null) {
        localStorage.setItem('isAllstateMA', data);
      } else {
        localStorage.setItem('isAllstateMA', 'false');
      }
      this.isAllstateMA = localStorage.getItem('isAllstateMA') == 'true';

      if (this.isAllstateMA) {
        localStorage.setItem('loadingLogo', Constant.ALLSTATELOADINGLOGO);
      } else {
        localStorage.setItem('loadingLogo', Constant.DEFAULTLOADINGLOGO);
      }
      this.loadingLogo = localStorage.getItem('loadingLogo');

      setTimeout(() => {
        this.checkAgreement();
      }, 2000);
    });

    this.subscription = this.service.currentTermAgreement.subscribe(message => {
      if (message == 'agreed') {
        this.saveAgreement();
      }
      if (message == 'disagree') {
        window.close();
      }
    });
  }
  getBrowserFringerprint(): string {
    return window.navigator.userAgent;
  }

  isGuid(str: string): boolean {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(str);
  }

  openSummaryOrder() {
    if (this.isGuid(this.accessToken)) {
      this.http.get(EnvironmentService.config.apiUrl + Constant.ORDER_URL + this.accessToken).subscribe(
        responseData => {
          if (responseData['Success']) {
            this.showLoadingIndicator = false;
            window.location.href = responseData['UrlToRedirect'];
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.error = err.error;
          this.orderService.returnToErrorPage(err.error);
        }
      );
    } else {
      this.orderService.returnToErrorPage('Not permitted.');
    }
  }
  checkAgreement() {
    this.http.get(this.ROOT_URL + Constant.CHECK_AGREEMENT + this.accessToken).subscribe(
      responseData => {
        this.showLoadingIndicator = false;
        if (responseData) {
          this.service.setTermAgreement('openAgree');
        } else {
          this.openSummaryOrder();
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.error = err.error;
        this.orderService.returnToErrorPage(err.error);
      }
    );
  }
  saveAgreement() {
    this.http
      .post(this.ROOT_URL + Constant.SAVE_AGREEMENT + this.accessToken + '/', JSON.stringify(this.browseFringerprint), {
        headers: { 'Content-Type': 'application/json; charset=utf-8' }
      })
      .subscribe(
        responseData => {
          if (responseData) {
            this.showLoadingIndicator = false;
            this.openSummaryOrder();
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.error = err.error;
          this.orderService.returnToErrorPage(err.error);
        }
      );
  }
}
