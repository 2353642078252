//Order Class Model
export class order {
  constructor(
    public OrderId: string,
    public OrderName: string,
    public LeadSources: string,
    public SourceType: string, //Order Type
    public CreatedDate: Date,
    public LeadType: string, // Product Type
    public AverageLeads: number,
    public AvgDailyCost: number,
    public Spent: number,
    public StartDate: Date,
    public EndDate: Date,
    public DailyCap: number,
    public HourlyCap: number,
    public Status: string,
    public Error: string,
    public TimeZone: string,
    public MaxCallCurrency: number,
    public TransferNumber: number,
    public ageFrom: number,
    public ageTo: number,
    public SendEmailNotification: boolean,
    public SendSMSNotification: boolean
  ) {}
}
export class orderData {
  public disableStatusFlag: boolean;
  public OrderId: string;
  public OrderName: string;
  public LeadSources: string;
  public LeadSourcesNames: string;
  public SourceType: string;
  public LastModifiedDate: string;
  public CreatedDate: Date;
  public LeadType: string;
  public AverageLeads: number;
  public AvgDailyCost: number;
  public Spent: number;
  public Status: string;
  public Error: string;
  public OrderLocationList: any[];
  public StartDate: Date;
  public EndDate: Date;
  public State: string;
  public County: string;
  public ZipCode: string;
  public OffSet: string;
  public Timeslots: any[];
  public DailyCap: number;
  public HourlyCap: number;
  public AgeFrom: number;
  public AgeTo: number;
  public MaximumConcurrency: number;
  public TransferNumber: number;
  public Metadata: string;
  public stateList: string[];
  public SendEmailNotification: boolean;
  public SendSMSNotification: boolean;
}
