import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@app/environment.service';

/**
 * Prefixes all requests with `EnvironmentService.config.apiUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: EnvironmentService.config.apiUrl + request.url });
    }
    return next.handle(request);
  }
}
