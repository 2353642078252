import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import * as Constant from '../../constants/api';

@Injectable({
  providedIn: 'root'
})
export class CustomService {
  //<!--Seema(23 / 10 / 2019) - fixes for copy tabs-- >
  copyOrderId: any;
  editOrderId: any;
  editOrderName: any;
  copyOrderName: any;
  leadType: any;
  //<!--Seema(23 / 10 / 2019) - fixes for copy tabs-- >
  private messageSource = new BehaviorSubject('none');
  private copyOrderSource = new BehaviorSubject('New Order');
  private successMessageSource = new BehaviorSubject('');
  private copySuccessMessage = new BehaviorSubject('');
  private editSuccessMessage = new BehaviorSubject('');
  private orderFormType = new BehaviorSubject('');
  public copyOrderDetails: FormGroup;
  private deliveryMessageSource = new BehaviorSubject('');
  private termAgreementSource = new BehaviorSubject('');

  private logoSource = new BehaviorSubject(Constant.DEFAULTLOGO);
  currentLogoIcon = this.logoSource.asObservable();

  setLogo(message: string) {
    this.logoSource.next(message);
  }

  currentMessage = this.messageSource.asObservable();
  copyOrderData = this.copyOrderSource.asObservable();
  currentSuccessMessage = this.successMessageSource.asObservable();
  copyCurrentSuccessMessage = this.copySuccessMessage.asObservable();
  editCurrentSuccessMessage = this.editSuccessMessage.asObservable();
  currentFormType = this.orderFormType.asObservable();
  currentDeliveryMessage = this.deliveryMessageSource.asObservable();
  currentTermAgreement = this.termAgreementSource.asObservable();
  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  setcopyOrderData(copyOrderData: string) {
    this.copyOrderSource.next(copyOrderData);
  }

  setSuccessMessage(message: string) {
    this.successMessageSource.next(message);
  }
  setDeliveryMessage(message: string) {
    this.deliveryMessageSource.next(message);
  }

  setCopySuccessMessage(message: string) {
    this.copySuccessMessage.next(message);
  }
  setEditSuccessMessage(message: string) {
    this.editSuccessMessage.next(message);
  }
  setFormType(message: string) {
    this.orderFormType.next(message);
  }
  setCopyOrderById(orderId: string) {
    this.copyOrderId = orderId;
  }
  getCopyOrderById() {
    return this.copyOrderId;
  }
  setEditOrderId(orderId: string) {
    this.editOrderId = orderId;
  }
  getEditOrderId() {
    return this.editOrderId;
  }
  setEditOrderName(orderName: string) {
    this.editOrderName = orderName;
  }
  getEditOrderName() {
    return this.editOrderName;
  }
  setCopyOrderName(orderName: string) {
    this.copyOrderName = orderName;
  }
  getCopyOrderName() {
    return this.copyOrderName;
  }
  setLeadType(type: any) {
    this.leadType = type;
  }
  getLeadType() {
    return this.leadType;
  }
  setTermAgreement(agreement: string) {
    this.termAgreementSource.next(agreement);
  }
}
