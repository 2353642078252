import { Component, OnInit } from '@angular/core';
import { CustomService } from '@app/services/custom.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clientLogo: any;
  isAllstateMA: boolean = false;
  constructor(private _customService: CustomService) {}

  ngOnInit() {
    this.isAllstateMA = localStorage.getItem('isAllstateMA') == 'true';
    this.clientLogo = localStorage.getItem('clientLogo');
  }
}
