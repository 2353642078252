import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import * as Constant from '../../constants/api';
import { EnvironmentService } from '@app/environment.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  accessToken: any;
  leadsource: any;
  locationlist: any;
  ROOT_URL: any = EnvironmentService.config.apiUrl;

  constructor(private httpService: HttpClient, private router: Router) {}

  /* open get Account funding balance */

  public getAccountFundingBalance() {
    this.accessToken = localStorage.getItem('accessToken');
    return this.httpService.get(this.ROOT_URL + Constant.ACCOUNT_BALANCE_URL + this.accessToken);
  }

  /* end get Account funding balance */

  /* open get order summary data */
  public getLeadOrder(accessToken: string) {
    this.accessToken = localStorage.getItem('accessToken');
    return this.httpService.get(this.ROOT_URL + Constant.ORDER_SUMMARY_URL + this.accessToken);
  }
  /* end get order summary data */

  /* open get lead sources data */

  public getOrderTypes(userToken: any) {
    return this.httpService.get(this.ROOT_URL + Constant.GetOrderType + localStorage.getItem('accessToken'));
  }

  /* end get lead sources data */

  /* open get location data */

  public getLocationData(isCallLead: boolean = false) {
    return this.httpService.get(
      this.ROOT_URL + Constant.LOCATIONDATA + localStorage.getItem('accessToken') + '/' + isCallLead
    );
  }
  public getBrokerInformation() {
    return this.httpService.get(this.ROOT_URL + Constant.ACCOUNT_PHONE_URL + localStorage.getItem('accessToken'), {
      responseType: 'text'
    });
  }
  /* end get location data */

  /* open update order status*/

  public updateOrderStatus(status: string, orderId: any) {
    this.accessToken = localStorage.getItem('accessToken');
    let orderStatusData = {
      Status: status,
      OrderId: orderId,
      AccessToken: this.accessToken
    };
    return this.httpService.post(this.ROOT_URL + Constant.UPDATESTATUS, JSON.stringify(orderStatusData), {
      headers: { 'Content-Type': 'application/json; charset=utf-8' }
    });
  }
  /* end update order status*/

  /* open create order data */

  public createOrder(orderData: any, leadSource: any, locationList: any, locationDetail: any) {
    this.leadsource = leadSource.join(',');
    return this.httpService.post(
      this.ROOT_URL + Constant.SAVEORDER,
      JSON.stringify({
        orderJson: orderData.getRawValue(),
        LeadSources: this.leadsource,
        LeadLocationList: JSON.stringify(locationList),
        accessToken: this.getAccessTokenFromStorage(),
        locationDetail: locationDetail
      }),
      {
        headers: { 'Content-Type': 'application/json; charset=utf-8' }
      }
    );
  }

  /* end create order data */

  /* open get order by id data */

  public getOrderById(userToken: any, orderId: string) {
    return this.httpService.get(this.ROOT_URL + Constant.ORDERGETBYID + '/' + userToken + '/' + orderId, {
      responseType: 'text'
    });
  }

  /* end get order by id  data */
  public getAccessTokenFromStorage() {
    return (this.accessToken = localStorage.getItem('accessToken'));
  }

  /* end get  county data */
  public getCountyData(state: any) {
    return this.httpService.get(this.ROOT_URL + Constant.GETCOUNTY + state + '/' + localStorage.getItem('accessToken'));
  }
  /* end get  county data */
  public getZipData(county: string, state: string) {
    return this.httpService.post(
      this.ROOT_URL + Constant.GETZIP + this.getAccessTokenFromStorage(),
      JSON.stringify({
        CountyName: county,
        State: state,
        ZipCode: null
      }),
      {
        headers: { 'Content-Type': 'application/json; charset=utf-8' }
      }
    );
  }

  /* open get  statecounty data */

  public getStateCountyData(zipCode: any) {
    return this.httpService.get(this.ROOT_URL + Constant.GETSTATECOUNTY + zipCode);
  }
  /* end get  statecounty data */

  /* open get lead sources data */
  public returnToErrorPage(error: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: { error: error }
    };
    this.router.navigate(['/error-page'], navigationExtras);
    return false;
  }

  public getProductTypes(orderType: string) {
    return this.httpService.get(
      this.ROOT_URL + Constant.GETPRODUCTTYPE + localStorage.getItem('accessToken') + '/' + orderType,
      {
        responseType: 'text'
      }
    );
  }
  public getFilteredLeadSources(accessToken: string, orderType: string, productType: string) {
    return this.httpService.get(
      this.ROOT_URL + Constant.GetFilteredLeadSources + accessToken + '/' + orderType + '/' + productType,
      {
        responseType: 'text'
      }
    );
  }
  public getLeadSources(userToken: any) {
    return this.httpService.get(this.ROOT_URL + Constant.LEADSOURCES + userToken);
  }
  public authentication() {
    return this.httpService.get(this.ROOT_URL + Constant.AUTHENTICATETOKEN + localStorage.getItem('accessToken'));
  }
  public isIEBrowser() {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }
  public getClietLogo() {
    if (localStorage.getItem('accessToken')) {
      return this.httpService.get(this.ROOT_URL + Constant.GETCLIENTLOGO + localStorage.getItem('accessToken'));
    }
  }

  public GetLoadingLogo() {
    if (localStorage.getItem('accessToken')) {
      return this.httpService.get(this.ROOT_URL + Constant.GETLOADINGLOGO + localStorage.getItem('accessToken'));
    }
  }

  public IsAllstateMasterAccount() {
    if (localStorage.getItem('accessToken')) {
      return this.httpService.get(
        this.ROOT_URL + Constant.ISALLSTATEMASTERACCOUNT + localStorage.getItem('accessToken')
      );
    }
  }

  public getPhoneDropDown(userToken: any) {
    return this.httpService.get(this.ROOT_URL + Constant.ACCOUNT_PHONE_URL + userToken, {
      responseType: 'text'
    });
  }
}
