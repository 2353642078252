import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

/**
 * Provides a base for authentication workflow.
 * The authentication functionality of the application is handled in the app.module.ts file.
 * The app.module does the configuration for the OauthService library which ensures the user is authenticated before accessing the application.
 */
@Injectable()
export class AuthenticationService {
  constructor(private oauthService: OAuthService) {}

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  public logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.oauthService.logOut();
    return of(true);
  }

  public isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  public reauthenticateUser() {
    console.log('re-authenticating a user here!');
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
