import { Injectable } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';
import { EnvironmentService } from '@app/environment.service';

/*
 * The logger is the main class for logging all debug, information, warnings and errors.  This class is meant to be injected.
 * All data will be sent to the console, and also to Application Insights.
 * Note:  When in production mode, debug and info logs are not sent to the console.
 */

/**
 * The possible log levels.
 */
export enum LogLevel {
  Error,
  Warning,
  Info,
  Debug
}

@Injectable()
export class Logger {
  static productionMode = false;

  constructor(private appInsights: ApplicationInsightsService) {
    Logger.productionMode = EnvironmentService.config.production;
  }

  debug(message: string, properties?: { [key: string]: string }) {
    this.log(LogLevel.Debug, message, properties);
  }

  info(message: string, properties?: { [key: string]: string }) {
    this.log(LogLevel.Info, message, properties);
  }

  warn(message: string, properties?: { [key: string]: string }) {
    this.log(LogLevel.Warning, message, properties);
  }

  error(message: string, properties?: { [key: string]: string }, error?: Error) {
    this.log(LogLevel.Error, message, properties, error);
  }

  private sendToConsole(message: string, level: LogLevel) {
    switch (level) {
      case LogLevel.Debug:
        if (!Logger.productionMode) {
          console.debug('Logger: ' + message);
        }
        break;
      case LogLevel.Info:
        if (!Logger.productionMode) {
          console.info('Logger: ' + message);
        }
        break;
      case LogLevel.Warning:
        console.warn('Logger: ' + message);
        break;
      case LogLevel.Error:
        //console.error('Logger: ' + message);
        break;
    }
  }

  private log(level: LogLevel, message: string, properties?: { [key: string]: string }, error?: Error) {
    try {
      if (!Logger.productionMode) {
        this.sendToConsole(message, level);

        var propertiesData = 'Could not parse';
        try {
          propertiesData = JSON.stringify(properties);
        } catch {}

        if (propertiesData) {
          this.sendToConsole('Properties: ' + propertiesData, level);
        }
      }

      switch (level) {
        case LogLevel.Debug:
          if (!Logger.productionMode) {
            this.appInsights.logTraceDebug(message, properties);
          }
          break;

        case LogLevel.Info:
          this.appInsights.logTraceInfo(message, properties);
          break;

        case LogLevel.Warning:
          this.appInsights.logTraceWarning(message, properties);
          break;

        case LogLevel.Error:
          this.appInsights.logTraceError(message, properties);
          break;
      }

      //if we have an error object, log this seperately
      if (error) {
        this.sendToConsole('Error object: ' + error, level);
        this.appInsights.logError(error, properties);
      }
    } catch (exc) {
      console.error('Failed to execute logger:');
      console.error(exc);
    }
  }
}
