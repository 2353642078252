import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
  TemplateRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderService } from './order.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleModule, MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as Constant from '../../constants/api';
import { ErrorService } from '../error-page/error.service';
import { CustomService } from '../services/custom.service';
import { interval } from 'rxjs';
import { I18nContext } from '@angular/compiler/src/render3/view/i18n/context';
import { MatPaginatorIntl } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  preserveWhitespaces: false,
  //show Records Per Page -Monika
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }]
  //show Records Per Page -Monika
})
export class OrderSummaryComponent implements OnInit, AfterViewInit {
  disableStatusFlag = false;
  statusToggle: string = 'Leads';
  orderList: any;
  accessToken: string;
  orderListData: any;
  showLoadingIndicator = true;
  mainDiv = false;
  showError = false;
  errorMessage: any;
  currentpage: any;
  page: any;
  noRecord: any;
  checkActveData: any;
  activeList = [Constant.SHOW_ALL_STATUS, Constant.SHOW_ACTIVE_STATUS];
  public selected = this.activeList[0];
  changeStatus: any;
  successMessage: any;
  deliveryDataMessage: any;
  leadActiveInactiveFlag: string;
  balance: any;
  dateFormat: any = Constant.DATE_DISPLAY_FORMAT;
  /* open table header names for order summary */
  displayedColumns: string[] = [
    'OrderName',
    'LeadSourcesNames',
    'SourceType',
    'LastModifiedDate',
    'CreatedDate',
    'EndDate',
    'LeadType',
    'AverageLeads',
    'Status',
    'Edit'
  ];

  /* end table header names for order summary */

  public dataSource = new MatTableDataSource();
  showMsg: boolean;
  showDeliveryWarning: boolean = false;
  message: string;
  class: 'show-error';
  updateTable: {}[];
  row: any;
  fundAlert: { type: string; message: string };
  isHideAlert: boolean;
  isAllstateMA: boolean = false;

  constructor(private _orderService: OrderService, private _service: CustomService) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
  }
  filterOptionFocus() {
    document.getElementById('filterOption').style.backgroundColor = '#f1f1f1 !important';
  }
  filterOptionBlur() {
    document.getElementById('filterOption').style.backgroundColor = '#fcfcfc !important';
  }
  ngOnInit() {
    this.accessToken = this._orderService.getAccessTokenFromStorage();
    console.log(this.accessToken);
    if (this.accessToken == null) {
      this._orderService.returnToErrorPage(Constant.TOKEN_MISSING_ERROR_MSG);
    }
    this.isAllstateMA = localStorage.getItem('isAllstateMA') == 'true';

    this._service.setcopyOrderData('');
    this.leadActiveInactiveFlag = 'InActive';
    this.getAccountBalance();
    if (typeof this._service.getLeadType() === 'undefined') {
      this.refreshOrder('Leads', 'Active');
      this.LeadType('Leads');
    } else {
      this.refreshOrder(this._service.getLeadType(), 'Active');
      this.LeadType(this._service.getLeadType());
    }
  }

  removeSuccessMessage() {
    this._service.setSuccessMessage('');
    this._service.setDeliveryMessage('');
    //interval(6000).subscribe(x => this.service.setSuccessMessage(''));
  }

  activeButton: any;
  LeadType(event: any) {
    if (event == 'Leads') {
      this.activeButton = 1;
    } else if (event == 'Calls') {
      this.activeButton = 2;
    } else {
      this.activeButton = 1;
    }
    this._service.setLeadType(event);
    this.checkNoOfRecord(event);

    this.statusToggle = event;
    /* open filter data lead data */

    this.dataSource.data = this.orderListData.filter(function(order: any) {
      return order.SourceType == event;
    });

    /* end filter data lead data */
  }

  /* open set no record error message */

  checkNoOfRecord(type: string) {
    var orderStatus = this.leadActiveInactiveFlag;
    if (this.leadActiveInactiveFlag === 'Active') {
      this.checkActveData = this.orderListData.filter(function(order: any) {
        return order.SourceType == type && order.Status == orderStatus;
      });
    } else {
      this.checkActveData = this.orderListData.filter(function(order: any) {
        return order.SourceType == type;
      });
    }

    if (this.checkActveData == 0) {
      this.noRecord = Constant.NORECORDSTODISPLAY;
    } else {
      this.noRecord = '';
    }
  }
  onManageFund() {
    this.isHideAlert = true;
    this.fundAlert = { type: 'warning', message: Constant.Manage_Funds_Message };
  }
  closeAlert() {
    this.isHideAlert = false;
  }
  /* end set no record error message */

  /* open get account balance*/
  getAccountBalance() {
    this._orderService.getAccountFundingBalance().subscribe((data: any) => {
      if (data != null) {
        this.balance = data;
      } else {
        this.balance = '0';
      }
    });
  }
  /* end get account balance*/

  /* open apply filter on status */

  applyFilter(filterValue: string) {
    if (filterValue === Constant.SHOW_ACTIVE_STATUS) {
      this.leadActiveInactiveFlag = 'Active';
      this.changeStatus = '';
    } else {
      this.leadActiveInactiveFlag = 'Paused';
      this.changeStatus = 'Paused';
    }
    if (typeof this._service.getLeadType() === 'undefined') {
      this.refreshOrder(this.statusToggle, this.changeStatus);
      this.LeadType(this.statusToggle);
    } else {
      this.refreshOrder(this._service.getLeadType(), this.changeStatus);
      this.LeadType(this._service.getLeadType());
    }

    if (filterValue === Constant.SHOW_ACTIVE_STATUS) {
      this.dataSource.filter = 'Active';
    } else {
      this.dataSource.filter = '';
      this.noRecord = '';
    }
  }
  /* end apply filter on status */
  // Updating status in datatable -Monika
  onStatusChange(event: MatSlideToggleChange, orderId: any, rowElement: any) {
    var status = null;
    if (event.checked) {
      status = 'Active';
      this._orderService.updateOrderStatus(status, orderId).subscribe(
        (data: any) => {
          const row = this.dataSource.data;
          for (let i = 0; i <= row.length; i++) {
            if (row[i]['OrderId'] == orderId) {
              row[i]['Status'] = 'Active';
            }
          }
          // Updating status in datatable -Monika
        },
        (err: any) => {}
      );
    } else {
      status = 'Paused';
      var filterValue = (document.getElementById('filterOption') as HTMLSelectElement).innerText;
      this._orderService.updateOrderStatus(status, orderId).subscribe(
        (data: any) => {
          const row = this.dataSource.data;
          if (filterValue.trim() === Constant.SHOW_ACTIVE_STATUS) {
            this.dataSource.data = this.dataSource.data.filter(i => i !== rowElement);
          }
          for (let i = 0; i <= row.length; i++) {
            if (row[i]['OrderId'] == orderId) {
              row[i]['Status'] = 'Paused';
            }
          }
          // Updating status in datatable -Monika
        },
        (err: any) => {}
      );
    }
  }
  refreshOrder(leadType: string, statusType: string) {
    this.accessToken = this._orderService.getAccessTokenFromStorage();
    if (this.accessToken) {
      this._orderService.getLeadOrder(this.accessToken).subscribe(
        data => {
          this.showLoadingIndicator = false;
          this.mainDiv = true;

          /* open get current success message */

          this._service.currentSuccessMessage.subscribe(data => {
            this.message = data;
            if (this.message != '') {
              this.showMsg = true;
            } else {
              this.showMsg = false;
            }
          });

          /* end get current success message */
          this._service.currentDeliveryMessage.subscribe(data => {
            this.deliveryDataMessage = data;
            if (this.deliveryDataMessage != '') {
              this.showDeliveryWarning = true;
            } else {
              this.showDeliveryWarning = false;
            }
          });

          setTimeout(() => this.removeSuccessMessage(), 10000);

          this.orderListData = data as string[];
          if (data == '') {
            this.noRecord = Constant.NORECORDSTODISPLAY;
          }

          this.dataSource.data = this.orderListData.filter(function(order: any) {
            return order.SourceType == leadType;
          });

          this.checkNoOfRecord(leadType);
          if (this.orderListData.length != 0) {
            if (this.orderListData[0]['Error'] != 'Success') {
              this.showError = true;
              this.errorMessage = this.orderListData[0]['Error'];
              this.mainDiv = false;
            }
          }
        },
        (err: HttpErrorResponse) => {
          this._orderService.returnToErrorPage(err.error);
        }
      );
    } else {
      this.mainDiv = false;
      this.showLoadingIndicator = false;
      this.showError = true;
      this.errorMessage = Constant.TOKEN_MISSING_ERROR_MSG;
    }
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
}
//show Records Per Page -Monika
export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = Constant.RECORD_PER_PAGE;

  return customPaginatorIntl;
}
//show Records Per Page -Monika
