import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  //{ path: '', redirectTo: '/lead-marketplace-test-request', pathMatch: 'full' },
  Shell.childRoutes([
    { path: 'order-summary', loadChildren: 'app/orders/order-summary.module#OrderSummaryModule' },
    { path: 'order-add', loadChildren: 'app/orders/order-add.module#OrderAddModule' },
    { path: 'order-edit/:orderId', loadChildren: 'app/orders/order-edit/order-edit.module#OrderEditModule' },
    {
      path: 'lead-marketplace-test-request',
      loadChildren: 'app/lead-marketplace-test-request/lead-marketplace-test-request.module#IProTestRequestModule'
    },
    {
      path: 'lead-marketplace/:accessToken',
      loadChildren: 'app/lead-marketplace/lead-marketplace.module#LeadMarketPlaceModule'
    },
    { path: 'error-page', loadChildren: 'app/error-page/error-page.module#ErrorPageModule' }
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'Notfound', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
