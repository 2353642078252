import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Logger } from './logger.service';

/*
 * Global events is a place to define events that the entire app ecosystem can publish and subscribe to
 * These are well-defined events that can have specific data that is passed along with the event.
 */

@Injectable()
export class GlobalApplicationEventsService {
  constructor(private logger: Logger) {}

  public GlobalErrorEvent: EventBaseSimple = new EventBaseSimple('Global Error Event', this.logger);

  //Example event that takes arguments
  //public UserLoggedInEvent: EventBase<UserLoggedInEventArgs> = new EventBase<UserLoggedInEventArgs>("User Logged In Event", this.logger);
}

/*
 * Event base that tags a type for the event arguments, which can be any class/type.
 */
export class EventBase<T> {
  protected subject: Subject<T>;
  private eventName: string;

  constructor(eventName: string, private logger: Logger) {
    this.eventName = eventName;
    logger.debug(`GlobalApplicationEventsService: ${this.eventName} - Setup`);
    this.subject = new Subject<T>();
  }

  public Broadcast(value: T) {
    this.logger.debug(`GlobalApplicationEventsService: ${this.eventName} - Broadcast - ${JSON.stringify(value)}`);
    this.subject.next(value);
  }

  public Subscribe(action: (args: T) => void): void {
    this.logger.debug(`GlobalApplicationEventsService: ${this.eventName} - Subscription created`);
    this.subject.subscribe(action);
  }
}

/*
 * Event base that does not have any arguments
 */
export class EventBaseSimple {
  protected subject: Subject<any>;
  private eventName: string;

  constructor(eventName: string, private logger: Logger) {
    this.eventName = eventName;
    logger.debug(`GlobalApplicationEventsService: ${this.eventName} - Setup`);
    this.subject = new Subject();
  }

  public Broadcast() {
    this.logger.debug(`GlobalApplicationEventsService: ${this.eventName} - Broadcast`);
    this.subject.next();
  }

  public Subscribe(action: () => void): void {
    this.logger.debug(`GlobalApplicationEventsService: ${this.eventName} - Subscription created`);
    this.subject.subscribe(action);
  }
}

/* This is example event arguments object that specifies data to pass in the event */
/*
export class UserLoggedInEventArgs {

  public UserId: string;
}
*/

/* This is example custom event class where you can make custom methods for broadcast and subscribe, that have custom params
 * This is nice for developers because they don't have to worry about using the arguments object
/*
export class UserLoggedInEvent extends EventBase<UserLoggedInEventArgs>{

  public Broadcast(userId: string)
  {

  }

  public Subscribe(action: (userId: string) => void): void {
  {

  }

}
*/
