import { Injectable } from '@angular/core';
import { OAuthService, OAuthEvent, EventType as OauthEventType } from 'angular-oauth2-oidc';

@Injectable()
export class IdentityService {
  public readonly IdentityUser: IdentityUser;

  constructor(private oauthService: OAuthService) {
    this.IdentityUser = new IdentityUser();
    this.listenForUserProfileLoaded();
  }

  public doSomething() {}

  private listenForUserProfileLoaded() {
    this.oauthService.events.subscribe(event => {
      if (event.type === 'user_profile_loaded') {
        this.updateIdentityWithClaims();
      }
    });
  }

  private updateIdentityWithClaims() {
    this.IdentityUser.updateUserFromClaims(this.oauthService.getIdentityClaims());
  }
}

class IdentityUser {
  public get userId(): string {
    return this._userId;
  }
  private _userId: string;
  public get username(): string {
    return this._username;
  }
  private _username: string;

  public updateUserFromClaims(claims: object) {
    this._userId = claims['sub'];
    this._username = claims['Username'];
  }
}
