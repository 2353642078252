import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '@app/environment.service';
import { Logger } from '@app/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showLoadingIndicator: boolean;
  constructor(private logger: Logger) {}
  ngOnInit() {
    // Setup logger
    if (EnvironmentService.config.production) {
      //this.logger..enableProductionMode();
    }

    this.logger.debug('init');
  }
}
