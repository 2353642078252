import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errorMessage = new BehaviorSubject('');
  currentMessage = this.errorMessage.asObservable();

  constructor() {}

  changeMessage(errorMessage: string) {
    console.log('errorMessageertsetr');
    this.errorMessage.next(errorMessage);
  }
}
