import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from './logger.service';
import { GlobalApplicationEventsService } from './global-application-events.service';

/*
 * Global error handler for all fatal issues in the angular app.
 * Will call the logger to log the exception, and send an event that will get captured by main app component
 * to send the user to error page.
 */

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    var logger: Logger;

    try {
      logger = this.injector.get(Logger);
      let router = this.injector.get(Router);
      let zone = this.injector.get(NgZone);
      let globalEvents = this.injector.get(GlobalApplicationEventsService);

      //Log the global error
      logger.error('Global unhandled error', { Url: router.url }, error);

      //Notify the app that we just had a global error so it can respond (ie take user to error page)
      globalEvents.GlobalErrorEvent.Broadcast();
    } catch (exc) {
      //we failed at some point to handle the global error
      //so, first try to log the new error to logger
      try {
        if (logger) {
          logger.error('Failed to handle/log unhandled error', exc);
        }
      } catch {}
      //also log straight to the console in case logger is not working
      console.error('Failed to handle/log unhandled error');
      console.error(exc);
    }
  }
}
