import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { EnvironmentService } from '@app/environment.service';
import { AppInsights } from 'applicationinsights-js';
import { filter } from 'rxjs/operators';

/**
 * This enum is a copy/paste from the Application Insights library @types reference
 * This is required because there is an issue / bug with Applicaiton Insights library where you cannot
 * reference the "AI.SeverityLevel" type from the code below. (RefferenceError).  So we use our own with the same values.
 */
enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4
}

@Injectable()
export class ApplicationInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: EnvironmentService.config.loggingInstrumentationKey
  };

  constructor(private router: Router) {
    console.log('AppInsightsService: Initializing');

    if (this.config && this.config.instrumentationKey) {
      AppInsights.downloadAndSetup(this.config);
    }

    // Add custom telemetry processor, so we can modify every telemetry item sent to app insights, to add/edit data, or block some events
    AppInsights.queue.push(function() {
      AppInsights.context.addTelemetryInitializer(function(envelope) {
        var telemetryItem = envelope.data.baseData;

        //  set custom properties:
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties['ApplicationName'] = EnvironmentService.config.applicationName;
        telemetryItem.properties['Environment'] = EnvironmentService.config.environmentName;
      });
    });

    // Attach to router events so we can automatically log all page views
    console.log('AppInsightsService: Attaching to router events');
    if (this.router) {
      console.log('AppInsightsService: Attached to router events');
      this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        console.log('AppInsightsService: Navigation start');
        //Note: Used URL for page name param because leaving it null did not produce accurate results in AI
        AppInsights.startTrackPage(event.url);
      });
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        console.log('AppInsightsService: Navigation end');
        //Note: Used URL for page name param because leaving it null did not produce accurate results in AI
        AppInsights.stopTrackPage(event.url, event.url);
      });
    }
  }

  public setAuthenticatedUserId(userId: string): void {
    AppInsights.setAuthenticatedUserContext(userId);
  }

  public logTraceDebug(name: string, properties?: { [key: string]: string }) {
    AppInsights.trackTrace(name, properties, SeverityLevel.Verbose);
  }

  public logTraceInfo(name: string, properties?: { [key: string]: string }) {
    AppInsights.trackTrace(name, properties, SeverityLevel.Information);
  }

  public logTraceWarning(name: string, properties?: { [key: string]: string }) {
    AppInsights.trackTrace(name, properties, SeverityLevel.Warning);
  }

  public logTraceError(name: string, properties?: { [key: string]: string }) {
    AppInsights.trackTrace(name, properties, SeverityLevel.Error);
  }

  public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackEvent(name, properties, measurements);
  }

  public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackException(error, null, properties, measurements);
  }
}
