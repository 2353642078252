import { Injectable, Optional, EventEmitter } from '@angular/core';
import { throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpBackend, HttpErrorResponse } from '@angular/common/http';

export class EnvironmentConfig {
  public applicationName: string = '';
  public apiUrl: string = '';
  public identityUrl: string = '';
  public identityClientId: string = '';
  public loggingInstrumentationKey: string = '';
  public development: boolean;
  public production: boolean;
  public environmentName: string;
  public clientUrl: string;
  public errorPageUrl: string;
  public userControllerUrl: string;
  public walkmeUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public static configurationLoaded: EventEmitter<EnvironmentConfig> = new EventEmitter<EnvironmentConfig>();
  public static initialized: boolean = false;
  public static config: EnvironmentConfig;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  // NOTE: this is not static because the app_initializer uses a single instance of this class
  public getEnvironmentConfiguration(): Promise<any> {
    if (!EnvironmentService.initialized) {
      return this.httpClient
        .get<any>('/config/index')
        .pipe(
          map(response => EnvironmentService.saveConfigResponse(response)),
          retry(3),
          catchError(EnvironmentService.handleError)
        )
        .toPromise();
    } else {
      return Promise.resolve();
    }
  }

  private static handleError(error: HttpErrorResponse) {
    console.log('EnvironmentService (handleError): Received an error for config call');

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error('Backend returned code ${error.status}, ' + 'body was: ${error.error}');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private static saveConfigResponse(response: any) {
    var config = new EnvironmentConfig();

    config.applicationName = response.applicationName;
    config.apiUrl = response.apiUrl;
    config.identityClientId = response.identityClientId;
    config.identityUrl = response.identityUrl;
    config.loggingInstrumentationKey = response.loggingInstrumentationKey;
    config.development = response.development;
    config.production = response.production;
    config.environmentName = response.environmentName;
    config.clientUrl = response.clientUrl;
    config.errorPageUrl = response.errorPageUrl;
    config.userControllerUrl = response.userControllerUrl;
    config.walkmeUrl = response.walkmeUrl;
    this.config = config;
    this.initialized = true;
    this.configurationLoaded.emit(config);
  }
}
