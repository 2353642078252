import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { CustomService } from '../services/custom.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  isAgreed: boolean = false;
  message: string;
  subscription: Subscription;
  currentYear: string;
  @ViewChild('agreementContent', { static: true })
  agreementContent$: ContentRef;
  isAllstateMA: boolean = false;
  constructor(private service: CustomService, private _modalService: NgbModal) {}

  ngOnInit() {
    this.isAllstateMA = localStorage.getItem('isAllstateMA') == 'true';
    let date = new Date();
    this.currentYear = date.getFullYear().toString();
    this.subscription = this.service.currentTermAgreement.subscribe(message => {
      if (message == 'openAgree') {
        this.isAgreed = true;
        this.openAgree();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  openAgree() {
    this._modalService
      .open(this.agreementContent$, { size: 'xl', scrollable: true, windowClass: 'dark-modal' })
      .result.then(
        result => {
          if (this.isAgreed) {
            this.service.setTermAgreement('agreed');
          }
        },
        reason => {
          if (this.isAgreed) {
            this.service.setTermAgreement('disagree');
          }
        }
      );
  }
}
