import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorService } from './error.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  error: any;
  test: any;
  mainDiv = false;
  showError = false;
  errorMessage: any;
  sessionId: Observable<string>;
  message: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private errorService: ErrorService) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.showError = true;
      this.errorMessage = params.error;
    });
  }
}
