import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  isIframe: boolean;
  constructor() {}

  ngOnInit() {
    this.isIframe = window.location !== window.parent.location;
  }
}
