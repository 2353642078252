import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() showLoadingIndicator: boolean;
  @Input() dynamicWidth: any;
  loadingLogoSrc: string;

  constructor() {}

  ngOnInit() {
    this.loadingLogoSrc = localStorage.getItem('loadingLogo');
  }
}
