import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeadMarketTestRequestComponent } from './lead-marketplace-test-request.component';

import { extract } from '@app/core';

const routes: Routes = [
  {
    path: 'lead-marketplace-test-request',
    component: LeadMarketTestRequestComponent,
    data: { title: extract('lead-marketplace-test-request') }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadMarketTestRequestRoutingModule {}
