import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, NgModel } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { Options } from 'ng5-slider';
import { OrderService } from '../../orders/order.service';
import * as Constant from '../../../constants/api';
import { Location } from '../../model/location';
import { order, orderData } from '../../model/order';
import { HttpErrorResponse } from '@angular/common/http';
import { interval, timer } from 'rxjs';
import { CustomService } from '@app/services/custom.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDailogComponent } from '@app/error-dailog/error-dailog.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ValidationErrorDialogComponent } from '@app/validation-error-dialog/validation-error-dialog.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Logger } from '../../core/logger.service';
import { formatDate } from '@angular/common';
import { times } from 'lodash';
import { brokerInfo } from '../../model/brokerInfo';
import { phoneFormat, reformatePhone } from '../../core/commonFunctions';
import { NzSelectComponent } from 'ng-zorro-antd';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss'],
  preserveWhitespaces: false
})
export class OrderEditComponent implements AfterViewInit, OnInit {
  [x: string]: any;
  lastPhoneSelect: string;
  isAddCountyReady: boolean = true;
  curPageEditLocation: number = 1; // currentpage of location page
  curPageCopyLocation: number = 1; // currentpage of location page
  defaultTime = new Date(Date.now());
  defaultEndTime: Date | null = null;
  endTimeVal: string = null;
  disabledSatusFlag = false;
  phoneNumberlengthError: string;
  startDateLimitFlag: boolean = true;
  copyStartDateLimitFlag: boolean = true;
  copyErrorMessagelimit: string;
  copyErrorMessageStartDatelimit: string;
  errorMessagelimit: string;
  errorMessageStartDatelimit: string;
  checkMaxCallCurrencyRange: boolean;
  maxCallSubmitError: boolean;
  copyMaxCallSubmitError: boolean;
  endDateErrorMsg: string;
  startDateErrorMsg: string;
  copyEndDateErrorMsg: string;
  copyStartDateErrorMsg: string;
  recordsPerPages: string[] = ['5', '10', '15', '20', '25'];
  selectednumber: number = 25;
  locationData: Location[];
  locationErrorNull: string;
  copyLocationErrorNull: string;
  countyData: Location[];
  filterData: pageMode;
  StartDate: string;
  checkBoxErrorMsg: string;
  EndDate: string;
  dailyCallCapError: string = '';
  hourlyCallCapError: string = '';
  orderError: string = '';
  startTimeError: string;
  endTimeError: string;
  SubmitError: boolean = false;
  LocationErrorflag: boolean;
  checkdailyCapRange: boolean;
  checkHourlyCapRange: boolean;
  orderName: string = '';
  maxCallCurrencyError: string;
  maxCallCurrencyRangeError: string;
  phoneNumberError: string;
  errorFlagOrderName: boolean = false;
  errorFlagDailyLeadCap: boolean = false;
  errorFlagHourlyLeadCap: boolean = false;
  timeslotError: string = '';
  accessToken: string;
  SubmitErrorFlag: boolean = false;
  orderTypeError: string;
  productTypeError: string;
  orderNameError: string;
  startDateError: string;
  endDateError: string;
  timezoneError: string;
  hourlycapError: string;
  dailycapError: string = '';
  leadSourceError: string = '';
  state: boolean;
  county: boolean;
  ListCountySelected: string[] = [];
  zipData: Location[] = [];
  Ziplist: HTMLInputElement;
  allZipCodes: Location[];
  showAllZipCode: boolean;
  leadSources: LeadSourceIntegration[] = [];
  orderData: orderData;
  orderModel: order = null;
  orderId: string;
  HideDemographics: boolean;
  HideFields: boolean;
  orderStatus: boolean;
  errorMessageStartDate: string;
  ageTo: number;
  ageFrom: number;
  leadSourcesRequired: string = '';
  balance: string;
  countyName: string;
  locationList: Location[] = [];
  copyOrderLocationList: Location[] = [];
  filterList: LeadSourceIntegration[] = [];
  locationStateCountyZipList: string[] = [];
  locationStateCountyZipCopyList: string[] = [];
  locationError: string;
  copyLocationError: string;
  orderFlag: boolean;
  copyOrderModel: order;
  copyData: orderData;
  stateForService: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  applyAll: boolean;
  productType: string[];
  copyProductType: string[];
  editOrderClickFlag: boolean;
  showEditTab: boolean = true;
  showCopyTab: boolean = false;
  showCopyOrderName: boolean;
  showLoadingIndicator = true;
  orderErrorMessage: string;
  showMsg: boolean;
  saveFlag: boolean;
  copyOrdername: string;
  copyUrl: boolean;
  checkBoxError: boolean;
  checkBoxValidator: boolean = false;
  copyLeadSources: LeadSourceIntegration[] = [];
  copyLeadSourceList: LeadSourceIntegration[] = [];
  copyLocationList: Location[];
  productTypeValue: string;
  copyAgeFrom: number;
  copyLocationErrorflag: boolean;
  copyErrorFlagEndDate: boolean = false;
  copyErrorFlagStartDate: boolean = false;
  errorFlagStartDate: boolean = false;
  errorFlagEndDate: boolean = false;
  copySubmitError: boolean = false;
  copySubmitErrorFlag: boolean = false;
  copyLocationsErrorflag: boolean;
  copycheckBoxValidator: boolean = false;
  copyErrorMessageStartDate: string;
  copyOrderError: string = '';
  copyMaxCallCurrencyRangeError: string;
  copyDailyCallCapError: string;
  copyHourlyCallCapError: string;
  copyHideDemographics: boolean;
  copyHideFields: boolean;
  orderTypeValue: string;
  copyHourlycapError: string;
  copyMaxCallCurrencyError: string;
  copycheckboxErrorMsg: string;
  message: string;
  spinnerFlag: boolean = false;
  copyOrderClickFlag: boolean = false;
  copyOrderCreatedFlag: boolean = true;
  copyOrderUpdatedFlag: boolean = false;
  copyOrderCreatedId: string;
  copyDisabled: boolean = false;
  filteredCounties: Location[];
  filteredCopyCounties: Location[];
  sDate: string;
  eDate: string;
  sDateCopy: string;
  eDateCopy: string;
  formType: pageMode = pageMode.edit;
  editSuccessMsg: string;
  copySuccessMsg: string;
  showEditMsg: boolean = false;
  showCopyMsg: boolean = false;
  isIeBrowser: boolean;
  isOtherBrowser: boolean;
  browserName: string;
  flagForETime: boolean;
  flagForSTime: boolean;
  currentDate = new Date();
  startFlag: boolean;
  endFlag: boolean;
  endTimeFlag: boolean;
  endTimeFlagErr: string;
  startTimeFlag: boolean;
  startTimeFlagErr: string;
  copyFlagForETime: boolean;
  copyFlagForSTime: boolean;
  copyEndTimeFlag: boolean;
  copyEndTimeFlagErr: string;
  copyStartTimeFlag: boolean;
  copyStartTimeFlagErr: string;
  isIE: boolean;
  minStartDate: any;
  minEndDate: any;
  today: Date;
  checkSelectedField: string = 'none';
  compareTimeErrorFlag: boolean = false;
  copyCompareTimeErrorFlag: boolean = true;
  stateOptionValue: string = 'Select State';
  countyOptionValue: string = 'Select County';
  countyCopyData: Location[];
  locationCopyData: Location[];
  copyEndDateValid: boolean;
  copyStartDateValid: boolean;
  endDateValid: boolean;
  startDateValid: boolean;
  stateFirstOptionValue: string = 'Select State';
  countyFirstOptionValue: string = 'Select County';
  copyLocationStartingPoint: string = 'none';
  copyZipData: Location[];
  deliveryDataMessage: string;
  showDeliveryWarning: boolean = false;
  hideCountyZipCodeOfEditForm: boolean;
  hideCountyZipCodeOfCopyForm: boolean = false;
  resizeTableOnEditCallTab: boolean = false;
  resizeTableOnCopyCallTab: boolean = false;
  copyOrderTypeValueOnSelect: string = '';
  iprolistphone: string[];
  someTime: Date = null;
  @ViewChild('copyOrderButton', { static: true })
  copyOrderButton$: ElementRef;
  @ViewChild('copyEndDate', { static: true })
  copyEndDate$: ElementRef;
  @ViewChild('copyStartDate', { static: true })
  copyStartDate$: ElementRef;
  @ViewChild('CountySelector', { static: true })
  CountySelector$: NzSelectComponent;
  @ViewChild('CopyCountySelector', { static: true })
  CopyCountySelector$: NzSelectComponent;
  @ViewChild('ZipCode', { static: true })
  ZipCode$: ElementRef;
  @ViewChild('HourlyCallCap', { static: true })
  HourlyCallCap$: ElementRef;
  @ViewChild('DailyCallCap', { static: true })
  DailyCallCap$: ElementRef;
  @ViewChild('MaxCallCurrency', { static: true })
  MaxCallCurrency$: ElementRef;
  @ViewChild('CopyZipCode', { static: true })
  CopyZipCode$: ElementRef;
  @ViewChild('copyHourlyCallCap', { static: true })
  copyHourlyCallCap$: ElementRef;
  @ViewChild('copyDailyCallCap', { static: true })
  copyDailyCallCap$: ElementRef;
  @ViewChild('copyMaxCallCurrency', { static: true })
  copyMaxCallCurrency$: ElementRef;
  pageMode: typeof pageMode = pageMode;
  //agefilterMsg: string = '';
  hiddenCountyDropdown: boolean = true;
  isCopy: any;
  isSelectAll: boolean;
  isReadySend: boolean;
  submitted = false;
  copySubmitted = false;
  /* open values for  age range slider  */
  errorMessage: string = '';
  copyErrorMessage: string = '';
  copyAgeTo: number = 60;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  copyOptions: Options = {
    floor: 0,
    ceil: 100
  };
  applyAllday: string = '';
  selectedObject: any;
  customObj: LeadSourceIntegration[];
  isDone: boolean;
  integrationList: string[] = [];
  copyIntegrationList: string[] = [];
  leadSourceExist: boolean;
  SendEmailNotification: boolean = true;
  SendMobileNotification: boolean = false;
  chkMobileNotification: boolean = false;
  chkEmailNotification: boolean = true;
  textEmail: string = '';
  textMobile: string = '';
  isHideAlert: boolean;
  myContactAlert: { type: string; message: string };
  HideNotification: boolean = true;
  isCopyOrderForm: boolean = false;
  CopySendEmailNotification: boolean = true;
  CopySendMobileNotification: boolean = false;
  brokerModel: brokerInfo = null;
  isCallLead: boolean = false;
  autoCompleteValue: string = 'off';
  isAllstateMA: boolean = false;

  constructor(
    private _router: Router,
    private _orderService: OrderService,
    private _oauthService: OAuthService,
    private _fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _service: CustomService,
    private _matDialog: MatDialog,
    private _modalService: NgbModal,
    private _datePipe: DatePipe,
    private _logger: Logger
  ) {
    this._activatedRoute.params.subscribe(params => (this.orderId = params['orderId']));
    this.locationData = [];
    this.countyData = [];
    this.zipData = [];
    this.allZipCodes = [];
    this.productType = [];
  }
  ngAfterViewInit() {
    /*handle tab focus between copy and edit order page*/
    this.disableTabIndexForDayCheckbox();
    this.setEditOrderTabIndex();
    this.disableStatusButton();
    this.setDemographicValue(this.copyOrderForm, pageMode.copy);
    this._oauthService.events.subscribe(event => {
      if (event.type === 'user_profile_loaded') {
        this._router.navigate(['/home'], { replaceUrl: true });
      }
    });

    /* open set empty variable after every 5,8 seconds */
    this.showLoadingIndicator = false;
    interval(10000).subscribe(x => (this.locationError = ''));
    interval(10000).subscribe(x => (this.copyLocationError = ''));
    interval(10000).subscribe(x => (this.timeslotError = ''));
    interval(10000).subscribe(x => (this.checkBoxErrorMsg = ''));

    /* end set empty variable after every 5,8 seconds */

    /*date validation*/
    let strStartDate = this._datePipe.transform(this.orderData.StartDate, 'yyyy-MM-dd');
    let strToday = this._datePipe.transform(this.today, 'yyyy-MM-dd');
    if (strStartDate > strToday) {
      this.minStartDate = strToday;
    } else {
      this.minStartDate = this.orderData.StartDate;
    }
    if (this.orderData.EndDate != null) {
      let strEndDate = this._datePipe.transform(this.orderData.EndDate, 'yyyy-MM-dd');
      if (strEndDate > strToday) {
        this.minEndDate = strToday;
      } else {
        this.minEndDate = this.orderData.EndDate;
      }
    } else {
      this.minEndDate = strToday;
    }

    // this.syncTimePickers();
    this.initTimePickers();
    /*date validation*/

    if (this.orderModel.SourceType == 'Calls') {
      this.isCallLead = true;
    } else {
      this.isCallLead = false;
    }
    this.getStateData();
  }

  getBrokerInformation() {
    this.HideNotification = true;
    let res: brokerInfo;
    res = this.brokerModel;
    //this._orderService.getBrokerInformation().subscribe(
    //  brokerRes => {
    //  res = JSON.parse(brokerRes);
    this.textEmail = !res.brokerEmail ? Constant.BrokerEmptyEmailMessage : res.brokerEmail;
    if (res.brokerEmail) {
      if (!this.orderModel.SendEmailNotification && !this.isCopyOrderForm) {
        this.SendEmailNotification = false;
      } else {
        this.SendEmailNotification = true;
        this.CopySendEmailNotification = true;
      }
      this.chkEmailNotification = false;
    } else {
      this.SendEmailNotification = false;
      this.CopySendEmailNotification = false;
      this.chkEmailNotification = true;
    }

    this.textMobile = !res.brokerMobile ? Constant.BrokerEmptyMobileMessage : res.brokerMobile;
    this.SendMobileNotification = false;
    this.CopySendMobileNotification = false;
    this.chkMobileNotification = false;
    /*
    if (res.brokerMobile) {
      if (!this.orderModel.SendSMSNotification && !this.isCopyOrderForm) {
        this.SendMobileNotification = false;
      } else {
        this.SendMobileNotification = true;
        this.CopySendMobileNotification = true;
      }
      this.chkMobileNotification = false;
    } else {
      this.SendMobileNotification = false;
      this.CopySendMobileNotification = false;
      this.chkMobileNotification = true;
    }
    */
    this.disableSpinner();
    //  },
    //  err => {
    //    this._logger.error(Constant.BrokerEmailPhoneLogMsg);
    //  }
    //);
  }
  editOrderForm = this._fb.group({
    OrderId: [''],
    OrderType: ['', Validators.required],
    ProductType: ['', Validators.required],
    OrderName: ['', Validators.required],
    StartDate: ['', Validators.required],
    EndDate: [''],
    Status: [''],
    Leadsources: ['', Validators.required],
    State: ['', Validators.required],
    County: [''],
    ZipCode: [''],
    AgeRange: [''],
    recordsPerPage: ['0'],
    TimeZone: ['', Validators.required],
    timeSlots: this._fb.array([]),
    HourlyCallCap: ['', Validators.required],
    DailyCallCap: ['', Validators.required],
    MaxCallCurrency: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    SendEmailNotification: [''],
    SendMobileNotification: ['']
  });
  closeAlert() {
    this.isHideAlert = false;
  }
  onMyContactClick() {
    this.isHideAlert = true;
    this.myContactAlert = {
      type: 'warning',
      message: Constant.Manage_MYCONTACT_Message
    };
  }
  copyOrderForm = this._fb.group({
    OrderId: [''],
    OrderType: ['', Validators.required],
    ProductType: ['', Validators.required],
    OrderName: ['', Validators.required],
    StartDate: ['', Validators.required],
    EndDate: [''],
    Status: [''],
    Leadsources: ['', Validators.required],
    State: ['', Validators.required],
    County: [''],
    ZipCode: [''],
    AgeRange: [''],
    recordsPerPage: ['0'],
    TimeZone: ['', Validators.required],
    timeSlots: this._fb.array([]),
    HourlyCallCap: ['', Validators.required],
    DailyCallCap: ['', Validators.required],
    MaxCallCurrency: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    CopySendEmailNotification: [''],
    CopySendMobileNotification: ['']
  });

  getproducttype() {
    /* open product type depend on order type */

    this._orderService.getProductTypes(this.orderData.SourceType).subscribe(
      res => {
        this.productType = JSON.parse(res.toString());
      },
      err => {
        this._orderService.returnToErrorPage(err.error);
        this._logger.error(Constant.productTypeLogMsg);
      }
    );

    /* end product type depend on order type */
  }
  /* open get lead sources by source type and lead type */
  getleadsources() {
    this._orderService
      .getFilteredLeadSources(this.accessToken, this.orderData.SourceType, this.orderData.LeadType)
      .subscribe(
        res => {
          this.leadSources = JSON.parse(res.toString());
          var leadSourcesList = this.orderData.LeadSources.split(',');
          if (leadSourcesList.length > 0) {
            this.updateLeadSourcesControl(this.leadSources.length, pageMode.edit);
          }

          for (var val of leadSourcesList) {
            this.leadSourcesRequired = val;
            this.onLeadSourceChange(val, 'noChange');
          }
        },
        err => {
          console.log(err);
          this._logger.error(Constant.leadsourceLogMsg);
        }
      );
  }
  /* open push time slot value in formarray  */
  addTimeSlot(): void {
    this.setCopyBtnDisable();
    (<FormArray>this.editOrderForm.get('timeSlots')).push(this.addTimeSlotFormGroup());
  }

  addCopyTimeSlot(): void {
    (<FormArray>this.copyOrderForm.get('timeSlots')).push(this.addTimeSlotFormGroup());
  }
  /**Changes for time end*/
  checkBoxValidation(formType: pageMode, checkBoxType: any) {
    this.setCopyBtnDisable();
    var data = null;
    if (formType === pageMode.edit) {
      data = this.editOrderForm.get('timeSlots') as FormArray;
    } else {
      data = this.copyOrderForm.get('timeSlots') as FormArray;
    }

    var num = {};
    var selectAllCount = 0;

    for (selectAllCount = 0; selectAllCount <= data.length - 1; selectAllCount++) {
      var group = data.at(selectAllCount) as FormGroup;
      var sunday = group.get('sunday').value;
      var monday = group.get('monday').value;
      var tuesday = group.get('tuesday').value;
      var wednesday = group.get('wednesday').value;
      var thursday = group.get('thursday').value;
      var friday = group.get('friday').value;
      var saturday = group.get('saturday').value;
      var applyAll = group.get('AllWeek').value;
      if (this.isIE == true) {
        if (checkBoxType === 'all') {
          if (applyAll) {
            this.checkBoxError = false;
          } else {
            if ((sunday || monday || tuesday || wednesday || thursday || friday || saturday) && applyAll == true) {
              this.checkBoxError = false;
            } else if (
              (sunday || monday || tuesday || wednesday || thursday || friday || saturday === false) &&
              applyAll === true
            ) {
              this.checkBoxError = false;
            } else if (
              sunday &&
              monday &&
              tuesday &&
              wednesday &&
              thursday &&
              friday &&
              saturday === true &&
              applyAll === false
            ) {
              this.checkBoxError = true;
            } else if (
              sunday == false &&
              monday == false &&
              tuesday == false &&
              wednesday == false &&
              thursday == false &&
              friday == false &&
              saturday == false &&
              applyAll == false
            ) {
              this.checkBoxError = true;
            }
          }
        } else {
          if (
            sunday == false &&
            monday == false &&
            tuesday == false &&
            wednesday == false &&
            thursday == false &&
            friday == false &&
            saturday == false &&
            checkBoxType === 'none'
          ) {
            this.checkBoxError = true;
          } else if (
            (sunday == true && checkBoxType === 'none') ||
            (monday == true && checkBoxType === 'none') ||
            (tuesday == true && checkBoxType === 'none') ||
            (wednesday == true && checkBoxType === 'none') ||
            (thursday == true && checkBoxType === 'none') ||
            (friday == true && checkBoxType === 'none') ||
            (saturday == true && checkBoxType === 'none')
          ) {
            this.checkBoxError = false;
          }
        }
      } else {
        if (sunday || monday || tuesday || wednesday || thursday || friday || saturday) {
          this.checkBoxError = false;
        } else {
          this.checkBoxError = true;
        }
      }

      num[selectAllCount] = this.checkBoxError;
      if (num[selectAllCount] == true) {
        if (formType === pageMode.edit) {
          this.checkBoxErrorMsg = Constant.TIME_SLOT_ERROR_MESSAGE; //"Please select atleast one day in Rule" + (i + 1);
          this.checkBoxValidator = true;
        } else {
          this.copycheckboxErrorMsg = Constant.TIME_SLOT_ERROR_MESSAGE;
          this.copycheckBoxValidator = true;
        }
      } else {
        if (formType === pageMode.edit) {
          this.checkBoxValidator = false;
          this.checkBoxErrorMsg = '';
        } else {
          this.copycheckBoxValidator = false;
          this.copycheckboxErrorMsg = '';
        }
      }
      this.setErrorRequired(group, this.checkBoxError);
    }
  }
  setErrorRequired(group: FormGroup, validatorValue: boolean) {
    const setvalue = validatorValue ? { required: true } : null;
    group.get('sunday').setErrors(setvalue);
    group.get('monday').setErrors(setvalue);
    group.get('tuesday').setErrors(setvalue);
    group.get('wednesday').setErrors(setvalue);
    group.get('thursday').setErrors(setvalue);
    group.get('friday').setErrors(setvalue);
    group.get('saturday').setErrors(setvalue);
    group.get('AllWeek').setErrors(setvalue);
  }

  addTimeSlotFormGroup(): FormGroup {
    return this._fb.group({
      Start: ['', Validators.required],
      End: ['', Validators.required],
      AllWeek: ['', Validators.required],
      sunday: ['', Validators.required],
      monday: ['', Validators.required],
      tuesday: ['', Validators.required],
      wednesday: ['', Validators.required],
      thursday: ['', Validators.required],
      friday: ['', Validators.required],
      saturday: ['', Validators.required]
    });
  }
  /*open  update order  */
  openDialog(error: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = error;
    this._matDialog.open(ErrorDailogComponent, dialogConfig);
  }
  /* Open - Method For Validation Dialog Box */
  openValidationDialog(msg: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = msg;
    this._matDialog.open(ValidationErrorDialogComponent, dialogConfig);
  }
  /* End - Method For Validation Dialog Box */
  getTimeFromTimeSlot(timeslots: any, timepos: number, timetype: string) {
    let count = timeslots.length;
    if (timepos < count) {
      if (timetype == 'start') {
        var convertTime = this.getCurrentTime(timeslots.value[timepos].Start);
        return convertTime;
      } else {
        var convertTime = this.getCurrentTime(timeslots.value[timepos].End);
        return convertTime;
      }
    }
  }

  getCurrentTime(timeInput: any) {
    var currentTime;
    let hour: string = timeInput.substr(0, 2);
    let hourNum = Number(hour);
    if (hourNum < 9) {
      hour = ('0' + hour).slice(-2);
    } else {
      hour = hour.slice(-2);
    }
    let minutes = timeInput.substr(3, 2);
    let minNum = Number(minutes);
    if (minNum < 9) {
      minutes = ('0' + minutes).slice(-2);
    } else {
      minutes = minutes.slice(-2);
    }
    currentTime = hour + ':' + minutes;
    return currentTime;
  }

  editOrderSubmit() {
    this.convertTime(pageMode.edit);
    this.validateTimePickers();
    this.checkBoxValidation(pageMode.edit, 'none');
    var data = this.editOrderForm.get('timeSlots') as FormArray;
    this.submitted = true;
    if (this.orderModel.SourceType == 'Calls') {
      if (this.maxCallSubmitError) {
        //document.getElementById('MaxCallCurrency').focus();
        this.MaxCallCurrency$.nativeElement.focus();
        return;
      }
    }
    if (this.SubmitError) {
      if (this.editOrderForm.get('HourlyCallCap').value < 0 || this.editOrderForm.get('HourlyCallCap').value > 100) {
        //document.getElementById('HourlyCallCap').focus();
        this.HourlyCallCap$.nativeElement.focus();
      } else {
        //document.getElementById('DailyCallCap').focus();
        this.DailyCallCap$.nativeElement.focus();
      }
    }
    for (var editTime = 0; editTime < data.length; editTime++) {
      var editTimeValue = this.getTimeFromTimeSlot(data, editTime, 'start');
      if (editTimeValue == '') {
        document.getElementById('startTime' + editTime).focus();
        break;
      }
    }

    // let endTimeErrorFlag = this.timevalidator(pageMode.edit);
    this.LocationErrorflag = false;
    if (this.locationList.length == 0) {
      this.LocationErrorflag = true;
      this.locationErrorNull = Constant.ERROR_ON_LOCATION;
      if (this.editOrderForm.valid) {
        document.getElementById('State').focus();
      }
      if (this.editOrderForm.get('State').value === 'undefined' || this.editOrderForm.get('State').value === '') {
        this.editOrderForm.get('State').setErrors({ required: true });
      }
    } else {
      this.setStateControlValue();
    }
    if (this.SubmitErrorFlag) {
      document.getElementById('OrderName').focus();
    }
    if (this.errorFlagStartDate) {
      (<HTMLInputElement>document.getElementById('StartDate')).focus();
    } else if (this.errorFlagEndDate) {
      (<HTMLInputElement>document.getElementById('EndDate')).focus();
    }

    this.checkDateValidity(pageMode.edit);
    /*Checking date format validation for mat start and end date-Shivang_26-12-19*/
    if (this.endDateValid && this.startDateValid) {
      this.transformDateFormat(pageMode.edit, this.orderModel.StartDate, this.orderModel.EndDate);
    }
    this.endTimeFlag = true;
    this.startTimeFlag = true;
    this.compareTimevalidator(pageMode.edit, 'submit');
    if (
      this.compareTimeErrorFlag ||
      //endTimeErrorFlag ||
      this.editOrderForm.valid == false ||
      this.errorFlagStartDate ||
      this.errorFlagEndDate ||
      this.SubmitError ||
      this.SubmitErrorFlag ||
      this.LocationErrorflag ||
      this.checkBoxValidator === true ||
      this.startDateLimitFlag === false ||
      !this.endDateValid ||
      !this.startDateValid ||
      !this.startTimeFlag ||
      !this.endTimeFlag
    ) {
      return;
    }

    this.enableSpinner();
    this.showLoadingIndicator = true;
    this.copyOrderClickFlag = false;
    this.saveUpdateOrder(
      this.editOrderForm,
      this.integrationList,
      this.locationList,
      pageMode.edit,
      this.locationStateCountyZipList
    );

    this.disableSpinner();
  }
  /* open get account balance*/
  getAccountBalance() {
    this._orderService.getAccountFundingBalance().subscribe((data: any) => {
      if (data != null) {
        this.balance = data;
      } else {
        this.balance = '0';
      }
    });
  }
  /* open save edit and copy order */
  saveUpdateOrder(orderForm: FormGroup, leadSourcesData: any, locationData: any, formType: pageMode, stateList: any) {
    if (this.copyOrderClickFlag) {
      this.enableSpinner();
    }

    this._orderService.createOrder(orderForm, leadSourcesData, locationData, stateList).subscribe(
      res => {
        if (res['OrderSaveStatus'] != 'Success') {
          window.scrollTo(0, 0);
          this.openDialog(res['ErrorMessageList']);
          this.disableSpinner();
        } else {
          if (res['deliveryDataStatus'] != 'Success') {
            this._service.setDeliveryMessage(Constant.deliver_Data_Message);
            this.deliveryDataMessage = Constant.deliver_Data_Message;
            this.showDeliveryWarning = true;
          }
          if (formType === pageMode.edit) {
            if (this.copyOrderClickFlag) {
              this.disableSpinner();
              window.scrollTo(0, 0);
              this._service.setFormType('edit');
              this.showSuccessMessage(Constant.MESSAGES.ORDER_UPDATED_SUCCESSFULLY.msg);
            } else {
              this._service.setLeadType(this.editOrderForm.get('OrderType').value);
              this.disableSpinner();
              window.scrollTo(0, 0);
              this._service.setSuccessMessage(Constant.MESSAGES.ORDER_UPDATED_SUCCESSFULLY.msg);
              let navigationExtras: NavigationExtras = {
                queryParams: { msg: 'add-order-success' }
              };
              this._router.navigate(['/order-summary'], navigationExtras);
            }
          } else {
            this.copyOrderCreatedId = res['ErrorMessageList'][0];
            if (this.copyOrderCreatedFlag) {
              this.disableSpinner();
              window.scrollTo(0, 0);
              this.showSuccessMessage(Constant.MESSAGES.ORDER_COPIED_SUCCESSFULLY.msg);
              this.copyOrderCreatedFlag = false;
            } else {
              this.disableSpinner();
              window.scrollTo(0, 0);
              this.showSuccessMessage(Constant.MESSAGES.ORDER_COPIED_UPDATED_SUCCESSFULLY.msg);
            }
          }
        }
      },
      err => {
        this._logger.error(Constant.submitLogMsg);
      }
    );
  }
  /* open order name validation Start */
  validateOrderName(event: any, formType: pageMode) {
    this.setCopyBtnDisable();
    this.orderName = '';
    this.orderError = '';
    this.copyOrderError = '';
    this.orderName = this.trimming_fn(event.target.value);
    if (this.orderName == '') {
      this.orderError = Constant.ORDER_NAME_MSG;
      this.copyOrderError = Constant.ORDER_NAME_MSG;
      if (formType === pageMode.edit) {
        this.SubmitErrorFlag = true;
      } else {
        this.copySubmitErrorFlag = true;
      }
    } else {
      if (formType === pageMode.edit) {
        this.SubmitErrorFlag = false;
      } else {
        this.copySubmitErrorFlag = false;
      }
    }
  }

  trimming_fn(x: any) {
    return x ? x.replace(/^\s+|\s+$/gm, '') : '';
  }
  /* end order name validation end */
  checkDateValidity(type: pageMode) {
    if (type === pageMode.edit) {
      const startDateValue = (<HTMLInputElement>document.getElementById('StartDate')).value;
      const endDateValue = (<HTMLInputElement>document.getElementById('EndDate')).value;
      if (startDateValue !== '' && !this.isValidDate(startDateValue)) {
        this.startDateErrorMsg = Constant.ENDDATEERROR;
        this.startDateValid = false;
      } else {
        this.startDateErrorMsg = '';
        this.startDateValid = true;
      }
      if (endDateValue !== '' && !this.isValidDate(endDateValue)) {
        this.endDateErrorMsg = Constant.ENDDATEERROR;
        this.endDateValid = false;
      } else {
        this.endDateErrorMsg = '';
        this.endDateValid = true;
      }
    } else {
      const copyStartDateValue = this.copyStartDate$.nativeElement.value; // (<HTMLInputElement>document.getElementById('copyStartDate')).value;
      const copyEndDateValue = this.copyEndDate$.nativeElement.value; // (<HTMLInputElement>document.getElementById('copyEndDate')).value;
      if (copyStartDateValue !== '' && !this.isValidDate(copyStartDateValue)) {
        this.copyStartDateErrorMsg = Constant.ENDDATEERROR;
        this.copyStartDateValid = false;
      } else {
        this.copyStartDateErrorMsg = '';
        this.copyStartDateValid = true;
      }
      if (copyEndDateValue !== '' && !this.isValidDate(copyEndDateValue)) {
        this.copyEndDateErrorMsg = Constant.ENDDATEERROR;
        this.copyEndDateValid = false;
      } else {
        this.copyEndDateErrorMsg = '';
        this.copyEndDateValid = true;
      }
    }
  }
  /* Start - Method for Start and End Date Validation- Shivang_20-12-19*/
  validateDate(event: any, StartDate: any, EndDate: any): void {
    this.checkDateValidity(pageMode.edit);
    this.setCopyBtnDisable();
    this.errorMessage = '';
    this.errorMessageStartDate = '';
    this.errorMessageStartDatelimit = '';
    this.errorMessagelimit = '';
    let inputStartDate = StartDate;
    let inputEndDate = EndDate;
    inputStartDate = this._datePipe.transform(inputStartDate, 'yyyy-MM-dd');
    inputEndDate = this._datePipe.transform(inputEndDate, 'yyyy-MM-dd');
    if (inputEndDate < this.today && inputEndDate != null) {
      this.disabledSatusFlag = true;
      this.orderModel.Status = 'false';
      this.disableStatusButton();
    } else {
      this.disabledSatusFlag = false;
      this.disableStatusButton();
    }
    if (inputStartDate != null) {
      this.startDateErrorMsg = '';
      this.startDateValid = true;
      /*date validation changes -monika*/
      if (inputStartDate < this.minStartDate) {
        /*date validation changes -monika*/
        this.errorMessageStartDatelimit = Constant.DATELIMITERROR;
        this.startDateLimitFlag = false;
        (<HTMLInputElement>document.getElementById('StartDate')).focus();
      } else {
        this.errorMessageStartDatelimit = '';
        this.startDateLimitFlag = true;
      }
    }

    if (inputEndDate != null) {
      this.endDateErrorMsg = '';
      this.endDateValid = true;
      /*date validation changes -monika*/
      if (inputEndDate < this.minEndDate) {
        /*date validation changes -monika*/
        this.errorMessagelimit = Constant.DATELIMITERROR;
        this.errorFlagEndDate = true;
        (<HTMLInputElement>document.getElementById('EndDate')).focus();
      }
    }
    if (inputStartDate == '') {
      this.errorMessageStartDatelimit = '';
    }
    if (inputEndDate == '' || inputEndDate == null) {
      this.errorMessage = '';
      this.errorMessagelimit = '';
      this.errorFlagEndDate = false;
      this.errorMessageStartDate = '';
      return;
    }
    if (inputStartDate != null && inputEndDate != null) {
      let selectedDateField;
      //For mat input event.targetElement.name gives field name insted event.target.name-Shivang_261219
      selectedDateField = event.targetElement.name;
      if (selectedDateField == 'EndDate' && inputEndDate <= inputStartDate) {
        this.errorMessage = Constant.END_DATE_ERROR_MSG;
        this.errorFlagEndDate = true;
        (<HTMLInputElement>document.getElementById('EndDate')).focus();
      } else {
        this.errorFlagEndDate = false;
      }
      if (selectedDateField == 'StartDate' && inputStartDate >= inputEndDate) {
        this.errorMessageStartDate = Constant.START_DATE_ERROR_MSG;
        this.errorFlagStartDate = true;
        (<HTMLInputElement>document.getElementById('StartDate')).focus();
      } else {
        this.errorFlagStartDate = false;
      }
    }
  }
  /* Start - Method for Start and End Date Validation for Copy Order- Shivang_20-12-19*/
  validateCopyDate(event: any, StartDate: any, EndDate: any): void {
    this.checkDateValidity(pageMode.copy);
    this.copyErrorMessage = '';
    this.copyErrorMessageStartDate = '';
    this.copyErrorMessageStartDatelimit = '';
    this.copyErrorMessagelimit = '';
    let inputCopyStartDate = StartDate;
    let inputCopyEndDate = EndDate;
    inputCopyStartDate = this._datePipe.transform(inputCopyStartDate, 'yyyy-MM-dd');
    inputCopyEndDate = this._datePipe.transform(inputCopyEndDate, 'yyyy-MM-dd');

    if (inputCopyStartDate != null) {
      this.copyStartDateErrorMsg = '';
      this.copyStartDateValid = true;
      /*date validation changes -monika*/
      if (inputCopyStartDate < this.today) {
        /*date validation changes -monika*/
        this.copyErrorMessageStartDatelimit = Constant.DATELIMITERROR;
        this.copyStartDateLimitFlag = false;
        this.copyStartDate$.nativeElement.focus();
        //(<HTMLInputElement>document.getElementById('copyStartDate')).focus();
      } else {
        this.copyErrorMessageStartDatelimit = '';
        this.copyStartDateLimitFlag = true;
      }
    }
    if (inputCopyEndDate != null) {
      /*date validation changes -monika*/
      this.copyEndDateErrorMsg = '';
      this.copyEndDateValid = true;
      if (inputCopyEndDate < this.today) {
        /*date validation changes -monika*/
        this.copyErrorMessagelimit = Constant.DATELIMITERROR;
        this.copyErrorFlagEndDate = true;
        (<HTMLInputElement>document.getElementById('CopyEndDate')).focus();
      }
    }
    if (inputCopyStartDate == '') {
      this.copyErrorMessageStartDatelimit = '';
    }
    if (inputCopyEndDate == '' || inputCopyEndDate == null) {
      this.copyErrorMessage = '';
      this.copyErrorMessageStartDate = '';
      this.copyErrorFlagEndDate = false;
      this.copyErrorFlagStartDate = false;
      this.copyErrorMessagelimit = '';
    }

    if (inputCopyStartDate != null && inputCopyEndDate != null) {
      let selectedDateField;
      //For mat input event.targetElement.name gives field name insted event.target.name-Shivang_261219
      selectedDateField = event.targetElement.name;

      if (selectedDateField == 'EndDate' && inputCopyEndDate <= inputCopyStartDate) {
        this.copyErrorMessage = Constant.END_DATE_ERROR_MSG;
        this.copyErrorFlagEndDate = true;
        this.copyEndDate$.nativeElement.focus();
        //(<HTMLInputElement>document.getElementById('CopyEndDate')).focus();
      } else {
        this.copyErrorFlagEndDate = false;
      }
      if (selectedDateField == 'StartDate' && inputCopyStartDate >= inputCopyEndDate) {
        this.copyErrorMessageStartDate = Constant.START_DATE_ERROR_MSG;
        this.copyErrorFlagStartDate = true;
        this.copyStartDate$.nativeElement.focus();
        //(<HTMLInputElement>document.getElementById('CopyStartDate')).focus();
      } else {
        this.copyErrorFlagStartDate = false;
      }
    }
  }
  /* open  leadcap section validation start */
  validateMaxCallCurrency(event: any, formType: pageMode) {
    if (this.browserName == 'ie') {
      if (formType == pageMode.edit) {
        this.checkMaxCallNumber(event, 'maxCall');
      } else {
        this.checkCopyMaxCallNumber(event, 'maxCall');
      }
    }
    let editMaxCallCapLimitError = <HTMLInputElement>document.getElementById('EditMaxCallCapLimtError');
    let copyMaxCallCapLimitError = <HTMLInputElement>document.getElementById('CopyMaxCallCapLimtError');
    this.setCopyBtnDisable();
    this.maxCallCurrencyRangeError = '';
    this.copyMaxCallCurrencyRangeError = '';
    this.checkMaxCallCurrencyRange = this.checkRange(event.target.value);
    if (this.checkMaxCallCurrencyRange) {
      if (formType === pageMode.edit) {
        editMaxCallCapLimitError.setAttribute('style', 'display:none;');
        this.maxCallCurrencyRangeError = '';
        this.maxCallSubmitError = false;
      } else {
        copyMaxCallCapLimitError.setAttribute('style', 'display:block;');
        this.copyMaxCallCurrencyRangeError = '';
        this.copyMaxCallSubmitError = false;
      }
    } else {
      if (formType === pageMode.edit) {
        editMaxCallCapLimitError.setAttribute('style', 'display:block;');
        this.maxCallCurrencyRangeError = Constant.limitCapError;
        this.maxCallSubmitError = true;
      } else {
        copyMaxCallCapLimitError.setAttribute('style', 'display:block;');
        this.copyMaxCallCurrencyRangeError = Constant.limitCapError;
        this.copyMaxCallSubmitError = true;
      }
    }
  }

  validateDailyLeadCap(event: any, formType: pageMode) {
    if (this.browserName == 'ie') {
      if (formType == pageMode.edit) {
        this.checkDailyCapNumber(event, 'daily');
      } else {
        this.checkCopyDailyCapNumber(event, 'daily');
      }
    }
    this.setCopyBtnDisable();
    this.dailyCallCapError = '';
    let editDailyCallCapLimitError = <HTMLInputElement>document.getElementById('EditDailyCallCapLimtError');
    let copyDailyCallCapLimitError = <HTMLInputElement>document.getElementById('CopyDailyCallCapLimtError');
    this.checkdailyCapRange = this.checkRange(event.target.value);
    if (this.checkdailyCapRange) {
      if (formType === pageMode.edit) {
        this.compareValue('DailyCallCap');
      } else {
        this.compareCopyValue('DailyCallCap');
      }
    } else {
      this.dailyCallCapError = Constant.limitCapError;
      this.copyDailyCallCapError = Constant.limitCapError;
      this.SubmitError = true;
      this.copySubmitError = true;
      if (formType === pageMode.edit) {
        editDailyCallCapLimitError.setAttribute('style', 'display:block;');
      } else {
        copyDailyCallCapLimitError.setAttribute('style', 'display:block;');
      }
    }
  }

  validateHourlyLeadCap(event: any, formType: pageMode) {
    if (this.browserName == 'ie') {
      if (formType == pageMode.edit) {
        this.checkHourlyNumber(event, 'hourly');
      } else {
        this.checkCopyHourlyNumber(event, 'hourly');
      }
    }
    this.setCopyBtnDisable();
    this.hourlyCallCapError = '';
    this.checkHourlyCapRange = this.checkRange(event.target.value);
    let editHourlyCallCapLimitError = <HTMLInputElement>document.getElementById('EditHourlyCallCapLimtError');
    let copyHourlyCallCapLimitError = <HTMLInputElement>document.getElementById('CopyHourlyCallCapLimtError');
    if (this.checkHourlyCapRange) {
      if (formType === pageMode.edit) {
        this.compareValue('HourlyCallCap');
      } else {
        this.compareCopyValue('HourlyCallCap');
      }
    } else {
      this.hourlyCallCapError = Constant.limitCapError;
      this.copyHourlyCallCapError = Constant.limitCapError;
      if (formType === pageMode.edit) {
        editHourlyCallCapLimitError.setAttribute('style', 'display:block;');
      } else {
        copyHourlyCallCapLimitError.setAttribute('style', 'display:block;');
      }
      this.SubmitError = true;
      this.copySubmitError = true;
    }
  }
  /*validate phoneNumber*/
  validatePhoneNumber(value: string, formType: pageMode) {
    if (value !== '' && this.lastPhoneSelect !== value) {
      let phone: string = reformatePhone(value);
      this.lastPhoneSelect = phone;
      this.phoneNumberlengthError = phone.length >= 10 ? '' : Constant.PHONE_NUMBER_LENGTH_ERROR_MSG;
      if (formType == pageMode.edit) {
        this.editOrderForm.get('PhoneNumber').setValue(phone);
      } else {
        this.copyOrderForm.get('PhoneNumber').setValue(phone);
      }
    } else {
      this.lastPhoneSelect = '';
    }
  }
  /*validate phoneNumber*/
  checkRange(value: any) {
    if ((value < 1 || value > 100) && value != '') {
      return false;
    }
    return true;
  }

  compareValue(eventName: string) {
    this.orderModel.DailyCap = +this.editOrderForm.get('DailyCallCap').value;
    this.orderModel.HourlyCap = +this.editOrderForm.get('HourlyCallCap').value;
    if (this.orderModel.DailyCap > 0 && this.orderModel.HourlyCap > 0) {
      if (eventName == 'HourlyCallCap') {
        this.checkdailyCapRange = this.checkRange(this.orderModel.DailyCap);
        if (this.orderModel.DailyCap < this.orderModel.HourlyCap) {
          this.hourlyCallCapError = Constant.HOURLY_CALL_CAP_ERROR_MSG;
          if (this.checkdailyCapRange) {
            this.dailyCallCapError = '';
          } else {
            this.dailyCallCapError = Constant.limitCapError;
            this.SubmitError = true;
          }
          this.SubmitError = true;
        } else {
          if (this.checkdailyCapRange) {
            this.dailyCallCapError = '';
            this.SubmitError = false;
          }
          this.hourlyCallCapError = '';
        }
      }
      //check for Daily Call Cap
      else {
        if (this.orderModel.DailyCap < this.orderModel.HourlyCap) {
          this.dailyCallCapError = Constant.DAILY_CALL_CAP_ERROR_MSG;
          this.checkHourlyCapRange = this.checkRange(this.orderModel.HourlyCap);
          if (this.checkHourlyCapRange) {
            this.hourlyCallCapError = '';
          } else {
            this.hourlyCallCapError = Constant.limitCapError;
            this.SubmitError = true;
          }
          this.SubmitError = true;
        } else {
          this.hourlyCallCapError = '';
          this.dailyCallCapError = '';
          this.SubmitError = false;
        }
      }
    }
  }

  compareCopyValue(eventName: any) {
    this.copyOrderModel.DailyCap = +this.copyOrderForm.get('DailyCallCap').value;
    this.copyOrderModel.HourlyCap = +this.copyOrderForm.get('HourlyCallCap').value;
    if (this.copyOrderModel.DailyCap > 0 && this.copyOrderModel.HourlyCap > 0) {
      if (eventName == 'HourlyCallCap') {
        this.checkdailyCapRange = this.checkRange(this.copyOrderModel.DailyCap);
        if (this.copyOrderModel.DailyCap < this.copyOrderModel.HourlyCap) {
          this.copyHourlyCallCapError = Constant.HOURLY_CALL_CAP_ERROR_MSG;

          if (this.checkdailyCapRange) {
            this.copyDailyCallCapError = '';
          } else {
            this.copyDailyCallCapError = Constant.limitCapError;
            this.copySubmitError = true;
          }
          this.copySubmitError = true;
        } else {
          if (this.checkdailyCapRange) {
            this.copyDailyCallCapError = '';
            this.copySubmitError = false;
          }
          this.copyHourlyCallCapError = '';
        }
      } else {
        if (this.copyOrderModel.DailyCap < this.copyOrderModel.HourlyCap) {
          this.copyDailyCallCapError = Constant.DAILY_CALL_CAP_ERROR_MSG;
          this.checkHourlyCapRange = this.checkRange(this.copyOrderModel.HourlyCap);
          if (this.checkHourlyCapRange) {
            this.copyHourlyCallCapError = '';
          } else {
            this.copyHourlyCallCapError = Constant.limitCapError;
            this.copySubmitError = true;
          }
          this.copySubmitError = true;
        } else {
          this.copyHourlyCallCapError = '';
          this.copyDailyCallCapError = '';
          this.copySubmitError = false;
        }
      }
    }
  }
  /* open set checkbox value on apply all */
  setCheckboxValue(checkBoxValue: any, i: number, formType: pageMode) {
    var timeslotControl = null;
    var data = null;
    if (formType === pageMode.edit) {
      data = this.editOrderForm.get('timeSlots') as FormArray;
    } else {
      data = this.copyOrderForm.get('timeSlots') as FormArray;
    }
    var group = data.at(i) as FormGroup;
    if (checkBoxValue == true) {
      timeslotControl = group.get('sunday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('monday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('tuesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('wednesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('thursday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('friday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('saturday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
    } else {
      timeslotControl = group.get('sunday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('monday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('tuesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('wednesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('thursday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('friday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('saturday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
    }

    group.updateValueAndValidity();
  }
  /* open apply all and deselect all */

  selectAllDay(i: number, formType: pageMode): void {
    var data = null;
    if (formType === pageMode.edit) {
      data = this.editOrderForm.get('timeSlots') as FormArray;
    } else {
      data = this.copyOrderForm.get('timeSlots') as FormArray;
    }

    var group = data.at(i) as FormGroup;
    var applyAll = group.get('AllWeek');
    if (this.isIE == false) {
      if (applyAll.value == true) {
        this.setCheckboxValue(false, i, formType);
      } else {
        this.setCheckboxValue(true, i, formType);
      }
    } else {
      if (applyAll.value == false) {
        this.setCheckboxValue(false, i, formType);
      } else {
        this.setCheckboxValue(true, i, formType);
      }
    }
  }

  /* end apply all and deselect all */
  clearTimeflag(formType: pageMode) {
    if (formType == pageMode.edit && this.isIeBrowser) {
      this.startTimeFlagErr = '';
      this.endTimeFlagErr = '';
    } else if (formType == pageMode.copy && this.isIeBrowser) {
      this.copyStartTimeFlagErr = '';
      this.copyEndTimeFlagErr = '';
    }
  }
  /* open unselect apply all checkbox */

  unSelectApplyAll(i: number, formType: pageMode) {
    this.setCopyBtnDisable();
    var data = null;
    if (formType == pageMode.edit) {
      data = this.editOrderForm.get('timeSlots') as FormArray;
    } else {
      data = this.copyOrderForm.get('timeSlots') as FormArray;
    }
    var group = data.at(i) as FormGroup;
    var applyAll = group.get('AllWeek');
    if (applyAll.value) {
      var allWeekControl = group.get('AllWeek');
      allWeekControl.setValue(false);
    }
  }

  /* end unselect apply all checkbox */

  /* open set empty values to form control on state change */

  setEmptyValueToFormControl(formType: pageMode) {
    if (formType === pageMode.edit) {
      const CountyControl = this.editOrderForm.get('County');
      const ZipCodeControl = this.editOrderForm.get('ZipCode');
      CountyControl.setValue('');
      ZipCodeControl.setValue('');
      CountyControl.updateValueAndValidity();
      ZipCodeControl.updateValueAndValidity();
    } else {
      const CountyControl = this.copyOrderForm.get('County');
      const ZipCodeControl = this.copyOrderForm.get('ZipCode');
      CountyControl.setValue('');
      ZipCodeControl.setValue('');
      CountyControl.updateValueAndValidity();
      ZipCodeControl.updateValueAndValidity();
    }
  }

  /* end set empty values to form control on state change */

  /* open get county data on state change */

  onchangeState(event: string, formType: pageMode): void {
    this.locationError = '';
    this.countyData = []; // county
    this.clearCountyDropDown();
    this.zipData = []; // zip code
    this.countyCopyData = [];
    this.copyZipData = [];
    if (formType === pageMode.edit) {
      this.setCopyBtnDisable();
      if (this.checkSelectedField === 'none' || this.checkSelectedField === 'state') {
        this.setEmptyValueToFormControl(formType);
        this.checkSelectedField = 'state';
      }
      this.state = false;
      if (event === '') {
        if (this.checkSelectedField === 'none' || this.checkSelectedField === 'state') {
          this.checkSelectedField = 'none';
          this.countyData = [];
          this.zipData = [];
          const countyControl = this.editOrderForm.get('County');
          countyControl.setValue('');
          countyControl.updateValueAndValidity();
        }
      } else {
        if (
          (this.checkSelectedField === 'none' || this.checkSelectedField === 'state') &&
          this.editOrderForm.get('OrderType').value == 'Leads'
        ) {
          this.enableSpinner();
        }
      }

      if (event != 'null' && event !== '') {
        if (this.checkSelectedField === 'none' || this.checkSelectedField === 'state') {
          this.state = true;
          this.stateForService = event;
          if (this.editOrderForm.get('OrderType').value == 'Calls') {
            return;
          }
          this._orderService.getCountyData(event).subscribe(
            res => {
              this.disableSpinner();
              this.countyData = JSON.parse(res.toString());
            },
            err => {
              this._logger.error(Constant.countyLogMsg);
            }
          );
        }
      }
    } else {
      this.setCopyBtnDisable();
      if (this.copyLocationStartingPoint === 'none' || this.copyLocationStartingPoint === 'state') {
        this.setEmptyValueToFormControl(formType);
        this.copyLocationStartingPoint = 'state';
      }
      this.state = false;
      if (event === '') {
        if (this.copyLocationStartingPoint === 'none' || this.copyLocationStartingPoint === 'state') {
          this.copyLocationStartingPoint = 'none';
          this.countyCopyData = [];
          this.copyZipData = [];
          const countyControl = this.copyOrderForm.get('County');
          countyControl.setValue('');
          countyControl.updateValueAndValidity();
        }
      } else {
        if (
          (this.copyLocationStartingPoint === 'none' || this.copyLocationStartingPoint === 'state') &&
          this.copyOrderForm.get('OrderType').value == 'Leads'
        ) {
          this.enableSpinner();
        }
      }

      if (event != 'null' && event !== '') {
        if (this.copyLocationStartingPoint === 'none' || this.copyLocationStartingPoint === 'state') {
          this.state = true;
          this.stateForService = event;
          if (this.copyOrderForm.get('OrderType').value == 'Calls') {
            return;
          }
          this._orderService.getCountyData(event).subscribe(
            res => {
              this.disableSpinner();
              this.countyCopyData = JSON.parse(res.toString());
            },
            err => {
              //copy
              this._logger.error(Constant.countyLogMsg);
            }
          );
        }
      }
    }
  }

  /* end get county data on state change */

  /* open get zipcode data on county change */

  onchangeCounty(event: boolean, formType: pageMode): void {
    this.zipData = [];
    if (event == false && this.isReadySend && this.ListCountySelected.length > 0) {
      // finish select counties
      let countys = this.ListCountySelected;
      this.zipData = [];
      this.copyZipData = [];
      let counties;
      if (formType === pageMode.edit) {
        if (countys.length == 0) {
          this.disableSpinner();
        } else if (this.checkSelectedField === 'state' && countys.length > 0) {
          this.enableSpinner();
        }
        this.setCopyBtnDisable();
        const zipCodeControl = this.editOrderForm.get('ZipCode');
        if (this.checkSelectedField == 'state') {
          zipCodeControl.setValue('');
          zipCodeControl.updateValueAndValidity();
        } else {
          if (this.locationData.length === 1) {
            const stateControl = this.editOrderForm.get('State');
            stateControl.setValue(this.locationData[0].state);
            stateControl.updateValueAndValidity();
          }
        }
        // this.countyName = event.options[event.selectedIndex].getAttribute('data-isocode');
        this.county = false;
        if (countys.length > 0 && this.checkSelectedField === 'state') {
          this.county = true;
          counties = countys.join(',');
          this._orderService.getZipData(counties, this.stateForService).subscribe(
            (res: Location[]) => {
              this.zipData = res;
              this.disableSpinner();
              this.isAddCountyReady = true;
            },
            err => {
              this._logger.error(Constant.zipcodeLogMsg);
            }
          );
        }
      } else {
        if (countys.length == 0) {
          this.disableSpinner();
        } else if (this.copyLocationStartingPoint === 'state' && countys.length > 0) {
          this.enableSpinner();
        }
        this.setCopyBtnDisable();
        const zipCodeControl = this.copyOrderForm.get('ZipCode');
        if (this.copyLocationStartingPoint == 'state') {
          zipCodeControl.setValue('');
          zipCodeControl.updateValueAndValidity();
        } else {
          if (this.locationCopyData.length === 1) {
            const statecontrol = this.copyOrderForm.get('State');
            statecontrol.setValue(this.locationCopyData[0].state);
            statecontrol.updateValueAndValidity();
          }
        }
        // this.countyName = event.options[event.selectedIndex].getAttribute('data-isocode');
        this.county = false;
        if (countys.length > 0 && this.copyLocationStartingPoint === 'state') {
          this.county = true;
          counties = countys.join(',');
          this._orderService.getZipData(counties, this.stateForService).subscribe(
            (res: Location[]) => {
              this.copyZipData = res;
              this.disableSpinner();
              this.isAddCountyReady = true;
            },
            err => {
              //copy
              this._logger.error(Constant.zipcodeLogMsg);
            }
          );
        }
      }
    }
  }

  /* end get zipcode data on county change */
  changeValidityOnDeleteLocation(formType: pageMode) {
    var stateControl;
    if (formType === pageMode.edit) {
      stateControl = this.editOrderForm.get('State');
    } else {
      stateControl = this.copyOrderForm.get('State');
    }
    stateControl.setValue('');
    stateControl.setValidators([Validators.required]);
    stateControl.updateValueAndValidity();
  }

  deleteLocationRow(locationIndex: number, formType: pageMode, state: string) {
    let countyIndex: number;
    if (formType === pageMode.edit) {
      this.checkSelectedField = 'state';
      countyIndex = this.selectednumber * (this.curPageEditLocation - 1) + locationIndex;
      this.setCopyBtnDisable();
      this.deleteStateFromStateListInJson(state, pageMode.edit);
      this.insertDeleteLocationEditList(state);
      if (this.locationList.length <= 1) {
        this.changeValidityOnDeleteLocation(pageMode.edit);
      }
      this.locationList.splice(countyIndex, 1);
    } else {
      this.copyLocationStartingPoint = 'state';
      countyIndex = this.selectednumber * (this.curPageCopyLocation - 1) + locationIndex;
      this.deleteStateFromStateListInJson(state, pageMode.copy);
      this.insertDeleteLocationCopyList(state);
      if (this.locationList.length <= 1) {
        this.changeValidityOnDeleteLocation(pageMode.copy);
      }
      this.copyOrderLocationList.splice(countyIndex, 1);
    }
  }

  /* open remove state from state list */

  deleteStateFromStateListInJson(state: any, formType: pageMode) {
    if (formType === pageMode.edit) {
      if (
        this.locationStateCountyZipList.some(function(arrVal) {
          return state.toString().trim() == arrVal;
        })
      ) {
        let deletedIndexValue = this.locationStateCountyZipList.indexOf(state.toString().trim());
        this.locationStateCountyZipList.splice(deletedIndexValue, 1);
      }
    } else {
      if (
        this.locationStateCountyZipCopyList.some(function(arrVal) {
          return state.toString().trim() == arrVal;
        })
      ) {
        let deletedCopyIndexValue = this.locationStateCountyZipCopyList.indexOf(state.toString().trim());
        this.locationStateCountyZipCopyList.splice(deletedCopyIndexValue, 1);
      }
    }
  }

  /* end remove state from state list */

  /* Open - Insert Location List */

  insertLocationList(state: string, county: string, zipCode: string, countyList: Location[], formType: pageMode) {
    let OrderTypeEdit = this.editOrderForm.get('OrderType').value;
    if (formType === pageMode.edit) {
      if (OrderTypeEdit == 'Calls') {
        if (!this.locationList.some(item => item.state.toString().trim() == state.toString().trim())) {
          let callLocationListEdit = new Location();
          callLocationListEdit.state = state;
          this.locationList.push(callLocationListEdit);
        } else {
          this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
        }
      } else {
        for (let ItemCounty of countyList) {
          if (
            this.locationList.some(
              item =>
                item.state.toString().trim() == state.toString().trim() &&
                item.countyName.toString().trim() == ItemCounty.countyName.toString().trim()
            )
          ) {
            let countyRows = this.locationList.filter(i => i.countyName == ItemCounty.countyName).length;
            for (var i = 0; i < countyRows; i++) {
              let indexCounty = this.locationList.findIndex(i => i.countyName == ItemCounty.countyName);
              if (indexCounty > -1) this.locationList.splice(indexCounty, 1);
            }
          }
        }

        let dataLocationListEdit = new Location();
        dataLocationListEdit.state = state;
        dataLocationListEdit.countyName = 'All Counties';
        dataLocationListEdit.zipCode = null;
        if (
          this.locationList.some(
            i => i.state == dataLocationListEdit.state && i.countyName == dataLocationListEdit.countyName
          ) == false
        ) {
          this.locationList.push(dataLocationListEdit);
        } else {
          this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
        }
      }
    } else {
      let OrderTypeCopy = this.copyOrderForm.get('OrderType').value;
      if (OrderTypeCopy == 'Calls') {
        if (!this.copyOrderLocationList.some(item => item.state.toString().trim() == state.toString().trim())) {
          let callLocationListCopy = new Location();
          callLocationListCopy.state = state;
          this.copyOrderLocationList.push(callLocationListCopy);
        } else {
          this.copyLocationError = Constant.LOCATION_EXIST_ERROR_MSG;
        }
      } else {
        for (let countyValue of countyList) {
          if (
            this.copyOrderLocationList.some(
              item =>
                item.state.toString().trim() == state.toString().trim() &&
                item.countyName.toString().trim() == countyValue.countyName.toString().trim()
            )
          ) {
            let countyRows = this.copyOrderLocationList.filter(i => i.countyName == county[0]).length;
            for (var i = 0; i < countyRows; i++) {
              let indexCounty = this.copyOrderLocationList.findIndex(i => i.countyName == county[0]);
              if (indexCounty > -1) this.copyOrderLocationList.splice(indexCounty, 1);
            }
          }
        }
        let dataLocationListCopy = new Location();
        dataLocationListCopy.state = state;
        dataLocationListCopy.countyName = 'All Counties';
        dataLocationListCopy.zipCode = null;
        if (
          this.locationList.some(
            i => i.state == dataLocationListCopy.state && i.countyName == dataLocationListCopy.countyName
          ) == false
        ) {
          this.copyOrderLocationList.push(dataLocationListCopy);
        } else {
          this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
        }
      }
    }
  }

  /* End - Insert Location List */
  insertLocationStateCountyZipList(state: string, county: any, zipCode: any) {
    if (zipCode == '' && county.length == 0 && state != undefined) {
      if (state != '' && !this.locationStateCountyZipList.some(item => item == state)) {
        this.locationStateCountyZipList.push(state);
      }
    }
  }

  insertDeleteLocationEditList(str: any) {
    let indexState = this.locationStateCountyZipList.indexOf(str);
    if (indexState >= 0) this.locationStateCountyZipList.splice(this.locationStateCountyZipList.indexOf(str), 1);
  }
  /* Open - Add location list in edit order */

  addLocation(state: string, county: string[], zipCode: number) {
    this._logger.debug('addLocation() invoked.');
    this.insertLocationStateCountyZipList(state, county, zipCode);
    this.setCopyBtnDisable();
    this.locationErrorNull = '';
    this.showAllZipCode = false;
    let countiestr = county.join(',');
    this._logger.debug('this.zipData is ' + this.zipData);
    if (state == null || state === '') {
      this.locationError = Constant.STATE_COUNTY_REQ_ERROR_MSG;
      if (this.isCopyOrderForm == true) {
        this.copyOrderForm.get('State').setErrors({ required: true });
      } else {
        this.editOrderForm.get('State').setErrors({ required: true });
      }
    } else {
      if (county == null || county.length == 0) {
        if (this.locationList.some(location => location.state === state)) {
          this.filteredCounties = this.countyData.filter(
            (countyItems: any) => !this.locationList.some(location => location.countyName === countyItems)
          );
          if (Array.isArray(this.filteredCounties) && this.filteredCounties.length) {
            if (zipCode) {
              this.locationError = Constant.SELECT_COUNTY_ERROR_MSG;
            } else {
              this.insertLocationList(state, countiestr, zipCode.toString(), this.filteredCounties, pageMode.edit);
              this.checkSelectedField = 'none';
            }
          } else {
            this.locationError = Constant.STATE_ALREADY_EXIST;
          }
        } else {
          if (this.checkSelectedField === 'state') {
            this.insertLocationList(state, countiestr, zipCode.toString(), this.countyData, pageMode.edit);
            this.checkSelectedField = 'none';
          } else {
            this.locationError = Constant.SELECT_COUNTY_ERROR_MSG;
          }
        }
      } else {
        if (
          this.locationList.some(
            location =>
              location.state === state &&
              location.countyName === 'All Counties' &&
              location.zipCode == null &&
              (zipCode == null || zipCode.toString() == '')
          )
        ) {
          this.locationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.locationList.some(
            location =>
              location.state === state &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              location.zipCode == null &&
              (zipCode == null || zipCode.toString() == '') &&
              (location.zipCode == null || location.zipCode.toString() == '')
          )
        ) {
          this.locationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.locationList.some(
            location =>
              location.state === state &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              (zipCode == null || zipCode.toString() == '') &&
              (location.zipCode == null || location.zipCode.toString() == '')
          )
        ) {
          this.locationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.locationList.some(
            location =>
              location.state.toLowerCase() === state.toLowerCase() &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              location.zipCode === zipCode
          )
        ) {
          this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
        } else {
          if (this.checkSelectedField === 'state') {
            if (zipCode) {
              if (
                !this.locationList.some(
                  location =>
                    location.state.toLowerCase().trim() === state.toLowerCase().trim() &&
                    countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
                    location.zipCode === zipCode
                )
              ) {
                if (this.zipData.some((zipList: any) => zipList.zipCode == zipCode)) {
                  this.insertLocationOnAddLocation(state, countiestr, zipCode.toString(), pageMode.edit);
                } else {
                  this.locationError = Constant.ZIPCODE_NOT_EXIST_ERROR;
                }
              } else {
                this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
              }
            } else {
              this.insertLocationOnAddLocation(state, countiestr, zipCode.toString(), pageMode.edit);
            }
          } else {
            this.insertLocationOnAddLocation(state, countiestr, zipCode.toString(), pageMode.edit);
          }
        }
      }
    }
    this.clearLocationControlValue();
  }
  /* open prepare list of location */
  insertLocationOnAddLocation(state: string, county: string, zipCode: string, formType: pageMode) {
    let customObj;
    let countySel: string[];

    if (formType === pageMode.edit) {
      if (
        this.locationList.some(
          location => location.state === state && location.countyName === county && location.zipCode == null
        )
      ) {
        this.locationError = Constant.ZIPCODE_EXIST_ERROR_MSG;
        return;
      } else if (
        this.locationList.some(
          location => location.state === state && location.countyName === 'All Counties' && location.zipCode == null
        )
      ) {
        this.locationError = Constant.ZIPCODE_EXIST_ERROR_MSG;
        return;
      }
      if (this.zipData.length > 0 && zipCode !== '') {
        countySel = this.zipData.filter(a => a.zipCode == +zipCode).map(i => i.countyName);
      } else if (this.zipData.length > 0 && zipCode === '') {
        // select state or county but no select zipcode
        countySel = county.split(',');
      } else {
        // case enter zipcode by typing
        countySel = county.split(',');
      }
      if (countySel.length == this.countyData.length && zipCode === '') {
        let countyLocationArr: Location[] = [];
        countySel.forEach(county => {
          customObj = new Location();
          customObj.state = state;
          customObj.countyName = county;
          customObj.zipCode = zipCode === '' ? null : +zipCode;
          countyLocationArr.push(customObj);
        });
        this.insertLocationList(state, county, zipCode, countyLocationArr, pageMode.edit);
      } else {
        countySel.forEach(county => {
          customObj = new Location();
          customObj.state = state;
          customObj.countyName = county;
          customObj.zipCode = zipCode === '' ? null : +zipCode;
          this.locationList.push(customObj);
          this.checkSelectedField = 'none';
        });
      }
    } else {
      if (
        this.copyOrderLocationList.some(
          location => location.state === state && location.countyName === county && location.zipCode == null
        )
      ) {
        this.copyLocationError = Constant.ZIPCODE_EXIST_ERROR_MSG;
        return;
      } else if (
        this.copyOrderLocationList.some(
          location => location.state === state && location.countyName === 'All Counties' && location.zipCode == null
        )
      ) {
        this.copyLocationError = Constant.ZIPCODE_EXIST_ERROR_MSG;
        return;
      }
      if (this.copyZipData.length > 0 && zipCode !== '') {
        countySel = this.copyZipData.filter(a => a.zipCode == +zipCode).map(i => i.countyName);
      } else if (this.copyZipData.length > 0 && zipCode === '') {
        // select state or county but no select zipcode
        countySel = county.split(',');
      } else {
        // case enter zipcode by typing
        countySel = county.split(',');
      }
      if (countySel.length == this.countyCopyData.length && zipCode === '') {
        let copyCountyLocationArr: Location[] = [];
        countySel.forEach(county => {
          customObj = new Location();
          customObj.state = state;
          customObj.countyName = county;
          customObj.zipCode = zipCode === '' ? null : +zipCode;
          copyCountyLocationArr.push(customObj);
        });
        this.insertLocationList(state, county, zipCode, copyCountyLocationArr, pageMode.copy);
      } else {
        countySel.forEach(county => {
          customObj = new Location();
          customObj.state = state;
          customObj.countyName = county;
          customObj.zipCode = zipCode === '' ? null : +zipCode;
          this.copyOrderLocationList.push(customObj);
          this.copyLocationStartingPoint = 'none';
        });
      }
    }
  }
  /* end prepare list of location */
  insertLocationStateCountyZipCopyList(state: string, county: any, zipCode: any) {
    if (zipCode == '' && county == '' && state != undefined) {
      if (state != '' && !this.locationStateCountyZipCopyList.some(item => item == state)) {
        this.locationStateCountyZipCopyList.push(state);
      }
    }
  }

  insertDeleteLocationCopyList(str: any) {
    let indexState = this.locationStateCountyZipCopyList.indexOf(str);
    if (indexState >= 0)
      this.locationStateCountyZipCopyList.splice(this.locationStateCountyZipCopyList.indexOf(str), 1);
  }
  /* Open - Add location list in copy order */
  addCopyLocation(state: string, county: string[], zipCode: number) {
    this.insertLocationStateCountyZipCopyList(state, county, zipCode);
    this.setCopyBtnDisable();
    this.copyLocationErrorNull = '';
    this.showAllZipCode = false;
    let countiestr = county.join(',');
    if (state == null || state === '') {
      this.copyLocationError = Constant.STATE_COUNTY_REQ_ERROR_MSG;
      if (this.isCopyOrderForm == true) {
        this.copyOrderForm.get('State').setErrors({ required: true });
      } else {
        this.editOrderForm.get('State').setErrors({ required: true });
      }
    } else {
      //force user to select order type to get correct location value
      let orderType = this.copyOrderForm.get('OrderType').value;
      if (orderType == '') {
        this.copyLocationError = Constant.locationErrorForOrderType;
        return;
      }

      if (county == null || county.length == 0) {
        if (this.copyOrderLocationList.some(location => location.state === state)) {
          this.filteredCopyCounties = this.countyCopyData.filter(
            (counties: any) => !this.copyOrderLocationList.some(location => location.countyName === counties)
          );
          if (Array.isArray(this.filteredCopyCounties) && this.filteredCopyCounties.length) {
            if (zipCode) {
              this.copyLocationError = Constant.SELECT_COUNTY_ERROR_MSG;
            } else {
              this.insertLocationList(state, countiestr, zipCode.toString(), this.filteredCopyCounties, pageMode.copy);
              this.copyLocationStartingPoint = 'none';
            }
          } else {
            this.copyLocationError = Constant.STATE_ALREADY_EXIST;
          }
        } else {
          if (this.copyLocationStartingPoint === 'state') {
            this.insertLocationList(state, countiestr, zipCode.toString(), this.countyCopyData, pageMode.copy);
            this.copyLocationStartingPoint = 'none';
          } else {
            this.copyLocationError = Constant.SELECT_COUNTY_ERROR_MSG;
          }
        }
      } else {
        if (
          this.copyOrderLocationList.some(
            location =>
              location.state === state &&
              location.countyName === 'All Counties' &&
              location.zipCode == null &&
              (zipCode == null || zipCode.toString() == '')
          )
        ) {
          this.copyLocationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.copyOrderLocationList.some(
            location =>
              location.state === state &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              location.zipCode == null &&
              (zipCode == null || zipCode.toString() == '')
          )
        ) {
          this.copyLocationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.copyOrderLocationList.some(
            location =>
              location.state === state &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              (zipCode == null || zipCode.toString() == '') &&
              (location.zipCode == null || location.zipCode.toString() == '')
          )
        ) {
          this.copyLocationError = Constant.LOCATION_EXIST_ERROR_MSG;
        } else if (
          this.copyOrderLocationList.some(
            location =>
              location.state.toLowerCase() === state.toLowerCase() &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              location.zipCode === zipCode
          )
        ) {
          this.copyLocationError = Constant.LOCATION_EXIST_ERROR_MSG;
        } else {
          if (this.copyLocationStartingPoint === 'state') {
            if (zipCode) {
              if (
                !this.copyOrderLocationList.some(
                  location =>
                    location.state.toLowerCase().trim() === state.toLowerCase().trim() &&
                    countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
                    location.zipCode === zipCode
                )
              ) {
                if (this.copyZipData.some((zipList: any) => zipList.zipCode == zipCode)) {
                  this.insertLocationOnAddLocation(state, countiestr, zipCode.toString(), pageMode.copy);
                } else {
                  this.copyLocationError = Constant.ZIPCODE_NOT_EXIST_ERROR;
                }
              } else {
                this.copyLocationError = Constant.LOCATION_EXIST_ERROR_MSG;
              }
            } else {
              this.insertLocationOnAddLocation(state, countiestr, zipCode.toString(), pageMode.copy);
            }
          } else {
            this.insertLocationOnAddLocation(state, countiestr, zipCode.toString(), pageMode.copy);
          }
        }
      }
    }
    this.clearCopyLocationControlValue();
  }
  /* End - Add location list in copy order */
  allZipInCountySelectedRemoveOrphans() {}
  /* open add lead sources at on change event */
  onLeadSourceChange(leadSourceId: any, onChange: any) {
    this.selectedObject = this.leadSources.filter((t: any) => t.IntegrationId == leadSourceId);
    this.isDone = this.filterList.some((t: any) => t.IntegrationId == leadSourceId);
    if (!this.isDone) {
      if (onChange == 'onChange') {
        this.setCopyBtnDisable();
      }
      this.integrationList.push(this.selectedObject[0].IntegrationId);
      this.filterList.push({
        IntegrationId: this.selectedObject[0].IntegrationId,
        LeadSourceName: this.selectedObject[0].LeadSourceName,
        TransactionCost: this.selectedObject[0].TransactionCost,
        TransactionPrice: this.selectedObject[0].TransactionPrice
      });
    }
    this.selectedObject = [];
    this.updateLeadSourcesControl(this.integrationList.length, pageMode.edit);
  }
  /* open add lead sources in copy order at on change event */
  copyOrderLeadSourceList(leadSourceId: any, onChange: any) {
    this.selectedObject = this.copyLeadSources.filter((t: any) => t.IntegrationId == leadSourceId);
    this.leadSourceExist = this.copyLeadSourceList.some((t: any) => t.IntegrationId == leadSourceId);

    if (!this.leadSourceExist) {
      this.copyIntegrationList.push(this.selectedObject[0].IntegrationId);
      this.copyLeadSourceList.push({
        IntegrationId: this.selectedObject[0].IntegrationId,
        LeadSourceName: this.selectedObject[0].LeadSourceName,
        TransactionCost: this.selectedObject[0].TransactionCost,
        TransactionPrice: this.selectedObject[0].TransactionPrice
      });
    }
    this.selectedObject = [];
    this.updateLeadSourcesControl(this.copyLeadSourceList.length, pageMode.copy);
  }
  /* open restrict enter event */
  onKeydown(event: any) {
    return false;
  }
  /* open delete lead sources */
  deleteLeadSources(leadSourceIndex: any, formType: pageMode) {
    var leadSourcesControl = null;
    if (formType === pageMode.edit) {
      leadSourcesControl = this.editOrderForm.get('Leadsources');
      this.setCopyBtnDisable();
      this.filterList.splice(leadSourceIndex, 1);
      this.integrationList.splice(leadSourceIndex, 1);
      if (this.integrationList.length == 0) {
        this.updateLeadSourcesControl(this.integrationList.length, pageMode.edit);
      }
    } else {
      leadSourcesControl = this.copyOrderForm.get('Leadsources');
      this.copyLeadSourceList.splice(leadSourceIndex, 1);
      this.copyIntegrationList.splice(leadSourceIndex, 1);
      if (this.copyIntegrationList.length == 0) {
        this.updateLeadSourcesControl(this.copyIntegrationList.length, pageMode.copy);
      }
    }
    leadSourcesControl.setValue('');
    leadSourcesControl.updateValueAndValidity();
  }
  /* open update lead sources control */
  updateLeadSourcesControl(length: any, formType: pageMode) {
    var LeadSourcesControl = null;
    if (formType == pageMode.edit) {
      LeadSourcesControl = this.editOrderForm.get('Leadsources');
    } else {
      LeadSourcesControl = this.copyOrderForm.get('Leadsources');
    }
    if (length > 0) {
      LeadSourcesControl.setValidators(null);
    } else {
      LeadSourcesControl.setValidators([Validators.required]);
      LeadSourcesControl.setValue('');
    }
    LeadSourcesControl.updateValueAndValidity();
  }
  /* open method for copy order */
  copyOrderShow(content: any, flag: string) {
    this.isCopyOrderForm = true;
    /*handle tab focus between copy and edit order page*/
    this.setCopyOrderTabIndex();
    this.HideNotification = true;
    /*handle tab focus between copy and edit order page*/
    document.getElementById('copy-order-form').style.height = 'auto';
    document.getElementById('edit-order-form').style.display = 'none';
    document.getElementById('copy-order-form-submit').style.display = 'block';
    document.getElementById('copy-order-form-submit').style.removeProperty('display');
    document.getElementById('edit-order-form-submit').style.display = 'none';
    this.setCopyBtnDisable();
    this.editOrderClickFlag = true;
    this.copyOrderClickFlag = true;
    this.showCopyTab = true;
    this.showEditTab = false;

    let CopyOrderType = this.getControlValueByControlName('OrderType');

    if (CopyOrderType == 'Calls' && CopyOrderType != '') {
      this.resizeTableOnCopyCallTab = true;
    } else if (CopyOrderType == '') {
      this.HideNotification = true;
      if (this.orderModel.SourceType == 'Calls') {
        this.resizeTableOnCopyCallTab = true;
        this.resizeTableOnEditCallTab = true;
      } else {
        this.resizeTableOnCopyCallTab = false;
        this.resizeTableOnEditCallTab = false;
      }
      let CopyOrderTypeControl = this.copyOrderForm.get('OrderType');
      CopyOrderTypeControl.setValue('Leads');
      this.changeOrderType(CopyOrderTypeControl.value);
    } else {
      this.HideNotification = true;
      this.resizeTableOnCopyCallTab = false;
    }
  }

  getControlValueByControlName(controlName: string) {
    let OrderControl = this.copyOrderForm.get(controlName);
    console.log(OrderControl);
    console.log(OrderControl.value);
    return OrderControl.value;
  }

  editOrderUrl() {
    /*handle tab focus between copy and edit order page*/
    this.setEditOrderTabIndex();
    /*handle tab focus between copy and edit order page*/

    if (this.orderModel.SourceType == 'Leads') {
      this.HideNotification = true;
    }
    document.getElementById('copy-order-form').style.height = '0px';
    document.getElementById('edit-order-form').style.display = 'block';
    document.getElementById('copy-order-form-submit').style.display = 'none';
    document.getElementById('edit-order-form-submit').style.display = 'block';
    document.getElementById('edit-order-form-submit').style.removeProperty('display');
    this.showCopyTab = false;
    this.showEditTab = true;
    this.isCopyOrderForm = false;
    this.SendEmailNotification = this.orderModel.SendEmailNotification;
    this.SendMobileNotification = false;
    //this.SendMobileNotification = this.orderModel.SendSMSNotification;
  }

  copyOrderUrl(content: any, flag: string) {
    /*handle tab focus between copy and edit order page*/
    this.setCopyOrderTabIndex();
    /*handle tab focus between copy and edit order page*/
    this.open(content, flag);
    var copyOrderId = this._service.getCopyOrderById() ? this._service.getCopyOrderById() : this.orderId;
    if (this.copyUrl) {
      this._router.navigate(['/order-copy' + '/' + copyOrderId]);
    }
  }
  /* open location view all functionality */
  viewZipCodes(selectedData: any, i: number, event: any) {
    this.allZipCodes = [];
    this._orderService.getZipData(selectedData.countyName, selectedData.state).subscribe(
      res => {
        this.allZipCodes = JSON.parse(res.toString());
      },
      err => {
        this._logger.error(Constant.zipCodeCountyLogMsg);
      }
    );

    this.Ziplist = <HTMLInputElement>event.target.id.toString();
    let currentViewElement = document.getElementsByClassName(this.Ziplist.toString()) as HTMLCollectionOf<HTMLElement>;

    if (currentViewElement[0].style.display == 'none') {
      currentViewElement[0].style.display = 'block';
    } else currentViewElement[0].style.display = 'none';
  }
  disableStatusButton() {
    var statusInput = <HTMLInputElement>document.getElementById('EditStatus');
    var editStatusSlider = <HTMLInputElement>document.getElementById('EditStatusSlider');
    if (this.disabledSatusFlag) {
      statusInput.disabled = true;
      editStatusSlider.tabIndex = -1;
      editStatusSlider.style.opacity = '0.5';
      editStatusSlider.style.cursor = 'default';
    } else {
      statusInput.disabled = false;
      editStatusSlider.tabIndex = 0;
      editStatusSlider.style.opacity = '1';
      editStatusSlider.style.cursor = 'pointer';
    }
  }

  ngOnInit() {
    console.log('order edit ngOnInit:');
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      this.autoCompleteValue = 'nope';
    }
    this.setCountyEvent();

    this.today = this.currentDate;
    this.isIE = this._orderService.isIEBrowser();
    this.accessToken = this._orderService.getAccessTokenFromStorage();
    console.log(this.accessToken);
    if (this.accessToken != null) {
      this._orderService.authentication().subscribe(
        res => {},
        err => {
          if (!err.error.text) {
            this._orderService.returnToErrorPage(err.error);
            this._logger.error(err.error);
          }
        }
      );
    } else {
      this._orderService.returnToErrorPage(Constant.TOKEN_MISSING_ERROR_MSG);
      this._logger.error(Constant.TOKEN_MISSING_ERROR_MSG);
    }

    this.isAllstateMA = localStorage.getItem('isAllstateMA') == 'true';
    console.log(this.isAllstateMA);

    /* Start- For Detecting browser on load */
    this.browserName = this.getBrowserName();
    if (this.browserName === 'ie') {
      this.isIeBrowser = true;
      this.isOtherBrowser = false;
    } else {
      this.isIeBrowser = false;
      this.isOtherBrowser = true;
    }
    /* End- For Detecting browser on load */

    this.copyOrdername = this._service.getCopyOrderName();
    window.scroll(0, 0);

    this._service.currentMessage.subscribe(message => {
      if (message == 'none') {
        this.editOrderClickFlag = false;
      } else {
        this.copyOrderButton$.nativeElement.disabled = true;
        //(<HTMLInputElement>document.getElementById('copy-order-button')).disabled = true;
        this.editOrderClickFlag = true;
      }
    });
    this.getPhoneDropDown();
    this.getAccountBalance();

    this.showAllZipCode = false;
    //this.accessToken = this._orderService.getAccessTokenFromStorage();
    console.log(this.accessToken);
    console.log(this.orderId);
    if (this.accessToken) {
      this._orderService.getOrderById(this.accessToken, this.orderId).subscribe(
        data => {
          this.orderData = JSON.parse(data)[0];
          this._logger.debug('orderData is ' + JSON.stringify(this.orderData));
          this._service.setEditOrderId(this.orderData.OrderId);
          this._service.setEditOrderName(this.orderData.OrderName);

          if (this.orderData.Status == 'Active') {
            this.orderStatus = true;
          } else {
            this.orderStatus = false;
          }
          var dynamicLocationList = this.orderData.OrderLocationList;
          var dynamicStateList = this.orderData.stateList;
          this.setDemographicValue(this.editOrderForm, pageMode.edit);
          this.getproducttype();
          this.getleadsources();

          for (var locVal of dynamicLocationList) {
            let dynamicCustomObj = new Location();
            dynamicCustomObj.state = locVal.state;
            dynamicCustomObj.countyName = locVal.countyName;
            dynamicCustomObj.zipCode = locVal.zipCode;
            this.locationList.push(dynamicCustomObj);
            this.copyOrderLocationList.push(dynamicCustomObj);
          }
          for (var stateVal of dynamicStateList) {
            this.locationStateCountyZipList.push(stateVal);
            this.locationStateCountyZipCopyList.push(stateVal);
          }
          this.disabledSatusFlag = this.orderData.disableStatusFlag;
          /* open add timeslot */
          this.setOrderTimeSlots(pageMode.edit);

          /* end add timeslot */

          this.orderModel = new order(
            this.orderData.OrderId,
            this.orderData.OrderName,
            this.orderData.LeadSources,
            this.orderData.SourceType,
            this.orderData.CreatedDate,
            this.orderData.LeadType,
            this.orderData.AverageLeads,
            this.orderData.AvgDailyCost,
            this.orderData.Spent,
            this.orderData.StartDate,
            this.orderData.EndDate,
            this.orderData.DailyCap,
            this.orderData.HourlyCap,
            this.orderData.Status,
            this.orderData.Error,
            this.orderData.OffSet,
            this.orderData.MaximumConcurrency,
            this.orderData.TransferNumber,
            this.orderData.AgeFrom,
            this.orderData.AgeTo,
            this.orderData.SendEmailNotification,
            this.orderData.SendSMSNotification
          );

          this.copyOrderData(this.orderModel);
          if (this.orderModel.SourceType == 'Calls') {
            this.hideCountyZipCodeOfEditForm = true;
            this.hideCountyZipCodeOfCopyForm = true;
            this.resizeTableOnEditCallTab = true;
            this.phoneNumberlengthError = '';
            this.HideFields = false;
            this.copyHideFields = false;
            const MaxCallCurrencyControl = this.editOrderForm.get('MaxCallCurrency');
            const PhoneNumberControl = this.editOrderForm.get('PhoneNumber');
            MaxCallCurrencyControl.setValidators([Validators.required]);
            MaxCallCurrencyControl.setValue(this.orderModel.MaxCallCurrency);
            MaxCallCurrencyControl.updateValueAndValidity();
            PhoneNumberControl.setValidators([Validators.required, Validators.minLength(10)]);
            PhoneNumberControl.setValue(this.orderModel.TransferNumber);
            PhoneNumberControl.updateValueAndValidity();
            this.isCallLead = true;
          } else {
            this.hideCountyZipCodeOfEditForm = false;
            this.hideCountyZipCodeOfCopyForm = false;
            this.resizeTableOnEditCallTab = false;
            this.HideFields = true;
            this.copyHideFields = true;
            const MaxCallCurrencyControl = this.editOrderForm.get('MaxCallCurrency');
            const PhoneNumberControl = this.editOrderForm.get('PhoneNumber');
            MaxCallCurrencyControl.setValidators(null);
            MaxCallCurrencyControl.setValue('');
            MaxCallCurrencyControl.updateValueAndValidity();
            PhoneNumberControl.setValidators(null);
            PhoneNumberControl.setValue('');
            PhoneNumberControl.updateValueAndValidity();
            this.getBrokerInformation();
            this.isCallLead = false;
          }

          this.HideDemographics = !this.HideFields;
        },
        (err: HttpErrorResponse) => {
          let navigationExtras: NavigationExtras = {
            queryParams: { error: Constant.ORDERIDNOTVALID }
          };
          this._router.navigate(['/error-page'], navigationExtras);
          return false;
        }
      );
    } else {
      let navigationExtras: NavigationExtras = {
        queryParams: { error: Constant.TOKEN_MISSING_ERROR_MSG }
      };
      this._router.navigate(['/error-page'], navigationExtras);
      return false;
    }

    /* end get single order data */

    // this.getStateData();

    this.orderTypeError = Constant.ORDERTYPEERROR;
    this.productTypeError = Constant.PRODUCTTYPEERROR;
    this.orderNameError = Constant.ORDERNAMEERROR;
    this.startDateError = Constant.STARTDATEERROR;
    this.endDateError = Constant.ENDDATEERROR;
    this.timezoneError = Constant.TIMEZONEERROR;
    this.hourlycapError = Constant.HOURLYCAPERROR;
    this.copyHourlycapError = Constant.HOURLYCAPERROR;
    this.dailycapError = Constant.DAILYCAPERROR;
    this.leadSourceError = Constant.LEADSOURCEERROR;
    this.accessToken = this._orderService.getAccessTokenFromStorage();
    this.maxCallCurrencyError = Constant.MAX_CALL_CURRENCY_ERROR_MSG;
    this.copyMaxCallCurrencyError = Constant.MAX_CALL_CURRENCY_ERROR_MSG;
    this.phoneNumberError = Constant.PHONE_NUMBER_ERROR_MSG;
    this.startTimeError = Constant.START_ERROR_MESSAGE;
    this.endTimeError = Constant.END_ERROR_MESSAGE;
    if (!this.accessToken) {
      let navigationExtras: NavigationExtras = {
        queryParams: { error: Constant.TOKEN_MISSING_ERROR_MSG }
      };
      this._router.navigate(['/error-page'], navigationExtras);
      return false;
    }
  }

  getStateData() {
    this._orderService.getLocationData(this.isCallLead).subscribe(
      res => {
        this.locationData = JSON.parse(res.toString());
        this.locationCopyData = JSON.parse(res.toString());
        if (this.locationData.length > 0) {
          //this.setStateControlValue();
        }
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.stateLogMsg);
      }
    );
  }

  itemsPerPage(event: Event) {
    this.selectednumber = +(<HTMLInputElement>event.target).value;
  }

  setStateControlValue() {
    const StateControl = this.editOrderForm.get('State');
    const copyStateControl = this.copyOrderForm.get('State');
    StateControl.setValidators(null);
    StateControl.updateValueAndValidity();
    copyStateControl.setValidators(null);
    copyStateControl.updateValueAndValidity();
  }
  /* open set demograhics value */
  setDemographicValue(orderFormType: FormGroup, orderFlag: pageMode) {
    if (this.orderData.AgeFrom) {
      const AgeRangeControl = orderFormType.get('AgeRange');
      AgeRangeControl.setValidators([Validators.required]);
      AgeRangeControl.setValue(this.orderData.AgeFrom);
      AgeRangeControl.updateValueAndValidity();
      if (orderFlag === pageMode.edit) {
        this.ageTo = this.orderData.AgeTo;
      }

      this.copyAgeTo = this.orderData.AgeTo;
    } else {
      if (orderFlag === pageMode.copy) {
        const AgeRangeControl = orderFormType.get('AgeRange');
        AgeRangeControl.setValidators([Validators.required]);
        AgeRangeControl.setValue('0');
      }
    }
  }
  /* open delete timeslot */
  deleteTimeSlot(i: number, formType: pageMode) {
    this.endTimeFlagErr = '';
    this.startTimeFlagErr = '';
    var data = null;
    if (formType === pageMode.edit) {
      data = this.editOrderForm.get('timeSlots') as FormArray;
      if (data.length > 1) {
        this.setCopyBtnDisable();
        data = this.editOrderForm.get('timeSlots') as FormArray;
        data.removeAt(i);
        this.checkBoxValidation(formType, 'none');
      } else {
        this.openValidationDialog(Constant.RULE_ERROR_MESSAGE);
      }
    } else {
      data = this.copyOrderForm.get('timeSlots') as FormArray;
      if (data.length > 1) {
        data = this.copyOrderForm.get('timeSlots') as FormArray;
        data.removeAt(i);
        this.checkBoxValidation(formType, 'none');
      } else {
        this.openValidationDialog(Constant.RULE_ERROR_MESSAGE);
      }
    }
  }

  onChanges(): any {
    this.editOrderForm.valueChanges.subscribe(val => {
      if (val.OrderName != this.orderData.OrderName) {
        (<HTMLInputElement>document.getElementById('copy-order-button')).disabled = true;
      } else {
        (<HTMLInputElement>document.getElementById('copy-order-button')).disabled = false;
      }
    });
  }
  /* open disable copy btn */
  setCopyBtnDisable() {
    (<HTMLInputElement>document.getElementById('copy-order-button')).disabled = true;
    this.copyDisabled = true;
  }
  /**clear time messages*/
  clearTimeMessages(event: any, formType: pageMode) {
    if (formType == pageMode.edit) {
      var getID = event.target.id;
      var timeName = getID.split('Time');
      if (timeName[0] == 'start') {
        document.getElementById('startTimeErr' + timeName[1]).innerHTML = ' ';
      } else if (timeName[0] == 'end') {
        document.getElementById('endTimeErr' + timeName[1]).innerHTML = ' ';
      }
    } else {
      var copyGetID = event.target.id;
      var copyTimeName = copyGetID.split('Time');
      if (copyTimeName[0] == 'copyStart') {
        document.getElementById('copyStartTimeErr' + copyTimeName[1]).innerHTML = ' ';
      } else if (copyTimeName[0] == 'copyEnd') {
        document.getElementById('copyEndTimeErr' + copyTimeName[1]).innerHTML = ' ';
      }
    }
  }
  /* Compare time validation Monika*/
  compareTimevalidator(formType: pageMode, event: any) {
    this.setCopyBtnDisable();
    let errorFlag = false;
    var data = null;
    var dataLength = null;
    if (formType === pageMode.edit) {
      this.clearTimeflag(pageMode.edit);
      this.compareTimeErrorFlag = true;
      data = this.editOrderForm.get('timeSlots') as FormArray;
      dataLength = data.length;
    } else {
      this.clearTimeflag(pageMode.copy);
      this.copyCompareTimeErrorFlag = true;
      data = this.copyOrderForm.get('timeSlots') as FormArray;
      dataLength = data.length;
    }
    var compareTimeCount = 0;
    for (compareTimeCount = 0; compareTimeCount <= dataLength - 1; compareTimeCount++) {
      var group = data.at(compareTimeCount) as FormGroup;
      var endTime = group.get('End').value;
      if (endTime == '') {
        group.get('End').setErrors({ required: true });
      }
      this.endTimeVal = endTime;
      var startTime = group.get('Start').value;
      if (startTime == '') {
        group.get('Start').setErrors({ required: true });
      }
      var sampleDate = '04/12/2019 ';
      var endTimeValue = new Date(sampleDate + endTime);
      var startTimeValue = new Date(sampleDate + startTime);
      var compareTimeErrorDiv = null;
      if (formType === pageMode.edit) {
        compareTimeErrorDiv = document.getElementById('errorEndTime' + compareTimeCount);
      } else {
        compareTimeErrorDiv = document.getElementById('copyErrorEndTime' + compareTimeCount);
      }

      if (endTime != '' && startTimeValue >= endTimeValue) {
        compareTimeErrorDiv.innerHTML = Constant.TIME_COMPARE_ERROR_MESSAGE;
        if (formType == pageMode.edit) {
          this.compareTimeErrorFlag = true;
        } else {
          this.copyCompareTimeErrorFlag = true;
        }
        if (event == 'submit') {
          formType === pageMode.copy
            ? document.getElementById('copyEndTime' + compareTimeCount).focus()
            : document.getElementById('endTime' + compareTimeCount).focus();
          return false;
        }
      } else {
        compareTimeErrorDiv.innerHTML = '';
        this.copyCompareTimeErrorFlag = false;
        this.compareTimeErrorFlag = false;
      }
    }
  }
  /* End time validation Monika*/
  timevalidator(formType: pageMode) {
    let endTimeFields;
    if (formType === pageMode.edit) {
      endTimeFields = document.getElementsByClassName('deliveryEndTimeClass') as HTMLCollectionOf<HTMLInputElement>;
    } else {
      endTimeFields = document.getElementsByClassName('deliveryEndTimeClassCopy') as HTMLCollectionOf<HTMLInputElement>;
    }
    let errorFlag = false;
    let endTimeIndex = 0;
    for (let endTimeFieldCount = 0; endTimeFieldCount < endTimeFields.length; endTimeFieldCount++) {
      var endTimeText = '';
      if (formType === pageMode.edit) {
        endTimeText = this.getTimeFromTimeSlot(this.editOrderForm.get('timeSlots'), endTimeIndex, 'end');
      } else {
        endTimeText = this.getTimeFromTimeSlot(this.copyOrderForm.get('timeSlots'), endTimeIndex, 'end');
      }

      let errorDiv = endTimeFields[endTimeFieldCount].parentElement.nextElementSibling;
      if (endTimeFieldCount % 2 != 0) {
        endTimeIndex++;
      }
      if (endTimeText.trim() == '') {
        errorDiv.textContent = Constant.END_ERROR_MESSAGE;
        if (formType === pageMode.edit) {
          document.getElementById('endTime' + (endTimeIndex - 1)).focus();
        } else {
          document.getElementById('copyEndTime' + (endTimeFieldCount - endTimeIndex)).focus();
        }

        errorFlag = true;
      } else {
        errorDiv.textContent = '';
      }
    }
    return errorFlag;
  }
  /* open popu-up start */
  open(content: any, flag: any) {
    if (flag === 'after') {
      this._modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'custom-class' })
        .result.then(
          result => {
            this.saveFlag = true;
            this.editOrderClickFlag = true;
            this.showCopyTab = false;
            this.showEditTab = true;

            (<HTMLInputElement>document.getElementById('copy-order-button')).disabled = true;
            //this.editOrder();
          },
          reason => {
            if (flag != 'after') {
              this.copyUrl = false;
              this.editOrderClickFlag = false;
              this.showCopyTab = true;
              this.showEditTab = false;

              (<HTMLInputElement>document.getElementById('copy-order-button')).disabled = false;
            } else {
              this.showCopyTab = false;
              this.showEditTab = true;
            }
          }
        );
    } else {
      this.copyUrl = true;
      this.editOrderClickFlag = true;
      this.showCopyTab = false;
      this.showEditTab = true;

      (<HTMLInputElement>document.getElementById('copy-order-button')).disabled = true;
    }
  }
  /* copy order */
  setOrderTimeSlots(formType: pageMode) {
    for (var slots of this.orderData.Timeslots) {
      this.sunday = false;
      this.monday = false;
      this.tuesday = false;
      this.wednesday = false;
      this.thursday = false;
      this.friday = false;
      this.saturday = false;
      this.applyAll = false;
      if (slots.AllWeek) {
        this.sunday = true;
        this.monday = true;
        this.tuesday = true;
        this.wednesday = true;
        this.thursday = true;
        this.friday = true;
        this.saturday = true;
        this.applyAll = true;

        var formArray = null;
        if (formType === pageMode.edit) {
          formArray = this.editOrderForm.get('timeSlots') as FormArray;
        } else {
          formArray = this.copyOrderForm.get('timeSlots') as FormArray;
        }

        formArray.push(
          this._fb.group({
            Start: [slots.Start, Validators.required],
            StartPicker: [slots.Start],
            End: [slots.End],
            EndPicker: [slots.End],
            AllWeek: [this.applyAll],
            sunday: [this.sunday],
            monday: [this.monday],
            tuesday: [this.tuesday],
            wednesday: [this.wednesday],
            thursday: [this.thursday],
            friday: [this.friday],
            saturday: [this.saturday]
          })
        );
      } else {
        for (var days of slots.DayOfWeek) {
          this.applyAll = false;
          if (days == 'Sunday') {
            this.sunday = true;
          }
          if (days == 'Monday') {
            this.monday = true;
          }
          if (days == 'Tuesday') {
            this.tuesday = true;
          }
          if (days == 'Wednesday') {
            this.wednesday = true;
          }
          if (days == 'Thursday') {
            this.thursday = true;
          }
          if (days == 'Friday') {
            this.friday = true;
          }
          if (days == 'Saturday') {
            this.saturday = true;
          }
        }
        if (formType === pageMode.edit) {
          formArray = this.editOrderForm.get('timeSlots') as FormArray;
        } else {
          formArray = this.copyOrderForm.get('timeSlots') as FormArray;
        }

        formArray.push(
          this._fb.group({
            Start: [slots.Start, Validators.required],
            StartPicker: [slots.Start],
            End: [slots.End],
            EndPicker: [slots.End],
            AllWeek: [this.applyAll],
            sunday: [this.sunday],
            monday: [this.monday],
            tuesday: [this.tuesday],
            wednesday: [this.wednesday],
            thursday: [this.thursday],
            friday: [this.friday],
            saturday: [this.saturday]
          })
        );
      }
    }
  }

  copyOrderData(editOrderData: order) {
    this.copyOrderModel = Object.assign({}, editOrderData);
    if (!this.isCopy) {
      this.copyOrderModel.OrderName = '';
      this.copyOrderModel.OrderId = '';
      this.copyOrderModel.SourceType = '';
      this.copyOrderModel.LeadType = '';
      this.copyOrderModel.LeadSources = '';
      this.copyOrderModel.StartDate = this.today;
      this.copyOrderModel.EndDate = null;
      this.leadSources = [];
      this.filterList = [];
      this.orderFlag = true;
    }
    this.setOrderTimeSlots(pageMode.copy);
    return this.copyOrderModel;
  }

  changeSuccessMsgStatus(formType: pageMode) {
    if (formType === pageMode.edit) {
      this.editSuccessMsg = '';
      this.showEditMsg = false;
    } else {
      this.copySuccessMsg = '';
      this.showCopyMsg = false;
    }
  }

  removeSuccessMessage(formType: pageMode) {
    if (formType === pageMode.edit) {
      setTimeout(() => this.changeSuccessMsgStatus(formType), 5000);
    } else {
      const source = timer(5000);
      source.subscribe(val => {
        this.copySuccessMsg = '';
        this.showCopyMsg = false;
        this.deliveryDataMessage = '';
        this.showDeliveryWarning = false;
      });
    }
  }

  copyOrderSubmit() {
    /* open get success message */

    this.removeSuccessMessage(pageMode.copy);
    /* end get success message */
    this.convertTime(pageMode.copy);
    this.validateTimePickers();
    var data = this.copyOrderForm.get('timeSlots') as FormArray;
    this.checkBoxValidation(pageMode.copy, 'none');
    this.copySubmitted = true;
    if (this.copyOrderForm.get('OrderType').value == 'Calls') {
      if (this.copyMaxCallSubmitError) {
        document.getElementById('copyMaxCallCurrency').focus();
        return;
      }
    }

    if (this.copySubmitError) {
      if (this.copyOrderForm.get('HourlyCallCap').value < 0 || this.copyOrderForm.get('HourlyCallCap').value > 100) {
        document.getElementById('copyHourlyCallCap').focus();
      } else {
        document.getElementById('copyDailyCallCap').focus();
      }
    }

    // let endTimeErrorFlag = this.timevalidator(pageMode.copy);
    this.copyLocationErrorflag = false;
    if (this.copyOrderLocationList.length == 0) {
      this.copyLocationErrorflag = true;
      this.copyLocationErrorNull = Constant.ERROR_ON_LOCATION;
      if (this.copyOrderForm.valid) {
        document.getElementById('copyState').focus();
      }
      if (this.copyOrderForm.get('State').value === 'undefined' || this.copyOrderForm.get('State').value === '') {
        this.copyOrderForm.get('State').setErrors({ required: true });
      }
    }
    if (this.copySubmitErrorFlag) {
      document.getElementById('copyOrderName').focus();
    }
    if (this.copyErrorFlagStartDate) {
      this.copyStartDate$.nativeElement.focus();
      //(<HTMLInputElement>document.getElementById('copyStartDate')).focus();
      this._logger.warn(Constant.startDateLogMsg);
    } else if (this.copyErrorFlagEndDate) {
      this.copyEndDate$.nativeElement.focus();
      //(<HTMLInputElement>document.getElementById('copyEndDate')).focus();
    }

    /*For Copy Order- Checking date format validation for mat start and end date-Shivang_26-12-19*/

    this.checkDateValidity(pageMode.copy);
    if (this.copyStartDateValid && this.copyEndDateValid) {
      this.transformDateFormat(pageMode.copy, this.copyOrderModel.StartDate, this.copyOrderModel.EndDate);
    }
    /*For Copy Order- Checking date format validation for mat start and end date-Shivang_26-12-19*/

    var controls = this.copyOrderForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name == 'timeSlots') {
          for (var copyTime = 0; copyTime < data.length; copyTime++) {
            var copyTimeValue = this.getTimeFromTimeSlot(data, copyTime, 'start');
            if (copyTimeValue == null || copyTimeValue == '') {
              document.getElementById('copyStartTime' + copyTime).focus();
              break;
            }
          }
        } else {
          document.getElementById('copy' + name).focus();
        }
        break;
      }
    }
    this.copyEndTimeFlag = true;
    this.copyStartTimeFlag = true;
    this.compareTimevalidator(pageMode.copy, 'submit');
    if (
      this.copyCompareTimeErrorFlag ||
      //endTimeErrorFlag ||
      this.copyOrderForm.valid == false ||
      this.copyErrorFlagStartDate ||
      this.copyErrorFlagEndDate ||
      this.copySubmitError === true ||
      this.copySubmitErrorFlag === true ||
      this.copyLocationErrorflag === true ||
      this.copycheckBoxValidator === true ||
      !this.copyStartDateLimitFlag ||
      !this.copyEndDateValid ||
      !this.copyStartDateValid ||
      !this.copyStartTimeFlag ||
      !this.copyEndTimeFlag
    ) {
      return;
    }
    if (this.copyOrderCreatedId != null) {
      this.copyOrderForm.value.OrderId = this.copyOrderCreatedId;
    }

    this.saveUpdateOrder(
      this.copyOrderForm,
      this.copyIntegrationList,
      this.copyOrderLocationList,
      pageMode.copy,
      this.locationStateCountyZipCopyList
    );
  }

  getOrderTypeValue(event: any) {
    this.copyLeadSourceList = [];
    this.copyIntegrationList = [];
    this.copyLeadSources = [];
    const Leadsources = this.copyOrderForm.get('Leadsources');
    Leadsources.setValue('');
    Leadsources.setValidators([Validators.required]);
    Leadsources.updateValueAndValidity();
    if (
      (this.copyOrderTypeValueOnSelect != event.target.value && this.copyOrderTypeValueOnSelect != '') ||
      this.orderModel.SourceType != event.target.value ||
      event.target.value == ''
    ) {
      this.copyOrderLocationList = [];
      this.locationStateCountyZipCopyList = [];
    }
    this.copyOrderTypeValueOnSelect = event.target.value;

    if (event.target.value != '') {
      const productTypeControl = this.copyOrderForm.get('ProductType');
      productTypeControl.setValue('');
      productTypeControl.updateValueAndValidity();
      this.enableSpinner();
      this.copyLeadSourceList = [];
      this.copyLeadSources = [];

      /* open get productutypes when select order type */

      this._orderService.getProductTypes(event.target.value).subscribe(
        res => {
          this.copyProductType = JSON.parse(res.toString());
          this.disableSpinner();
          if (this.copyProductType == null) {
            this.copyProductType = [];
            this.openDialog([Constant.SERVER_ERROR]);
          }
        },
        err => {
          this._orderService.returnToErrorPage(err.error);
          this._logger.error(Constant.productTypeLogMsg);
        }
      );

      /* end get productutypes when select order type  */

      if (event.target.value == 'Calls') {
        this.isCallLead = true;
        this.HideNotification = true;
        this.hideCountyZipCodeOfCopyForm = true;
        this.resizeTableOnCopyCallTab = true;
        this.copyHideFields = false;
        this.copyHideDemographics = true;
        const MaxCallCurrencyControl = this.copyOrderForm.get('MaxCallCurrency');
        const PhoneNumberControl = this.copyOrderForm.get('PhoneNumber');
        MaxCallCurrencyControl.setValidators([Validators.required]);
        MaxCallCurrencyControl.updateValueAndValidity();
        PhoneNumberControl.setValidators([
          Validators.required,
          Validators.minLength(10),
          Validators.pattern('[0-9]|^d{3}-d{3}-d{4}$')
        ]);

        PhoneNumberControl.updateValueAndValidity();
      } else if (event.target.value == 'Leads') {
        this.isCallLead = false;
        this.hideCountyZipCodeOfCopyForm = false;
        this.resizeTableOnCopyCallTab = false;
        this.copyHideFields = true;
        this.copyHideDemographics = false;
        const MaxCallCurrencyControl = this.copyOrderForm.get('MaxCallCurrency');
        const PhoneNumberControl = this.copyOrderForm.get('PhoneNumber');
        MaxCallCurrencyControl.setValidators(null);
        MaxCallCurrencyControl.setValue('');
        MaxCallCurrencyControl.updateValueAndValidity();
        PhoneNumberControl.setValidators(null);
        PhoneNumberControl.setValue('');
        PhoneNumberControl.updateValueAndValidity();
        this.getBrokerInformation();
      } else {
        this.copyHideFields = false;
        this.copyHideDemographics = false;
      }
      this.orderTypeValue = event.target.value;
    } else {
      const productTypeControl = this.copyOrderForm.get('ProductType');
      productTypeControl.setValue('');
      productTypeControl.updateValueAndValidity();

      this.copyProductType = [];
      this.copyLeadSources = [];
    }
    this.clearCopyLocationControlValue();
  }

  getProductTypeValue(event: any) {
    const Leadsources = this.copyOrderForm.get('Leadsources');
    const OrderType = this.copyOrderForm.get('OrderType').value;
    Leadsources.setValue('');
    Leadsources.setValidators([Validators.required]);
    Leadsources.updateValueAndValidity();
    if (event.target.value != '') {
      this.copyLeadSourceList = [];
      this.copyIntegrationList = [];
      this.productTypeValue = event.target.value;
      if (OrderType != '') {
        this.LeadSources();
      }
    } else {
      this.copyLeadSources = [];
    }
  }

  LeadSources() {
    this.enableSpinner();
    this._orderService.getFilteredLeadSources(this.accessToken, this.orderTypeValue, this.productTypeValue).subscribe(
      res => {
        this.copyLeadSources = JSON.parse(res.toString());
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.leadsourceLogMsg);
      }
    );
  }
  /*Open Show/Hide Spinner Methods*/
  enableSpinner(): void {
    this.spinnerFlag = true;
  }

  disableSpinner(): void {
    this.spinnerFlag = false;
  }
  /* Open-Method to check validity of form controls */
  findInvalidControls() {
    const invalid = [];
    const controls = this.editOrderForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  showSuccessMessage(msg: string) {
    this._activatedRoute.params.subscribe(params => (this.orderId = params['orderId']));
    this._service.currentFormType.subscribe(data => {
      let mode: pageMode = data === 'edit' ? pageMode.edit : pageMode.copy;
      this.filterData = mode;
      if (mode === pageMode.edit) {
        this.showEditMsg = true;
        this.editSuccessMsg = msg;
        this.removeSuccessMessage(mode);
      } else {
        this.showCopyMsg = true;
        this.copySuccessMsg = msg;
      }
    });

    this.showMsg = true;
    const source = timer(6000);
    source.subscribe(val => {
      this.showMsg = false;
      this.message = '';
    });
  }
  /* active/inctive using spacebar press*/
  handleCheckBoxKeyUp(e: any) {
    if (e.keyCode === 32) {
      let checkBoxElement = e.target.previousElementSibling;
      checkBoxElement.click();
      return false;
    }
  }
  /* selectleadsources on pressing enterkey firefox*/
  validateEnter(e: any) {
    if (e.keyCode === 13 && (navigator.userAgent.search('Firefox') || navigator.userAgent.search('trident') >= 0)) {
      e.target.click();
    }
  }
  /*handle tab focus between copy and edit order page*/
  setEditOrderTabIndex() {
    let editOrderDivElements = (document
      .getElementById('edit-order-form')
      .querySelectorAll(
        'input, select, button,.slider, i, .ng5-slider-pointer,.checkmark ,.view-all, a'
      ) as any) as Array<HTMLElement>;
    var editChildCount = editOrderDivElements.length;
    for (var count = 0; count < editChildCount; count++) {
      editOrderDivElements[count].tabIndex = 0;
    }

    let copyOrderDivElements = (Array.from(
      document
        .getElementById('copy-order-form')
        .querySelectorAll('input, select, button,.slider, i, .ng5-slider-pointer,.checkmark ,.view-all, a')
    ) as any) as Array<HTMLElement>;
    var copyChildCount = copyOrderDivElements.length;
    for (var j = 0; j < copyChildCount; j++) {
      copyOrderDivElements[j].tabIndex = -1;
    }

    this.disableTabIndexForDayCheckbox();
  }

  setCopyOrderTabIndex() {
    let copyOrderDivElements = (document
      .getElementById('copy-order-form')
      .querySelectorAll(
        'input, select, button,.slider, i, .ng5-slider-pointer,.checkmark ,.view-all, a'
      ) as any) as Array<HTMLElement>;
    var childCount = copyOrderDivElements.length;
    for (var copyCount = 0; copyCount < childCount; copyCount++) {
      copyOrderDivElements[copyCount].tabIndex = 0;
    }

    let editOrderDivElements = (document
      .getElementById('edit-order-form')
      .querySelectorAll(
        'input, select, button,.slider, i, .ng5-slider-pointer,.checkmark ,.view-all, a'
      ) as any) as Array<HTMLElement>;
    var childCount = editOrderDivElements.length;
    for (var editDiv = 0; editDiv < childCount; editDiv++) {
      editOrderDivElements[editDiv].tabIndex = -1;
    }
    this.disableTabIndexForDayCheckbox();
  }

  disableTabIndexForDayCheckbox() {
    let days = document.getElementsByClassName('days-check') as HTMLCollectionOf<HTMLElement>;
    var childCount = days.length;
    for (var dayCount = 0; dayCount < childCount; dayCount++) {
      days[dayCount].tabIndex = -1;
    }
  }
  /* handle event of enter key*/
  handlekeyPressEvent(e: any) {
    if (e.keyCode === 13) {
      e.target.click();
      e.preventDefault();
    }
  }
  /*start - edit order IE Lead flow number increment/decrement from keyboard */
  checkHourlyNumber(e: any, flag: string) {
    const hourly = this.editOrderForm.get('HourlyCallCap');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'hourly'
    ) {
      hourly.setValue(Number(e.target.value) + 1);
      hourly.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'hourly'
    ) {
      hourly.setValue(1);
      hourly.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'hourly') {
      hourly.setValue(100);
      hourly.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'hourly'
    ) {
      hourly.setValue(Number(e.target.value) - 1);
      hourly.updateValueAndValidity();
    }
  }

  checkDailyCapNumber(e: any, flag: string) {
    const daily = this.editOrderForm.get('DailyCallCap');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'daily'
    ) {
      daily.setValue(Number(e.target.value) + 1);
      daily.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'daily'
    ) {
      daily.setValue(1);
      daily.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'daily') {
      daily.setValue(100);
      daily.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'daily'
    ) {
      daily.setValue(Number(e.target.value) - 1);
      daily.updateValueAndValidity();
    }
  }

  checkMaxCallNumber(e: any, flag: string) {
    const maxcall = this.editOrderForm.get('MaxCallCurrency');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(Number(e.target.value) + 1);
      maxcall.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(1);
      maxcall.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'maxCall') {
      maxcall.setValue(100);
      maxcall.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(Number(e.target.value) - 1);
      maxcall.updateValueAndValidity();
    }
  }
  /* start - copy order IE Lead flow number increment / decrement from keyboard */
  checkCopyHourlyNumber(e: any, flag: string) {
    const hourly = this.copyOrderForm.get('HourlyCallCap');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'hourly'
    ) {
      hourly.setValue(Number(e.target.value) + 1);
      hourly.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'hourly'
    ) {
      hourly.setValue(1);
      hourly.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'hourly') {
      hourly.setValue(100);
      hourly.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'hourly'
    ) {
      hourly.setValue(Number(e.target.value) - 1);
      hourly.updateValueAndValidity();
    }
  }

  checkCopyDailyCapNumber(e: any, flag: string) {
    const daily = this.copyOrderForm.get('DailyCallCap');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'daily'
    ) {
      daily.setValue(Number(e.target.value) + 1);
      daily.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'daily'
    ) {
      daily.setValue(1);
      daily.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'daily') {
      daily.setValue(100);
      daily.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'daily'
    ) {
      daily.setValue(Number(e.target.value) - 1);
      daily.updateValueAndValidity();
    }
  }

  checkCopyMaxCallNumber(e: any, flag: string) {
    const maxcall = this.copyOrderForm.get('MaxCallCurrency');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(Number(e.target.value) + 1);
      maxcall.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1 && flag == 'maxCall')) &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(1);
      maxcall.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'maxCall') {
      maxcall.setValue(100);
      maxcall.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(Number(e.target.value) - 1);
      maxcall.updateValueAndValidity();
    }
  }
  /* Start- Method For Detecting browser-Shivang_10-12-19*/
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  /* Start - Method to check date validity */
  public isValidDate(dateString: any): boolean {
    let dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
    if (dateString.match(dateformat)) {
      if (moment(dateString, 'MM/DD/YYYY').isValid()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /* Start - Method to check date input chars-Shivang_12-20-19 */
  checkDateInput(event: any) {
    const dateLength = event.target.value.length;
    let charCode = event.which ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 47 || charCode > 57)) || dateLength > 9) {
      return false;
    }

    if (event.which !== 8 || event.which !== 46) {
      this.setCopyBtnDisable();
      //Method Call to disable copy button while date change (Shivang_08-01-20)
      let dateInput = <HTMLInputElement>document.getElementById(event.target.id);
      let dateValue = dateInput.value;
      if (dateValue.length === 2 || dateValue.length === 5) {
        event.target.value = event.target.value + '/';
      }
    }
    return true;
  }
  /* Start - Method to check time input chars-Shivang_24-12-19 */
  checkTimeInput(event: any) {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    if (this.browserName === 'ie') {
      if (event.which !== 8 || event.which !== 46) {
        let timeInput = <HTMLInputElement>document.getElementById(event.target.id);
        let timeValue = timeInput.value;

        let index = timeValue.indexOf(':');
        if (timeValue.length == 2 && index < 0) {
          event.target.value = event.target.value + ':';
        }
      }
    }
    return true;
  }
  /* Start - Method to transform date format for IE-Shivang_12-20-19 */
  transformDateFormat(orderType: pageMode, StartDate: any, EndDate: any) {
    if (orderType === pageMode.edit) {
      this.sDate = this._datePipe.transform(StartDate, 'yyyy-MM-dd');
      this.eDate = this._datePipe.transform(EndDate, 'yyyy-MM-dd');

      const startDateControl = this.editOrderForm.get('StartDate');
      const endDateControl = this.editOrderForm.get('EndDate');
      startDateControl.setValue(this.sDate);
      endDateControl.setValue(this.eDate);
      startDateControl.updateValueAndValidity();
      endDateControl.updateValueAndValidity();
    } else {
      this.sDateCopy = this._datePipe.transform(StartDate, 'yyyy-MM-dd');
      this.eDateCopy = this._datePipe.transform(EndDate, 'yyyy-MM-dd');

      const copyStartDateControl = this.copyOrderForm.get('StartDate');
      const copyEndDateControl = this.copyOrderForm.get('EndDate');
      copyStartDateControl.setValue(this.sDateCopy);
      copyEndDateControl.setValue(this.eDateCopy);
      copyStartDateControl.updateValueAndValidity();
      copyEndDateControl.updateValueAndValidity();
    }
  }

  onSelectZipCode(event: any) {
    if (this.checkSelectedField === 'none') {
      this.checkSelectedField = 'zipCode';
    }
    const stateControl = this.editOrderForm.get('State');

    const countyControl = this.editOrderForm.get('County');

    if (
      (typeof stateControl.value === 'undefined' || stateControl.value === '') &&
      (typeof countyControl.value === 'undefined' || countyControl.value === '')
    ) {
      if (event.length >= 5) {
        this._orderService.getStateCountyData(event).subscribe(
          res => {
            this.countyData = [];
            this.locationData = [];
            let data = JSON.parse(res.toString());
            if (data == '') {
              this.locationErrorNull = '';
              this.locationError = Constant.ENTER_VALID_ZIPCODE_ERROR_MSG;
            } else {
              this.locationErrorNull = '';
              this.locationError = '';
            }
            for (let i in data) {
              this.countyData.push(data[i].countyName);
              this.locationData.push(data[i].state);
            }

            stateControl.setValue(data[0].state);
            countyControl.setValue(data[0].countyName);
            this.locationData = this.locationData.filter((item: any, pos: any, arr: any) => arr.indexOf(item) === pos);
          },
          err => {
            this._logger.error(Constant.countyLogMsg);
          }
        );
      }
    }
  }

  getStateAndCountyByZipCode(event: any, formType: pageMode) {
    this.enableSpinner();
    if (formType === pageMode.edit) {
      this.countyData = [];
      this.locationData = [];
    } else {
      this.countyCopyData = [];
      this.locationCopyData = [];
    }
    this._orderService.getStateCountyData(event).subscribe(
      res => {
        this.disableSpinner();
        let data: Location[] = JSON.parse(res.toString());
        if (data.length == 0 && event.length >= 5) {
          if (formType === pageMode.edit) {
            this.locationErrorNull = '';
            this.locationError = Constant.ENTER_VALID_ZIPCODE_ERROR_MSG;
          } else {
            this.copyLocationErrorNull = '';
            this.copyLocationError = Constant.ENTER_VALID_ZIPCODE_ERROR_MSG;
          }
        } else {
          this.locationErrorNull = '';
          this.locationError = '';
          for (let i in data) {
            this.createStateAndCountyDataListOnEnterZipcode(formType, data[i].state, data[i].countyName);
          }
          if (data.length === 1) {
            if (formType === pageMode.edit) {
              const stateControl = this.editOrderForm.get('State');
              const countyControl = this.editOrderForm.get('County');
              stateControl.setValue(data[0].state);
              countyControl.setValue(data.map(a => a.countyName));
            } else {
              const stateControl = this.copyOrderForm.get('State');
              const countyControl = this.copyOrderForm.get('County');
              stateControl.setValue(data[0].state);
              countyControl.setValue(data[0].countyName);
            }
          } else {
            /* open remove duplicate value from list on edit form */

            this.locationData = this.locationData.filter(
              (test: any, index: any, array: any) =>
                index === array.findIndex((findTest: any) => findTest.state === test.state)
            );

            /* end remove duplicate value from list on edit form */

            /* open remove duplicate value from list on copy form */

            this.locationCopyData = this.locationCopyData.filter(
              (test: any, index: any, array: any) =>
                index === array.findIndex((findTest: any) => findTest.state === test.state)
            );

            /* end remove duplicate value from list on copy form */

            this.setStateOnZipcodeSelection(formType);
          }
        }
      },
      err => {
        //copy
        this._logger.error(Constant.stateZipCodeLogMsg);
      }
    );
  }
  /* open create state and county list on enter zip */
  createStateAndCountyDataListOnEnterZipcode(formType: pageMode, state: string, countyName: string) {
    let countyObj = new Location();
    countyObj.countyName = countyName;
    let stateObj = new Location();
    stateObj.state = state;
    if (formType === pageMode.edit) {
      if (!this.countyData.some((item: any) => item.countyName == countyName)) {
        this.countyData.push(countyObj);
        this.editOrderForm.get('County').setValue(countyName);
        this.ListCountySelected.push(countyName);
        this.CountySelector$.nzSelectService.updateListOfSelectedValue([countyName], true);
        setTimeout(() => (this.isAddCountyReady = true)); //reenable add location btn
      }
      if (!this.locationData.some((item: any) => item.state == state)) {
        this.locationData.push(stateObj);
      }
    } else {
      if (!this.countyCopyData.some((item: any) => item.countyName == countyName)) {
        this.countyCopyData.push(countyObj);
        this.copyOrderForm.get('County').setValue([countyName]);
        this.ListCountySelected.push(countyName);
        this.CopyCountySelector$.nzSelectService.updateListOfSelectedValue([countyName], true);
        setTimeout(() => (this.isAddCountyReady = true)); //reenable add location btn
      }
      if (!this.locationCopyData.some((item: any) => item.state == state)) {
        this.locationCopyData.push(stateObj);
      }
    }
  }

  onEnterZipCode(event: any, formType: pageMode) {
    if (
      event.keyCode != 9 &&
      event.keyCode != 37 &&
      event.keyCode != 38 &&
      event.keyCode != 39 &&
      event.keyCode != 40
    ) {
      event = event.target.value;
      this.setCopyBtnDisable();
      if (formType === pageMode.edit) {
        if (this.checkSelectedField === 'none' || this.checkSelectedField === 'zipCode') {
          this.checkSelectedField = 'zipCode';
          const stateControl = this.editOrderForm.get('State');
          stateControl.setValue('');
          const countyControl = this.editOrderForm.get('County');
          countyControl.setValue('');
          stateControl.updateValueAndValidity();
          countyControl.updateValueAndValidity();
        }

        if (this.checkSelectedField === 'none' || this.checkSelectedField === 'zipCode') {
          this.zipData = [];
          if (event.length == 5) {
            this.getStateAndCountyByZipCode(event, formType);
          }
          if (event.length == 0) {
            this.checkSelectedField = 'none';
            this.clearLocationControlValue();
          }
        }
      } else {
        if (this.copyLocationStartingPoint === 'none' || this.copyLocationStartingPoint === 'zipCode') {
          this.copyLocationStartingPoint = 'zipCode';
          const stateControl = this.copyOrderForm.get('State');
          stateControl.setValue('');
          const countyControl = this.copyOrderForm.get('County');
          countyControl.setValue('');
          stateControl.updateValueAndValidity();
          countyControl.updateValueAndValidity();
        }

        if (this.copyLocationStartingPoint === 'none' || this.copyLocationStartingPoint === 'zipCode') {
          this.locationCopyData = [];
          this.copyZipData = [];
          if (event.length == 5) {
            this.getStateAndCountyByZipCode(event, formType);
          }
          if (event.length == 0) {
            this.copyLocationStartingPoint = 'none';
            this.clearCopyLocationControlValue();
          }
        }
      }
    }
  }

  setStateOnZipcodeSelection(formType: pageMode) {
    if (formType === pageMode.edit) {
      if (this.locationData.length === 1) {
        const stateControl = this.editOrderForm.get('State');
        for (let locationCount in this.locationData) {
          stateControl.setValue(this.locationData[locationCount].state);
        }
      }
    } else {
      if (this.locationCopyData.length === 1) {
        const stateControl = this.copyOrderForm.get('State');
        for (let copyLocationCount in this.locationData) {
          stateControl.setValue(this.locationCopyData[copyLocationCount].state);
        }
      }
    }
  }

  /* open update zip code value on select state */

  updateLocationControlValue() {
    if (this.checkSelectedField === 'state') {
      const zipCodeControl = this.editOrderForm.get('ZipCode');
      const CountyControl = this.editOrderForm.get('County');
      zipCodeControl.setValue('');
      CountyControl.setValue('');
      zipCodeControl.updateValueAndValidity();
      CountyControl.updateValueAndValidity();
    }
  }

  /* end  update zip code value on select state */

  /* clear location fields on add */
  clearLocationControlValue() {
    this.countyData = [];
    this.locationData = [];
    this.zipData = [];
    this.getStateData();
    this.clearCountyDropDown();
    const zipCodeControl = this.editOrderForm.get('ZipCode');
    const CountyControl = this.editOrderForm.get('County');
    zipCodeControl.setValue('');
    CountyControl.setValue('');
    zipCodeControl.updateValueAndValidity();
    CountyControl.updateValueAndValidity();
  }
  /* clear location fields on add */

  /* clear location fields on add */
  clearCopyLocationControlValue() {
    this.countyCopyData = [];
    this.locationCopyData = [];
    this.copyZipData = [];
    this.getStateData();
    this.clearCountyDropDown();
    const zipCodeControl = this.copyOrderForm.get('ZipCode');
    const CountyControl = this.copyOrderForm.get('County');
    zipCodeControl.setValue('');
    CountyControl.setValue('');
    zipCodeControl.updateValueAndValidity();
    CountyControl.updateValueAndValidity();
  }
  /* clear location fields on add */

  /* Start Method - To allow only numeric key inputs */
  checkPhoneNumberKeyInput(event: any, formType: pageMode) {
    let keyCode = event.keyCode;
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      keyCode == 8 ||
      (keyCode >= 35 && keyCode <= 40) ||
      keyCode == 46 ||
      (keyCode >= 96 && keyCode <= 105)
    ) {
      if (
        event.key === '-' ||
        event.key === '@' ||
        event.key === '!' ||
        event.key === '#' ||
        event.key === '$' ||
        event.key === '%' ||
        event.key === '*' ||
        event.key === '(' ||
        event.key === '^' ||
        event.key === '_' ||
        event.key === '&' ||
        event.key === '~' ||
        event.key === ')' ||
        event.key === '_' ||
        event.key === '`' ||
        event.key === '='
      )
        return false;
      if (formType == pageMode.edit) {
        this.setCopyBtnDisable();
      }
      return true;
    } else {
      return false;
    }
  }
  /* End Method - To allow only numeric key inputs */
  getPhoneDropDown() {
    this._orderService.getPhoneDropDown(this.accessToken).subscribe(
      res => {
        this.brokerModel = JSON.parse(res);
        this.iprolistphone = this.extractPhone(JSON.parse(res));
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.stateLogMsg);
      }
    );
  }
  extractPhone(res: brokerInfo) {
    let phonelist: string[] = [];
    let temphone = phoneFormat(res.brokerMobile);
    let phone = `Cell : ${temphone}`;
    phonelist.push(phone);
    temphone = phoneFormat(res.brokerPhone);
    phone = `Phone : ${temphone}`;
    phonelist.push(phone);
    return phonelist;
  }
  checkListPhone() {
    if (this.iprolistphone == null || this.iprolistphone.length == 0) {
      this.getPhoneDropDown();
    }
  }
  /* Start Method - Checks key input value for call caps,restricting greater than 3 digit */
  checkCallCapValueOnInput(event: any) {
    let callCapValue = event.target.value;
    const maxLength = event.target.maxLength;
    this.setCopyBtnDisable();
    if (callCapValue.length > maxLength) {
      event.target.value = callCapValue.slice(0, maxLength);
    }
  }
  /* End Method - Checks key input value for call caps,restricting greater than 3 digit */

  // HELPERS

  startPickerChange(val: any, index: any, formType: pageMode) {
    this.compareTimevalidator(formType, '');
  }

  endPickerChange(val: any, index: any, formType: pageMode) {
    this.compareTimevalidator(formType, '');
  }

  convertTime(formType: pageMode) {
    var timeSlots;
    var startTime, endTime, group;
    if (formType == pageMode.edit) {
      timeSlots = this.editOrderForm.get('timeSlots') as FormArray;
    } else {
      timeSlots = this.copyOrderForm.get('timeSlots') as FormArray;
    }
    for (let i = 0; i < timeSlots.length; i++) {
      group = timeSlots.at(i) as FormGroup;
      startTime = group.get('Start').value;
      if (startTime !== '') {
        startTime = startTime.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
        startTime = this.time24Format(startTime);
      }
      endTime = group.get('End').value;
      if (endTime !== '') {
        endTime = endTime.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
        endTime = this.time24Format(endTime);
      }
      group.patchValue({ Start: startTime, End: endTime });
    }
  }

  validateTimePickers() {
    var timeSlots = this.editOrderForm.get('timeSlots') as FormArray;
    var tsLength = timeSlots.length;
    var i = 0;
    for (i = 0; i <= tsLength - 1; i++) {
      var group = timeSlots.at(i) as FormGroup;
      //Since the pickers are synced with the textboxes, get the textbox values to validate
      var startTextbox = group.get('Start').value;
      startTextbox = startTextbox.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
      var endTextbox = group.get('End').value;
      endTextbox = endTextbox.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
      this._logger.debug('start is ' + startTextbox + ' End is ' + endTextbox);

      let conStart = this.militaryTimeToDate(startTextbox);
      let conEnd = this.militaryTimeToDate(endTextbox);

      let startInt: number = Number(conStart);
      let endInt: number = Number(conEnd);
      this._logger.debug('startInt is ' + startInt + ' end is ' + endInt);

      //test whether start is null and end has a value
      if (startInt == null && endInt > 0) {
        this._logger.debug('start is null & end has value');
        this.startTimeFlagErr = '';
      } else {
        this.startTimeFlagErr = '';
      }

      //test whether end is null
      if (startInt != null && endInt == null) {
        this._logger.debug('end is null');
        this.endTimeFlagErr = '';
      } else {
        this.endTimeFlagErr = '';
      }

      //Test whether start is less than end, if it's greater throw error that end time needs to be greater.
      if (startInt != null || endInt != null) {
        if (startInt <= endInt) {
          this._logger.debug('start is less than end');
          this.startTimeFlagErr = '';
          this.endTimeFlagErr = '';
          this.compareTimeErrorFlag = false;
          this.copyCompareTimeErrorFlag = false;
        } else {
          this.compareTimeErrorFlag = true;
          this.copyCompareTimeErrorFlag = true;
          this.endTimeFlagErr = '';
        }
      }
    }
  }

  militaryTimeToDate(input: string): Date {
    let suff = input.split(' ')[1];
    let hour = +input.split(':')[0];
    let min = suff != undefined ? input.split(':')[1].substr(0, 2) : input.split(':')[1];
    let hour24 = suff != undefined ? (suff == 'AM' ? hour : hour + 12) : hour;
    let time = `${hour24}:${min}`;
    var sampleDate = '04/12/2019 ';
    var startTimeValue = new Date(sampleDate + time);
    return startTimeValue;
  }

  time24Format(input: string): string {
    let suff = input.split(' ')[1];
    let hour = +input.split(':')[0];
    let min = suff != undefined ? input.split(':')[1].substr(0, 2) : input.split(':')[1];
    let hour24;
    if (suff == undefined) {
      suff = hour >= 12 ? 'PM' : 'AM';
    }
    if (suff == 'AM') {
      hour = hour == 12 ? 0 : hour;
      hour24 = hour;
    } else {
      hour24 = hour >= 12 ? hour : hour + 12;
    }
    return `${hour24}:${min}`;
  }

  dateToMilitaryTime(input: Date): string {
    var result = '07:45';
    var dateToConvert = input.toTimeString();
    dateToConvert = dateToConvert.substr(0, 5);
    return dateToConvert;
  }
  //Need to sync the timepickers with the text value returned from LeadCloud
  initTimePickers() {
    var timeSlots = this.editOrderForm.get('timeSlots') as FormArray;
    var tsLength = timeSlots.length;
    var i = 0;
    for (i = 0; i <= tsLength - 1; i++) {
      var group = timeSlots.at(i) as FormGroup;
      var startTime = group.get('Start').value;
      var endTime = group.get('End').value;
      var startDate = this.militaryTimeToDate(startTime);
      var endDate = this.militaryTimeToDate(endTime);
      group.patchValue({ StartPicker: startDate, Start: startTime, EndPicker: endDate, End: endTime });
    }
  }
  /////////////////////////////////
  setCountyEvent() {
    this.CountySelector$.registerOnChange((values: string[]) => {
      this.isAddCountyReady = false;
      if (values.indexOf('Select All') > -1) {
        this.isReadySend = false;
        this.isSelectAll = true;
        let items = this.countyData.map(a => a.countyName);
        this.ListCountySelected = items;
        if (values.length > 1) {
          values.forEach(k => {
            let index = this.ListCountySelected.findIndex(a => a == k);
            if (index > -1) this.ListCountySelected.splice(index, 1);
          });
          this.updatePanel(pageMode.edit);
        } else {
          this.updatePanel(pageMode.edit);
        }
      } else {
        this.isReadySend = true;
        this.isSelectAll = false;
        this.ListCountySelected = values;
      }
    });
    this.CopyCountySelector$.registerOnChange((values: string[]) => {
      this.isAddCountyReady = false;
      if (values.indexOf('Select All') > -1) {
        this.isReadySend = false;
        this.isSelectAll = true;
        let items = this.countyCopyData.map(a => a.countyName);
        this.ListCountySelected = items;
        if (values.length > 1) {
          values.forEach(k => {
            let index = this.ListCountySelected.findIndex(a => a == k);
            if (index > -1) this.ListCountySelected.splice(index, 1);
          });
          this.updatePanel(pageMode.copy);
        } else {
          this.updatePanel(pageMode.copy);
        }
      } else {
        this.isReadySend = true;
        this.isSelectAll = false;
        this.ListCountySelected = values;
      }
    });
  }

  private updatePanel(formType: pageMode) {
    if (formType == pageMode.edit) {
      this.CountySelector$.writeValue(this.ListCountySelected);
      this.CountySelector$.closeDropDown();
      setTimeout(() => {
        this.CountySelector$.toggleDropDown();
        this.isReadySend = true;
      }, null);
    } else {
      this.CopyCountySelector$.writeValue(this.ListCountySelected);
      this.CopyCountySelector$.closeDropDown();
      setTimeout(() => {
        this.CopyCountySelector$.toggleDropDown();
        this.isReadySend = true;
      }, null);
    }
  }

  private clearCountyDropDown() {
    this.ListCountySelected = [];
    this.CountySelector$.writeValue([]);
    this.CopyCountySelector$.writeValue([]);
  }
  getFormControl(formType: pageMode, name: string) {
    if (formType == pageMode.edit) {
      return this.editOrderForm.get(name);
    } else {
      return this.copyOrderForm.get(name);
    }
  }
  getFormArrayControl(formType: pageMode, name: string, arrayName: string, index: number) {
    if (formType == pageMode.edit) {
      const array = this.editOrderForm.get(arrayName) as FormArray;
      return array.at(index).get(name);
    } else {
      const array = this.copyOrderForm.get(arrayName) as FormArray;
      return array.at(index).get(name);
    }
  }

  isValid(formType: pageMode, name: string) {
    var e = this.getFormControl(formType, name);
    return e && e.valid;
  }
  hasError(formType: pageMode, name: string, prop?: string) {
    let submitted = formType == pageMode.edit ? this.submitted : this.copySubmitted;
    let e = this.getFormControl(formType, name);

    if (prop) {
      if (e.getError(prop) != null) return e && (submitted || e.dirty || e.touched) && e.getError(prop);
      else return false;
    } else {
      return e && (submitted || e.dirty || e.touched) && !e.valid;
    }
  }
  hasArrayError(formType: pageMode, arrayName: string, name: string, prop: string, index: number) {
    let submitted = formType == pageMode.edit ? this.submitted : this.copySubmitted;
    let e = this.getFormArrayControl(formType, name, arrayName, index);

    if (prop) {
      if (e.getError(prop) != null) return e && (submitted || e.dirty || e.touched) && e.getError(prop);
      else return false;
    } else {
      return e && (submitted || e.dirty || e.touched) && !e.valid;
    }
  }

  changeOrderType(orderType: string) {
    this.copyLeadSourceList = [];
    this.copyIntegrationList = [];
    this.copyLeadSources = [];
    const Leadsources = this.copyOrderForm.get('Leadsources');
    Leadsources.setValue('');
    Leadsources.setValidators([Validators.required]);
    Leadsources.updateValueAndValidity();
    if (
      (this.copyOrderTypeValueOnSelect != orderType && this.copyOrderTypeValueOnSelect != '') ||
      this.orderModel.SourceType != orderType ||
      orderType == ''
    ) {
      this.copyOrderLocationList = [];
      this.locationStateCountyZipCopyList = [];
    }
    this.copyOrderTypeValueOnSelect = orderType;

    if (orderType != '') {
      const productTypeControl = this.copyOrderForm.get('ProductType');
      productTypeControl.setValue('');
      productTypeControl.updateValueAndValidity();
      this.enableSpinner();
      this.copyLeadSourceList = [];
      this.copyLeadSources = [];

      /* get productutypes when select order type */
      this._orderService.getProductTypes(orderType).subscribe(
        res => {
          this.copyProductType = JSON.parse(res.toString());
          this.disableSpinner();
          if (this.copyProductType == null) {
            this.copyProductType = [];
            this.openDialog([Constant.SERVER_ERROR]);
          }
        },
        err => {
          this._orderService.returnToErrorPage(err.error);
          this._logger.error(Constant.productTypeLogMsg);
        }
      );

      if (orderType == 'Calls') {
        this.isCallLead = true;
        this.HideNotification = true;
        this.hideCountyZipCodeOfCopyForm = true;
        this.resizeTableOnCopyCallTab = true;
        this.copyHideFields = false;
        this.copyHideDemographics = true;
        const MaxCallCurrencyControl = this.copyOrderForm.get('MaxCallCurrency');
        const PhoneNumberControl = this.copyOrderForm.get('PhoneNumber');
        MaxCallCurrencyControl.setValidators([Validators.required]);
        MaxCallCurrencyControl.updateValueAndValidity();
        PhoneNumberControl.setValidators([
          Validators.required,
          Validators.minLength(10),
          Validators.pattern('[0-9]|^d{3}-d{3}-d{4}$')
        ]);

        PhoneNumberControl.updateValueAndValidity();
      } else if (orderType == 'Leads') {
        this.isCallLead = false;
        this.hideCountyZipCodeOfCopyForm = false;
        this.resizeTableOnCopyCallTab = false;
        this.copyHideFields = true;
        this.copyHideDemographics = false;
        const MaxCallCurrencyControl = this.copyOrderForm.get('MaxCallCurrency');
        const PhoneNumberControl = this.copyOrderForm.get('PhoneNumber');
        MaxCallCurrencyControl.setValidators(null);
        MaxCallCurrencyControl.setValue('');
        MaxCallCurrencyControl.updateValueAndValidity();
        PhoneNumberControl.setValidators(null);
        PhoneNumberControl.setValue('');
        PhoneNumberControl.updateValueAndValidity();
        this.getBrokerInformation();
      } else {
        this.copyHideFields = false;
        this.copyHideDemographics = false;
      }
      this.orderTypeValue = orderType;
    } else {
      const productTypeControl = this.copyOrderForm.get('ProductType');
      productTypeControl.setValue('');
      productTypeControl.updateValueAndValidity();

      this.copyProductType = [];
      this.copyLeadSources = [];
    }
    this.clearCopyLocationControlValue();
  }
}

/* open lead sources class to prepare list */

class LeadSourceIntegration {
  IntegrationId: string;
  LeadSourceName: string;
  TransactionCost: string;
  TransactionPrice: string;
}

/* end  lead sources class to prepare list */
export enum pageMode {
  edit = 1,
  copy
}
