import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getUser(userId: string): Observable<Models.UserModel> {
    return this.httpClient.get<Models.UserModel>('/user/' + userId);
  }

  getUsers(): Observable<Array<Models.UserModel>> {
    return this.httpClient.get<Array<Models.UserModel>>('/user');
  }
}
