import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { Options } from 'ng5-slider';
import { OrderService } from './order.service';
import * as Constant from '../../constants/api';
import { Location } from '../model/location';
import { interval, concat } from 'rxjs';
import { CustomService } from '../services/custom.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorDailogComponent } from '@app/error-dailog/error-dailog.component';
import { ValidationErrorDialogComponent } from '@app/validation-error-dialog/validation-error-dialog.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Logger } from '../core/logger.service';
import { NzSelectComponent } from 'ng-zorro-antd';
import { brokerInfo } from '../model/brokerInfo';
import { phoneFormat, reformatePhone } from '../core/commonFunctions';

interface Post {
  startTime: Date;
  endTime: Date;
}

@Component({
  selector: 'app-order-add',
  templateUrl: './order-add.component.html',
  styleUrls: ['./order-add.component.scss']
})
export class OrderAddComponent implements AfterViewInit, OnInit {
  [x: string]: any;
  lastPhoneSelect: string;
  HourlyCallCap: number;
  DailyCallCap: number;
  isAddCountyReady: boolean = true;
  currPageAddLocation: number = 1; // currentpage of location page
  defaultTime: Date | null = null;
  startTimeIs: string = 'no start time selected';
  phoneNumberlengthError: string;
  sDate: string;
  eDate: string;
  checkSelectedField = 'none';
  maxcallcurrencyFlag: boolean = true;
  startDateLimitFlag: boolean = true;
  errorMessagelimit: string;
  errorMessageStartDatelimit: string;
  checkMaxCallCurrencyRange: boolean;
  maxCallSubmitError: boolean;
  endDateErrorMsg: string;
  startDateErrorMsg: string;
  recordsPerPages: string[] = ['5', '10', '15', '20', '25'];
  selectednumber: number = 25;
  Ziplist: any;
  spinnerFlag: boolean = false;
  locationData: Location[];
  checkboxErrorMsg: string;
  countyData: Location[];
  StartDate: string;
  EndDate: string;
  decimalValue: any;
  orderError: any = '';
  SubmitErrorFlag: boolean = true;
  startTimeError: string;
  endTimeError: string;
  SubmitError: boolean = false;
  LocationErrorflag: boolean;
  locationErrorNull: any;
  checkdailyCapRange: boolean;
  checkHourlyCapRange: boolean;
  orderNameError: any = '';
  orderName: string = '';
  maxCallCurrencyError: string;
  maxCallCurrencyRangeError: string;
  phoneNumberError: string;
  errorFlagOrderName: boolean = true;
  errorFlagStartDate: boolean = false;
  errorFlagEndDate: boolean = false;
  errorFlagDailyLeadCap: boolean = true;
  errorFlagHourlyLeadCap: boolean = true;
  timeslotError: string = '';
  accessToken: string;
  orderTypeError: string;
  productTypeError: string;
  startDateError: string;
  endDateError: string;
  timezoneError: string;
  hourlycapError: string;
  dailycapError: string = '';
  leadSourceError: string = '';
  isState: boolean;
  isCounty: boolean;
  state: string;
  zipCode: string;
  ListCountySelected: string[] = [];
  zipData: Location[] = [];
  balance: string;
  locationFlag: boolean;
  leadSources: LeadSourceIntegration[] = [];
  errorMessageStartDate: string;
  hourlyCallCapError: string;
  dailyCallCapError: string;
  HideFields: boolean;
  countyName: string;
  HideDemographics: any;
  locationError: string;
  allZipCodes: Location[];
  showAllZipCode: boolean;
  productType: string[];
  orderTypeValue: string;
  productTypeValue: string;
  stateForService: string;
  orderErrorMessage: string;
  showMsg: boolean;
  showLoadingIndicator = false;
  locationList: Array<Location> = [];
  locationStateCountyZipList: string[] = [];
  checkBoxError: boolean;
  checkBoxValidator: boolean = false;
  filteredCounties: Location[];
  isIeBrowser: boolean;
  isOtherBrowser: boolean;
  browserName: string;
  currentDate = new Date();
  isIE: boolean;
  flagForETime: boolean;
  flagForSTime: boolean;
  startFlag: boolean;
  endFlag: boolean;
  endTimeFlag: boolean;
  endTimeFlagErr: string;
  startTimeFlag: boolean;
  startTimeFlagErr: string;
  startDateValid: boolean;
  endDateValid: boolean;
  compareTimeErrorFlag: boolean = false;
  stateOptionValue: string = 'Select State';
  countyOptionValue: string = 'Select County';
  hideLocationCountyZipCode: boolean = false;
  setLeadValueOnSelect: string = 'none';
  resizeTableOnCallTab: boolean = false;
  @ViewChild('CountySelector', { static: true })
  CountySelector$: NzSelectComponent;
  @ViewChild('ZipCode', { static: true })
  ZipCode$: ElementRef;
  @ViewChild('HourlyCallCap', { static: true })
  HourlyCallCap$: ElementRef;
  @ViewChild('DailyCallCap', { static: true })
  DailyCallCap$: ElementRef;
  @ViewChild('MaxCallCurrency', { static: true })
  MaxCallCurrency$: ElementRef;
  SendEmailNotification: boolean = true;
  SendMobileNotification: boolean = false;
  chkMobileNotification: boolean = false;
  chkEmailNotification: boolean = true;
  textEmail: string = '';
  textMobile: string = '';
  isHideAlert: boolean;
  myContactAlert: { type: string; message: string };
  post: Post = {
    startTime: new Date(Date.now()),
    endTime: new Date(Date.now())
  };
  //DailyCallCap: number;
  //HourlyCallCap: number;
  iprolistphone: string[] = [];
  integrationList: string[] = [];
  filterList: LeadSourceIntegration[] = [];
  selectedObject: LeadSourceIntegration[] = [];
  isDone: boolean;
  isSelectAll: boolean;
  isReadySend: boolean;
  submitted = false;
  errorMessage: string = '';
  value: number = 18;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  autoCompleteValue: string = 'off';
  isAllstateMA: boolean = false;

  constructor(
    private _router: Router,
    private _orderService: OrderService,
    private _oauthService: OAuthService,
    private _fb: FormBuilder,
    private _service: CustomService,
    private _matDialog: MatDialog,
    private _datePipe: DatePipe,
    private _logger: Logger
  ) {
    this.locationData = [];
    this.countyData = [];
    this.zipData = [];
    this.allZipCodes = [];
    this.productType = [];
  }

  ngAfterViewInit() {
    const countyControl = this.addOrderForm.get('County');
    countyControl.setValue('');
    countyControl.updateValueAndValidity();

    this._oauthService.events.subscribe(event => {
      if (event.type === 'user_profile_loaded') {
        this._router.navigate(['/home'], { replaceUrl: true });
      }
    });

    interval(8000).subscribe(x => (this.locationError = ''));
    interval(8000).subscribe(x => (this.timeslotError = ''));
  }

  addOrderForm = this._fb.group({
    OrderId: [''],
    OrderType: ['', Validators.required],
    ProductType: ['', Validators.required],
    OrderName: ['', Validators.required],
    StartDate: ['', Validators.required],
    EndDate: [''],
    Status: [''],
    Leadsources: ['', Validators.required],
    State: ['', Validators.required],
    County: [''],
    ZipCode: [''],
    AgeRange: [''],
    TimeZone: ['', Validators.required],
    timeSlots: this._fb.array([this.addTimeSlotFormGroup()]),
    HourlyCallCap: ['', Validators.required],
    DailyCallCap: ['', Validators.required],
    MaxCallCurrency: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    recordsPerPage: ['0'],
    SendEmailNotification: [''],
    SendMobileNotification: ['']
  });

  addTimeSlot(): void {
    (<FormArray>this.addOrderForm.get('timeSlots')).push(this.addTimeSlotFormGroup());
  }

  /*validate phoneNumber*/
  validatePhoneNumber(value: string) {
    if (value !== '' && this.lastPhoneSelect !== value) {
      let phone: string = reformatePhone(value);
      this.lastPhoneSelect = phone;
      this.phoneNumberlengthError = phone.length >= 10 ? '' : Constant.PHONE_NUMBER_LENGTH_ERROR_MSG;
      this.addOrderForm.get('PhoneNumber').setValue(phone);
    } else {
      this.lastPhoneSelect = '';
    }
  }

  checkBoxValidation(checkBoxType: any) {
    var data = this.addOrderForm.get('timeSlots') as FormArray;
    var num = {};
    var i = 0;
    for (i = 0; i <= data.length - 1; i++) {
      var group = data.at(i) as FormGroup;
      var sunday = group.get('sunday').value;
      var monday = group.get('monday').value;
      var tuesday = group.get('tuesday').value;
      var wednesday = group.get('wednesday').value;
      var thursday = group.get('thursday').value;
      var friday = group.get('friday').value;
      var saturday = group.get('saturday').value;
      var applyAll = group.get('AllWeek').value;

      if (this.isIE == true) {
        if (checkBoxType === 'all') {
          if (applyAll) {
            this.checkBoxError = false;
          } else {
            if ((sunday || monday || tuesday || wednesday || thursday || friday || saturday) && applyAll == true) {
              this.checkBoxError = false;
            } else if (
              (sunday || monday || tuesday || wednesday || thursday || friday || saturday === false) &&
              applyAll === true
            ) {
              this.checkBoxError = false;
            } else if (
              sunday &&
              monday &&
              tuesday &&
              wednesday &&
              thursday &&
              friday &&
              saturday === true &&
              applyAll === false
            ) {
              this.checkBoxError = true;
            } else if (
              sunday == false &&
              monday == false &&
              tuesday == false &&
              wednesday == false &&
              thursday == false &&
              friday == false &&
              saturday == false &&
              applyAll == false
            ) {
              this.checkBoxError = true;
            }
          }
        } else {
          if (
            sunday == false &&
            monday == false &&
            tuesday == false &&
            wednesday == false &&
            thursday == false &&
            friday == false &&
            saturday == false &&
            checkBoxType === 'none'
          ) {
            this.checkBoxError = true;
          } else if (
            (sunday == true && checkBoxType === 'none') ||
            (monday == true && checkBoxType === 'none') ||
            (tuesday == true && checkBoxType === 'none') ||
            (wednesday == true && checkBoxType === 'none') ||
            (thursday == true && checkBoxType === 'none') ||
            (friday == true && checkBoxType === 'none') ||
            (saturday == true && checkBoxType === 'none')
          ) {
            this.checkBoxError = false;
          }
        }
      } else {
        if (sunday || monday || tuesday || wednesday || thursday || friday || saturday) {
          this.checkBoxError = false;
        } else {
          this.checkBoxError = true;
        }
      }
      num[i] = this.checkBoxError;
      if (num[i] == true) {
        this.checkboxErrorMsg = Constant.TIME_SLOT_ERROR_MESSAGE; //"Please select atleast one day in Rule" + (i + 1);
        this.checkBoxValidator = true;
      } else {
        this.checkBoxValidator = false;
        this.checkboxErrorMsg = '';
      }
      this.setErrorRequired(group, this.checkBoxError);
    }
  }
  setErrorRequired(group: FormGroup, validatorValue: boolean) {
    const setvalue = validatorValue ? { required: true } : null;
    group.get('sunday').setErrors(setvalue);
    group.get('monday').setErrors(setvalue);
    group.get('tuesday').setErrors(setvalue);
    group.get('wednesday').setErrors(setvalue);
    group.get('thursday').setErrors(setvalue);
    group.get('friday').setErrors(setvalue);
    group.get('saturday').setErrors(setvalue);
    group.get('AllWeek').setErrors(setvalue);
  }
  addTimeSlotFormGroup(): FormGroup {
    return this._fb.group({
      Start: ['', Validators.required],
      StartPicker: [''],
      End: ['', Validators.required],
      EndPicker: [''],
      AllWeek: ['', Validators.required],
      sunday: ['', Validators.required],
      monday: ['', Validators.required],
      tuesday: ['', Validators.required],
      wednesday: ['', Validators.required],
      thursday: ['', Validators.required],
      friday: ['', Validators.required],
      saturday: ['', Validators.required]
    });
  }

  getTimeFromTimeSlot(timeslots: any, timepos: number, timetype: string) {
    if (timetype == 'start') {
      let timetext = timeslots.value[timepos].Start;
      let meridianTime = this.getCurrentTime(timetext);
      return meridianTime;
    } else {
      let timetext = timeslots.value[timepos].End;
      let meridianTime = this.getCurrentTime(timetext);
      return meridianTime;
    }
  }

  getCurrentTime(timeInput: any) {
    var currentTime;
    var currentDate = new Date(timeInput);
    // OR we can define like that also for current date
    // var currentDate = new Date();
    let hour: any = currentDate.getHours();
    hour = ('0' + hour).slice(-2);
    let minutes: any = currentDate.getMinutes();
    minutes = ('0' + minutes).slice(-2);
    var meridian = hour >= 12 ? ' PM' : ' AM';
    currentTime = ((hour + 11) % 12) + 1 + ':' + minutes + meridian;
    return currentTime;
  }

  /*** submit data**/
  addOrder() {
    console.log('addOrder() triggered ');
    // var data = this.addOrderForm.get('timeSlots') as FormArray;
    this.convertTime();
    var data = <FormArray>this.addOrderForm.get('timeSlots');
    this._logger.debug('timeslots are ' + data.value);
    this.checkBoxValidation('none');
    this.submitted = true;
    if (this.addOrderForm.get('OrderType').value == 'Calls') {
      if (this.maxCallSubmitError == true) {
        this.MaxCallCurrency$.nativeElement.focus();
        this.maxcallcurrencyFlag = false;
      }
    }
    if (this.SubmitError) {
      if (this.hourlyCallCapError.length > 0) {
        this.HourlyCallCap$.nativeElement.focus();
      } else {
        this.DailyCallCap$.nativeElement.focus();
      }
    }
    for (var i = 0; i < data.length; i++) {
      var startTimeValue = this.getTimeFromTimeSlot(data, i, 'start');
      if (startTimeValue == '') {
        document.getElementById('startPicker' + i).focus();
      }
    }
    //let endTimeErrorFlag = this.timevalidator();

    this.LocationErrorflag = false;
    if (this.locationList.length == 0) {
      this.LocationErrorflag = true;
      this.locationErrorNull = Constant.ERROR_ON_LOCATION;
      if (this.addOrderForm.get('State').value === 'undefined') {
        this.addOrderForm.get('State').setErrors({ required: true });
      }
      if (
        !(
          (
            this.compareTimeErrorFlag ||
            this.errorFlagStartDate ||
            this.errorFlagEndDate ||
            this.SubmitError == true ||
            this.SubmitErrorFlag == true ||
            this.LocationErrorflag == true ||
            this.checkBoxValidator === true ||
            this.startDateLimitFlag == false ||
            !this.endDateValid ||
            !this.startDateValid ||
            this.maxcallcurrencyFlag == false
          )
          //|| !this.startTimeFlag ||
          // !this.endTimeFlag
        )
      ) {
        document.getElementById('State').focus();
      }
    }

    //Checking date format validation in mat datepicker-Shivang_12-20-19
    this.checkDateValidity();

    if (this.endDateValid && this.startDateValid) {
      this.transformDateFormat();
    }

    if (this.SubmitErrorFlag) {
      document.getElementById('OrderName').focus();
    }
    if (this.errorFlagStartDate) {
      (<HTMLInputElement>document.getElementById('StartDate')).focus();
      this._logger.error(Constant.startDateLogMsg);
    } else if (this.errorFlagEndDate) {
      (<HTMLInputElement>document.getElementById('EndDate')).focus();
    }

    var controls = this.addOrderForm.controls;

    this.compareTimevalidator();

    if (
      this.addOrderForm.valid == false ||
      //endTimeErrorFlag ||
      this.compareTimeErrorFlag ||
      this.errorFlagStartDate ||
      this.errorFlagEndDate ||
      this.SubmitError == true ||
      this.SubmitErrorFlag == true ||
      this.LocationErrorflag == true ||
      this.checkBoxValidator === true ||
      this.startDateLimitFlag == false ||
      !this.endDateValid ||
      !this.startDateValid ||
      this.maxcallcurrencyFlag == false
    ) {
      return;
    }
    this.enableSpinner();
    this.showLoadingIndicator = true;

    this._orderService
      .createOrder(this.addOrderForm, this.integrationList, this.locationList, this.locationStateCountyZipList)
      .subscribe(
        res => {
          if (res['OrderSaveStatus'] != 'Success') {
            this.openDialog(res['ErrorMessageList']);
            this.showLoadingIndicator = false;
          } else {
            if (res['deliveryDataStatus'] != 'Success') {
              this._service.setDeliveryMessage(Constant.deliver_Data_Message);
            }
            this._service.setLeadType(this.addOrderForm.get('OrderType').value);
            this._service.setSuccessMessage(Constant.MESSAGES.ORDER_ADDED_SUCCESSFULLY.msg);
            let navigationExtras: NavigationExtras = {
              queryParams: { msg: 'add-order-success' }
            };
            this._router.navigate(['/order-summary'], navigationExtras);
          }
        },
        err => {
          this._logger.debug('error was ' + err);
          this._logger.error(Constant.submitLogMsg);
        }
      );

    this.disableSpinner();
  }
  /* End time validation Monika*/
  timevalidator() {
    let endTimeFields = document.getElementsByClassName('deliveryEndTimeClass') as HTMLCollectionOf<HTMLInputElement>;
    let errorFlag = false;
    let endTimeIndex = 0;
    for (let i = 0; i < endTimeFields.length; i++) {
      let endTimeText = this.getTimeFromTimeSlot(this.addOrderForm.get('timeSlots'), endTimeIndex, 'end');
      let errorDiv = endTimeFields[i].parentElement.nextElementSibling;
      if (i % 2 != 0) {
        endTimeIndex++;
      }
      if (endTimeText.trim() == '') {
        errorDiv.textContent = Constant.END_ERROR_MESSAGE;
        document.getElementById('endTime' + (endTimeIndex - 1)).focus();
        errorFlag = true;
      } else {
        errorDiv.textContent = '';
      }
    }
    return errorFlag;
  }
  /* Compare time validation Monika*/
  clearTimeMessages(event: any) {
    if (event != 'submit') {
      var getID = event.target.id;
      var timeName = getID.split('Time');
      if (timeName[0] == 'start') {
        document.getElementById('startTimeErr' + timeName[1]).innerHTML = ' ';
      } else if (timeName[0] == 'end') {
        document.getElementById('endTimeErr' + timeName[1]).innerHTML = ' ';
      }
    }
  }

  getOrderTypeValue(orderType: string) {
    if (orderType == 'Calls') {
      this.hideLocationCountyZipCode = true;
    } else {
      this.hideLocationCountyZipCode = false;
    }
    this.clearLocationControlValue();
    this.integrationList = [];
    this.filterList = [];
    this.updateLeadSourcesControl(this.integrationList.length);
    this.enableSpinner();
    const Leadsources = this.addOrderForm.get('Leadsources');
    Leadsources.setValue('');
    Leadsources.updateValueAndValidity();
    if (this.setLeadValueOnSelect != orderType) {
      this.locationList = [];
      this.locationStateCountyZipList = [];
    }
    this.setLeadValueOnSelect = orderType;
    if (orderType != '') {
      const productTypeControl = this.addOrderForm.get('ProductType');
      productTypeControl.setValue('');
      productTypeControl.updateValueAndValidity();
      this.leadSources = [];
      this.filterList = [];

      /* open get productutypes when select order type */

      this._orderService.getProductTypes(orderType).subscribe(
        res => {
          this.productType = JSON.parse(res.toString());
          this.disableSpinner();
        },
        err => {
          this._orderService.returnToErrorPage(err.error);
          this._logger.error(Constant.productTypeLogMsg);
        }
      );

      /* end get productutypes when select order type  */

      if (orderType == 'Calls') {
        /* open hide county and zipcode location */
        this.hideLocationCountyZipCode = true;
        this.resizeTableOnCallTab = true;
        /* end hide county and zipcode location */
        this.getPhoneDropDown();
        this.phoneNumberlengthError = '';
        this.HideFields = false;
        this.HideDemographics = true;
        const MaxCallCurrencyControl = this.addOrderForm.get('MaxCallCurrency');
        const PhoneNumberControl = this.addOrderForm.get('PhoneNumber');
        MaxCallCurrencyControl.setValidators([Validators.required]);
        MaxCallCurrencyControl.setValue('');
        MaxCallCurrencyControl.updateValueAndValidity();
        PhoneNumberControl.setValidators([Validators.required, Validators.minLength(10)]);
        PhoneNumberControl.setValue('');
        PhoneNumberControl.updateValueAndValidity();
      } else if (orderType == 'Leads') {
        /* open hide county and zipcode location */
        this.hideLocationCountyZipCode = false;
        this.resizeTableOnCallTab = false;
        /* open hide county and zipcode location */

        this.HideFields = true;
        this.HideDemographics = false;
        const MaxCallCurrencyControl = this.addOrderForm.get('MaxCallCurrency');
        const PhoneNumberControl = this.addOrderForm.get('PhoneNumber');
        MaxCallCurrencyControl.setValidators(null);
        MaxCallCurrencyControl.setValidators(null);
        MaxCallCurrencyControl.setValue('');
        MaxCallCurrencyControl.updateValueAndValidity();
        PhoneNumberControl.setValidators(null);
        PhoneNumberControl.setValue('');
        PhoneNumberControl.updateValueAndValidity();
        this.getBrokerInformation();
      } else {
        this.HideFields = false;
        this.HideDemographics = false;
      }
      this.orderTypeValue = orderType;
    } else {
      const productTypeControl = this.addOrderForm.get('ProductType');
      productTypeControl.setValue('');
      productTypeControl.updateValueAndValidity();

      this.productType = [];
      this.leadSources = [];
      this.disableSpinner();
    }
    //  this.getStateData();
    this.clearLocationControlValue();
  }
  // get product type list based on the order type value
  getProductTypeValue(event: any) {
    const Leadsources = this.addOrderForm.get('Leadsources');
    const OrderType = this.addOrderForm.get('OrderType').value;
    Leadsources.setValue('');
    Leadsources.setValidators([Validators.required]);
    Leadsources.updateValueAndValidity();
    if (event.target.value != '') {
      this.filterList = [];
      this.integrationList = [];
      this.productTypeValue = event.target.value;
      if (OrderType != '') {
        this.LeadSources();
      }
    } else {
      this.leadSources = [];
    }
  }
  /*Open Show/Hide Spinner Methods*/
  enableSpinner(): void {
    this.spinnerFlag = true;
  }

  disableSpinner(): void {
    this.spinnerFlag = false;
  }
  /*End Show/Hide Spinner Methods*/
  checkDateValidity() {
    let startDateValue = (<HTMLInputElement>document.getElementById('StartDate')).value;
    let endDateValue = (<HTMLInputElement>document.getElementById('EndDate')).value;
    if (startDateValue !== '' && !this.isValidDate(startDateValue)) {
      this.startDateErrorMsg = Constant.ENDDATEERROR;
      this.startDateValid = false;
      (<HTMLInputElement>document.getElementById('StartDate')).focus();
    } else {
      this.startDateErrorMsg = '';
      this.startDateValid = true;
    }
    if (endDateValue !== '' && !this.isValidDate(endDateValue)) {
      this.endDateErrorMsg = Constant.ENDDATEERROR;
      this.endDateValid = false;
      (<HTMLInputElement>document.getElementById('EndDate')).focus();
    } else {
      this.endDateErrorMsg = '';
      this.endDateValid = true;
    }
  }
  /* Start - Method for Start and End Date Validation- Shivang_20-12-19*/
  validateDate(event: any): void {
    this.checkDateValidity();
    this.errorMessage = '';
    this.errorMessageStartDate = '';
    this.errorMessageStartDatelimit = '';
    this.errorMessagelimit = '';
    let inputStartDate = this.StartDate;
    let inputEndDate = this.EndDate;

    inputStartDate = this._datePipe.transform(inputStartDate, 'yyyy-MM-dd');
    inputEndDate = this._datePipe.transform(inputEndDate, 'yyyy-MM-dd');
    if (inputStartDate != null) {
      /*date validation changes -monika*/
      this.startDateErrorMsg = '';
      this.startDateValid = true;
      let todayDate;
      todayDate = this._datePipe.transform(this.currentDate, 'yyyy-MM-dd');
      if (inputStartDate < todayDate) {
        /*date validation changes -monika*/
        this.errorMessageStartDatelimit = Constant.DATELIMITERROR;
        this.startDateLimitFlag = false;
      } else {
        this.errorMessageStartDatelimit = '';
        this.startDateLimitFlag = true;
      }
    }

    if (inputEndDate != null) {
      /*date validation changes -monika*/
      this.endDateErrorMsg = '';
      this.endDateValid = true;
      let todayDate = this._datePipe.transform(this.currentDate, 'yyyy-MM-dd');
      if (inputEndDate < todayDate) {
        /*date validation changes -monika*/
        this.errorMessagelimit = Constant.DATELIMITERROR;
        this.errorFlagEndDate = true;
      }
    }

    if (inputStartDate == '') {
      this.errorMessageStartDatelimit = '';
    }
    if (inputEndDate == '' || inputEndDate == null) {
      this.errorMessage = '';
      this.errorMessageStartDate = '';
      this.errorMessagelimit = '';
      this.errorFlagEndDate = false;
      this.errorFlagStartDate = false;
    }

    if (inputStartDate != null && inputEndDate != null) {
      let selectedDateField;
      //For mat input event.targetElement.name gives field name insted event.target.name-Shivang_261219
      selectedDateField = event.targetElement.name;
      if (selectedDateField == 'StartDate' && inputStartDate >= inputEndDate) {
        this.errorMessageStartDate = Constant.errorMessageStartDate;
        this.errorFlagStartDate = true;
      } else {
        this.errorFlagStartDate = false;
      }
      if (selectedDateField == 'EndDate' && inputEndDate <= inputStartDate) {
        this.errorMessage = Constant.errorMessageEndDate;
        this.errorFlagEndDate = true;
      } else {
        this.errorFlagEndDate = false;
      }
    }
  }
  /******order name validation Start******/
  validateOrderName(event: any) {
    this.orderError = '';
    this.orderName = this.trimming_fn(event.target.value);
    if (this.orderName == '' || this.orderName == null) {
      this.orderError = Constant.ORDER_NAME_MSG;
      this.SubmitErrorFlag = true;
    } else {
      this.SubmitErrorFlag = false;
    }
  }

  trimming_fn(x: any) {
    return x ? x.replace(/^\s+|\s+$/gm, '') : '';
  }
  /*****leadcap section validation start*****/
  validateMaxCallCurrency(event: any) {
    if (this.browserName == 'ie') {
      this.checkMaxCallNumber(event, 'maxCall');
    }
    this.maxcallcurrencyFlag = true;
    this.maxCallCurrencyRangeError = '';
    let maxCallCurrencyLimitError = <HTMLInputElement>document.getElementById('MaxCallCurrencyLimitError');

    this.decimalValue = this.checkDecimalValue(event.target.value);
    if (this.decimalValue) {
      this.checkMaxCallCurrencyRange = this.checkRange(event.target.value);
      if (this.checkMaxCallCurrencyRange) {
        maxCallCurrencyLimitError.setAttribute('style', 'display:none;');
        this.maxCallCurrencyRangeError = '';
        this.maxCallSubmitError = false;
      } else {
        this.maxCallCurrencyRangeError = Constant.limitCapError;
        maxCallCurrencyLimitError.setAttribute('style', 'display:block;');
        this.maxCallSubmitError = true;
      }
    } else {
      this.maxCallCurrencyRangeError = Constant.DECIMAL_MESSAGE;
      maxCallCurrencyLimitError.setAttribute('style', 'display:block;');
      this.maxCallSubmitError = true;
    }
  }

  checkDecimalValue(value: any) {
    if (value % 1 === 0) return true;
    else return false;
  }
  /* selectleadsources on pressing enterkey firefox*/
  validateEnter(e: any) {
    if (e.keyCode === 13 && navigator.userAgent.search('Firefox') >= 0) {
      this.onLeadSourceChange(e.target.value);
    }
  }
  /* selectleadsources on pressing enterkey firefox*/

  validateDailyLeadCap(event: any) {
    if (this.browserName == 'ie') {
      this.checkDailyCapNumber(event, 'daily');
    }
    this.dailyCallCapError = '';

    this.checkdailyCapRange = this.checkRange(event.target.value); //define
    let dailyCallCapLimitError = <HTMLInputElement>document.getElementById('DailyCallCapLimtError');
    if (this.checkdailyCapRange) {
      dailyCallCapLimitError.setAttribute('style', 'display:none;');
      this.compareValue('DailyCallCap', dailyCallCapLimitError);
    } else {
      this.dailyCallCapError = Constant.limitCapError;
      dailyCallCapLimitError.setAttribute('style', 'display:block;');
      this.SubmitError = true;
    }
  }

  validateHourlyLeadCap(event: any) {
    if (this.browserName == 'ie') {
      this.checkNumber(event, 'hourly');
    }
    this.hourlyCallCapError = '';
    this.checkHourlyCapRange = this.checkRange(event.target.value); //define
    let hourlyCallCapLimitError = <HTMLInputElement>document.getElementById('HourlyCallCapLimtError');
    if (this.checkHourlyCapRange) {
      hourlyCallCapLimitError.setAttribute('style', 'display:none;');
      this.compareValue('HourlyCallCap', hourlyCallCapLimitError);
    } else {
      this.hourlyCallCapError = Constant.limitCapError;
      hourlyCallCapLimitError.setAttribute('style', 'display:block;');
      this.SubmitError = true;
    }
  }

  checkRange(value: any) {
    if ((value < 1 || value > 100) && value != '') {
      return false;
    }
    return true;
  }

  compareValue(eventName: string, leadFlowError: any) {
    this.DailyCallCap = +this.addOrderForm.get('DailyCallCap').value;
    this.HourlyCallCap = +this.addOrderForm.get('HourlyCallCap').value;
    if (this.DailyCallCap > 0 && this.HourlyCallCap > 0) {
      if (eventName == 'HourlyCallCap') {
        this.checkdailyCapRange = this.checkRange(this.DailyCallCap);
        if (this.DailyCallCap < this.HourlyCallCap) {
          this.hourlyCallCapError = Constant.HOURLY_CALL_CAP_ERROR_MSG;

          if (this.checkdailyCapRange) {
            leadFlowError.setAttribute('style', 'display:block;');
            this.dailyCallCapError = '';
          } else {
            this.dailyCallCapError = Constant.limitCapError;
            leadFlowError.setAttribute('style', 'display:block;');
            this.SubmitError = true;
          }
          this.SubmitError = true;
        } else {
          if (this.checkdailyCapRange) {
            leadFlowError.setAttribute('style', 'display:block;');
            this.dailyCallCapError = '';
            this.SubmitError = false;
          }
          leadFlowError.setAttribute('style', 'display:block;');
          this.hourlyCallCapError = '';
        }
      } else {
        if (this.DailyCallCap < this.HourlyCallCap) {
          this.dailyCallCapError = Constant.DAILY_CALL_CAP_ERROR_MSG;
          leadFlowError.setAttribute('style', 'display:block;');
          this.checkHourlyCapRange = this.checkRange(this.HourlyCallCap);
          if (this.checkHourlyCapRange) {
            leadFlowError.setAttribute('style', 'display:block;');
            this.hourlyCallCapError = '';
          } else {
            this.hourlyCallCapError = Constant.limitCapError;
            leadFlowError.setAttribute('style', 'display:block;');
            this.SubmitError = true;
          }
          this.SubmitError = true;
        } else {
          if (this.checkHourlyCapRange) {
            leadFlowError.setAttribute('style', 'display:block;');
            this.hourlyCallCapError = '';
          } else {
            this.hourlyCallCapError = Constant.limitCapError;
            leadFlowError.setAttribute('style', 'display:block;');
            this.SubmitError = true;
            return;
          }
          leadFlowError.setAttribute('style', 'display:none;');
          this.dailyCallCapError = '';
          this.SubmitError = false;
        }
      }
    }
  }
  /*****leadcap section validation end*****/

  closeAlert() {
    this.isHideAlert = false;
  }
  /* open update zip code value on select state */
  updateLocationControlValue() {
    if (this.checkSelectedField === 'state') {
      const zipCodeControl = this.addOrderForm.get('ZipCode');
      const CountyControl = this.addOrderForm.get('County');
      zipCodeControl.setValue('');
      CountyControl.setValue('');
      zipCodeControl.updateValueAndValidity();
      CountyControl.updateValueAndValidity();
    }
  }
  /* clear location fields on add */
  clearLocationControlValue() {
    this.countyData = [];
    this.locationData = [];
    this.zipData = [];
    this.getStateData();
    this.clearCountyDropDown();
    const zipCodeControl = this.addOrderForm.get('ZipCode');
    const StateControl = this.addOrderForm.get('State');
    const CountyControl = this.addOrderForm.get('County');
    zipCodeControl.setValue('');
    CountyControl.setValue('');
    zipCodeControl.updateValueAndValidity();
    CountyControl.updateValueAndValidity();
  }
  /*get county and state from zipcode*/
  getStateAndCountyByZipCode(event: any) {
    this.enableSpinner();
    const stateControl = this.addOrderForm.get('State');
    const countyControl = this.addOrderForm.get('County');
    this._orderService.getStateCountyData(event).subscribe(
      res => {
        this.disableSpinner();
        this.countyData = [];
        this.locationData = [];
        let data = JSON.parse(res.toString());

        if (data == '' && event.length >= 5) {
          this.setInvalidLocation();
          this.locationErrorNull = '';
          this.locationError = Constant.ENTER_VALID_ZIPCODE_ERROR_MSG;
        } else {
          this.setLocation();
          this.locationErrorNull = '';
          this.locationError = '';
        }
        for (let i in data) {
          if (!this.countyData.some((item: any) => item.countyName == data[i].countyName)) {
            let customObj = new Location();
            customObj.countyName = data[i].countyName;
            this.countyData.push(customObj);
            this.ListCountySelected.push(customObj.countyName);
            this.CountySelector$.nzSelectService.updateListOfSelectedValue([customObj.countyName], true);
            setTimeout(() => (this.isAddCountyReady = true)); //reenable add location btn
          }

          if (!this.locationData.some((item: any) => item.state == data[i].state)) {
            let countyDataObj = new Location();
            countyDataObj.state = data[i].state;
            this.locationData.push(countyDataObj);
          }
        }

        if (data.length === 1) {
          stateControl.setValue(data[0].state);
          countyControl.setValue(data[0].countyName);
        } else {
          this.locationData = this.locationData.filter(
            (test: any, index: any, array: any) =>
              index === array.findIndex((findTest: any) => findTest.state === test.state)
          );
          this.setStateOnZipcodeSelection();
        }
      },
      err => {
        this._logger.error(Constant.stateZipCodeLogMsg);
      }
    );
  }

  setStateOnZipcodeSelection() {
    if (this.locationData.length === 1) {
      const stateControl = this.addOrderForm.get('State');
      for (let i in this.locationData) {
        stateControl.setValue(this.locationData[i].state);
      }
    }
  }

  onEnterZipCode(event: any) {
    if (
      event.keyCode != 9 &&
      event.keyCode != 37 &&
      event.keyCode != 38 &&
      event.keyCode != 39 &&
      event.keyCode != 40
    ) {
      event = event.target.value;
      if (this.checkSelectedField === 'none' || this.checkSelectedField === 'zipCode') {
        this.checkSelectedField = 'zipCode';
        const stateControl = this.addOrderForm.get('State');
        stateControl.setValue('undefined');
        const countyControl = this.addOrderForm.get('County');
        countyControl.setValue('');
        stateControl.updateValueAndValidity();
        countyControl.updateValueAndValidity();
        this.zipData = [];
        if (event.length == 5) {
          this.getStateAndCountyByZipCode(event);
        }
        if (event.length == 0) {
          this.setLocation();
          this.checkSelectedField = 'none';
          this.clearLocationControlValue();
        }
      }
    }
  }

  setLocation() {
    this.stateOptionValue = 'Select State';
    this.countyOptionValue = 'Select County';
  }

  setInvalidLocation() {
    this.stateOptionValue = 'No State';
    this.countyOptionValue = 'No County';
  }
  /*get county and state from zipcode*/
  onchangeState(event: any): void {
    this.zipData = [];
    this.countyData = [];
    this.clearCountyDropDown();
    if (this.checkSelectedField === 'none') {
      this.checkSelectedField = 'state';
    }
    this.updateLocationControlValue();
    this.isState = false;
    if (event === 'undefined') {
      if (this.checkSelectedField === 'none' || this.checkSelectedField === 'state') {
        this.checkSelectedField = 'none';
        this.countyData = [];
        this.zipData = [];
        const countyControl = this.addOrderForm.get('County');
        countyControl.setValue('');
        countyControl.updateValueAndValidity();
      }
    } else {
      const countyControl = this.addOrderForm.get('County');
      countyControl.setValue('');
      countyControl.updateValueAndValidity();
      if (
        (this.checkSelectedField === 'none' || this.checkSelectedField === 'state') &&
        this.addOrderForm.get('OrderType').value == 'Leads'
      ) {
        this.enableSpinner();
      }
    }
    if (event != 'null' && event != '' && event != 'undefined') {
      const zipCodeControl = this.addOrderForm.get('ZipCode');
      if (typeof zipCodeControl.value === 'undefined' || zipCodeControl.value === '') {
        this.isState = true;
        this.stateForService = event;
        if (this.addOrderForm.get('OrderType').value == 'Calls') {
          return;
        }
        this._orderService.getCountyData(event).subscribe(
          res => {
            this.countyData = JSON.parse(res.toString());
            this.disableSpinner();
          },
          err => {
            this._logger.error(Constant.countyLogMsg);
          }
        );
      }
    }
  }

  onchangeCounty(event: boolean): void {
    this.zipData = [];
    if (event == false && this.isReadySend && this.ListCountySelected.length > 0) {
      // finish select counties
      let countys = this.ListCountySelected;
      let counties;
      if (countys.length == 0) {
        this.disableSpinner();
      } else if (this.checkSelectedField === 'state' && countys.length > 0) {
        this.enableSpinner();
      }
      const zipCodeControl = this.addOrderForm.get('ZipCode');
      if (this.checkSelectedField == 'state') {
        zipCodeControl.setValue('');
        zipCodeControl.updateValueAndValidity();
      } else {
        if (this.locationData.length === 1) {
          const stateControl = this.addOrderForm.get('State');
          stateControl.setValue(this.locationData[0].state);
          stateControl.updateValueAndValidity();
        }
      }

      //this.countyName = event.options[event.selectedIndex].getAttribute('data-isocode');
      this.isCounty = false;
      if (countys.length > 0) {
        this.isCounty = true;
        counties = countys.join(',');
        this._orderService.getZipData(counties, this.stateForService).subscribe(
          (res: Location[]) => {
            this.zipData = res;
            this.disableSpinner();
            this.isAddCountyReady = true;
          },
          err => {
            this._logger.error(Constant.zipcodeLogMsg);
          }
        );
      }
    }
  }
  /* Open - Insert Location List */
  insertLocationList(state: string, county: string, zipCode: string, countyList: Location[]) {
    let OrderTypeEdit = this.addOrderForm.get('OrderType').value;
    if (OrderTypeEdit == 'Calls') {
      if (!this.locationList.some(item => item.state.toString().trim() == state.toString().trim())) {
        let callLocationListEdit = new Location();
        callLocationListEdit.state = state;
        this.locationList.push(callLocationListEdit);
      } else {
        this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
      }
    } else {
      for (let county of countyList) {
        if (
          this.locationList.some(
            item =>
              item.state.toString().trim() == state.toString().trim() &&
              item.countyName.toString().trim() == county.countyName.toString().trim()
          )
        ) {
          let countyRows = this.locationList.filter(i => i.countyName == county.countyName).length;
          for (var i = 0; i < countyRows; i++) {
            let indexCounty = this.locationList.findIndex(i => i.countyName == county.countyName);
            if (indexCounty > -1) this.locationList.splice(indexCounty, 1);
          }
        }
      }

      let dataLocationListEdit = new Location();
      dataLocationListEdit.state = state;
      dataLocationListEdit.countyName = 'All Counties';
      dataLocationListEdit.zipCode = null;
      if (
        this.locationList.some(
          i => i.state == dataLocationListEdit.state && i.countyName == dataLocationListEdit.countyName
        ) == false
      ) {
        this.locationList.push(dataLocationListEdit);
      } else {
        this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
      }
    }
  }
  /* End - Insert Location List */
  insertLocationStateCountyZipList(state: string, county: string[], zipCode: string) {
    if (zipCode == '' && county.length == 0 && state != 'undefined') {
      if (state != '' && !this.locationStateCountyZipList.some(item => item == state)) {
        this.locationStateCountyZipList.push(state);
      }
    }
  }

  insertDeleteLocationList(str: string) {
    let indexState = this.locationStateCountyZipList.indexOf(str);
    if (indexState >= 0) this.locationStateCountyZipList.splice(this.locationStateCountyZipList.indexOf(str), 1);
  }
  /* Open - Add location list in New Order */
  addLocation(state: string, county: string[], zipCode: string) {
    this.insertLocationStateCountyZipList(state, county, zipCode);
    this.locationErrorNull = '';
    this.showAllZipCode = false;
    let countiestr = county.join(',');
    if (state == null || state === '' || state === 'undefined') {
      this.locationError = Constant.STATE_COUNTY_REQ_ERROR_MSG;
      this.addOrderForm.get('State').setErrors({ required: true });
    } else {
      //force user to select order type to get correct location value
      let orderType = this.addOrderForm.get('OrderType').value;
      if (orderType == '') {
        this.locationError = Constant.locationErrorForOrderType;
        return;
      }
      //force user to select order type to get correct location value

      if (county == null || county.length == 0) {
        if (this.locationList.some(location => location.state === state)) {
          this.filteredCounties = this.countyData.filter(
            (counties: any) => !this.locationList.some(location => location.countyName === counties)
          );
          if (Array.isArray(this.filteredCounties) && this.filteredCounties.length) {
            if (zipCode) {
              this.locationError = Constant.SELECT_COUNTY_ERROR_MSG;
            } else {
              this.insertLocationList(state, countiestr, zipCode.toString(), this.filteredCounties);
              this.checkSelectedField = 'none';
            }
          } else {
            this.locationError = Constant.STATE_ALREADY_EXIST;
          }
        } else {
          if (this.checkSelectedField === 'state') {
            this.insertLocationList(state, countiestr, zipCode.toString(), this.countyData);
            this.checkSelectedField = 'none';
          } else {
            this.locationError = Constant.SELECT_COUNTY_ERROR_MSG;
          }
        }
      } else {
        if (
          this.locationList.some(
            location =>
              location.state === state &&
              location.countyName === 'All Counties' &&
              location.zipCode == null &&
              (zipCode == null || zipCode.toString() == '')
          )
        ) {
          this.locationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.locationList.some(
            location =>
              location.state === state &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              location.zipCode == null &&
              (zipCode == null || zipCode.toString() == '') &&
              (location.zipCode == null || location.zipCode.toString() == '')
          )
        ) {
          this.locationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.locationList.some(
            location =>
              location.state === state &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              (zipCode == null || zipCode.toString() == '') &&
              (location.zipCode == null || location.zipCode.toString() == '')
          )
        ) {
          this.locationError = Constant.STATE_COUNTY_EXIST_ERROR_MSG;
        } else if (
          this.locationList.some(
            location =>
              location.state.toLowerCase() === state.toLowerCase() &&
              countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
              location.zipCode === +zipCode
          )
        ) {
          this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
        } else {
          if (this.checkSelectedField === 'state') {
            if (zipCode) {
              if (
                !this.locationList.some(
                  location =>
                    location.state.toLowerCase().trim() === state.toLowerCase().trim() &&
                    countiestr.toLowerCase().indexOf(location.countyName.toLowerCase()) > -1 &&
                    location.zipCode === +zipCode
                )
              ) {
                if (this.zipData.some((zipList: any) => zipList.zipCode == zipCode)) {
                  this.insertLocationOnAddLocation(state, countiestr, zipCode.toString());
                } else {
                  this.locationError = Constant.ZIPCODE_NOT_EXIST_ERROR;
                }
              } else {
                this.locationError = Constant.LOCATION_EXIST_ERROR_MSG;
              }
            } else {
              this.insertLocationOnAddLocation(state, countiestr, zipCode.toString());
            }
          } else {
            this.insertLocationOnAddLocation(state, countiestr, zipCode.toString());
          }
        }
      }
    }
    this.clearLocationControlValue();
  }
  /* open prepare list of location */
  insertLocationOnAddLocation(state: string, county: string, zipCode: string) {
    let customObj;
    let countySel: string[];
    if (
      this.locationList.some(
        location => location.state === state && location.countyName === county && location.zipCode == null
      )
    ) {
      this.locationError = Constant.ZIPCODE_EXIST_ERROR_MSG;
      return;
    } else if (
      this.locationList.some(
        location => location.state === state && location.countyName === 'All Counties' && location.zipCode == null
      )
    ) {
      this.locationError = Constant.ZIPCODE_EXIST_ERROR_MSG;
      return;
    }
    if (this.zipData.length > 0 && zipCode !== '') {
      countySel = this.zipData.filter(a => a.zipCode == +zipCode).map(i => i.countyName);
    } else if (this.zipData.length > 0 && zipCode === '') {
      // select state or county but no select zipcode
      countySel = county.split(',');
    } else {
      // case enter zipcode by typing
      countySel = county.split(',');
    }
    if (countySel.length == this.countyData.length && zipCode === '') {
      let countyLocationArr: Location[] = [];
      countySel.forEach(county => {
        customObj = new Location();
        customObj.state = state;
        customObj.countyName = county;
        customObj.zipCode = zipCode === '' ? null : +zipCode;
        countyLocationArr.push(customObj);
      });
      this.insertLocationList(state, county, zipCode, countyLocationArr);
    } else {
      countySel.forEach(county => {
        customObj = new Location();
        customObj.state = state;
        customObj.countyName = county;
        customObj.zipCode = zipCode === '' ? null : +zipCode;
        this.locationList.push(customObj);
        this.checkSelectedField = 'none';
      });
    }
  }
  // add lead sources to the filtered list
  onLeadSourceChange(leadSourceId: any) {
    this.selectedObject = this.leadSources.filter((t: any) => t.IntegrationId == leadSourceId);
    this.isDone = this.filterList.some((t: any) => t.IntegrationId == leadSourceId);
    if (!this.isDone) {
      this.integrationList.push(this.selectedObject[0].IntegrationId);
      this.filterList.push({
        IntegrationId: this.selectedObject[0].IntegrationId,
        LeadSourceName: this.selectedObject[0].LeadSourceName,
        TransactionCost: this.selectedObject[0].TransactionCost,
        TransactionPrice: this.selectedObject[0].TransactionPrice
      });
    }
    this.updateLeadSourcesControl(this.integrationList.length);
    this.selectedObject = [];
  }
  /* open get account balance*/
  getAccountBalance() {
    this._orderService.getAccountFundingBalance().subscribe((data: any) => {
      if (data != null) {
        this.balance = data;
      } else {
        this.balance = '0';
      }
    });
  }
  /* open restrict enter event */
  onKeydown(event: any) {
    return false;
  }

  onZipFocusFirefox(event: any) {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') !== -1) {
      let input = this.ZipCode$.nativeElement;
      input.addEventListener('mouseover', () => {
        input.focus();
      });
    }
  }
  // delete lead sources from the list
  deleteLeadSources(leadSourceIndex: any) {
    var leadSourcesControl = this.addOrderForm.get('Leadsources');
    this.filterList.splice(leadSourceIndex, 1);
    this.integrationList.splice(leadSourceIndex, 1);
    if (this.integrationList.length == 0) {
      this.updateLeadSourcesControl(this.integrationList.length);
    }
    leadSourcesControl.setValue('');
    leadSourcesControl.updateValueAndValidity();
  }
  /* open update lead sources control */
  updateLeadSourcesControl(length: any) {
    const LeadSourcesControl = this.addOrderForm.get('Leadsources');
    if (length > 0) {
      LeadSourcesControl.setValidators(null);
    } else {
      LeadSourcesControl.setValidators([Validators.required]);
      LeadSourcesControl.setValue('');
    }
    LeadSourcesControl.updateValueAndValidity();
  }
  /* end update lead sources control */
  deleteLocationRow(locationIndex: number, state: string) {
    let countyIndex = this.selectednumber * (this.currPageAddLocation - 1) + locationIndex;
    this.insertDeleteLocationList(state);
    if (this.locationList.length <= 1) {
      const stateControl = this.addOrderForm.get('State');
      stateControl.setValue('undefined');
      stateControl.setValidators([Validators.required]);
      stateControl.updateValueAndValidity();
    }
    this.checkSelectedField = 'state';
    this.locationList.splice(countyIndex, 1);
  }
  /* view-all drop down */
  viewZipCodes(selectedData: any, i: number, event: any) {
    this.allZipCodes = [];
    this._orderService.getZipData(selectedData.countyName, selectedData.state).subscribe(
      res => {
        this.allZipCodes = JSON.parse(res.toString());
      },
      err => {
        this._logger.error(Constant.zipCodeCountyLogMsg);
      }
    );

    this.Ziplist = <HTMLInputElement>event.target.id;
    let currentViewElement = document.getElementsByClassName(this.Ziplist) as HTMLCollectionOf<HTMLElement>;

    if (currentViewElement[0].style.display == 'none') {
      currentViewElement[0].style.display = 'block';
    } else currentViewElement[0].style.display = 'none';
  }
  /* Open - Method For Dialog Box */
  openDialog(error: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = error;
    this._matDialog.open(ErrorDailogComponent, dialogConfig);
  }
  /* Open - Method For Validation Dialog Box */
  openValidationDialog(msg: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = msg;
    this._matDialog.open(ValidationErrorDialogComponent, dialogConfig);
  }

  ngOnInit() {
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      this.autoCompleteValue = 'nope';
    }
    this.setCountyEvent();
    this.hideLocationCountyZipCode = false;
    this.isIE = this._orderService.isIEBrowser();
    this.accessToken = this._orderService.getAccessTokenFromStorage();
    if (this.accessToken != null) {
      this._orderService.authentication().subscribe(
        res => {
          console.log('response' + res);
        },
        err => {
          if (!err.error.text) {
            this._orderService.returnToErrorPage(err.error);
            this._logger.error(err.error);
          }
        }
      );
    } else {
      this._orderService.returnToErrorPage(Constant.TOKEN_MISSING_ERROR_MSG);
      this._logger.error(Constant.TOKEN_MISSING_ERROR_MSG);
    }

    this.isAllstateMA = localStorage.getItem('isAllstateMA') == 'true';

    /* Start- For Detecting browser on load */
    this.browserName = this.getBrowserName();
    if (this.browserName === 'ie') {
      this.isIeBrowser = true;
      this.isOtherBrowser = false;
    } else {
      this.isIeBrowser = false;
      this.isOtherBrowser = true;
    }
    /* End- For Detecting browser on load */

    window.scroll(0, 0);
    this.showAllZipCode = false;
    this.orderTypeError = Constant.ORDERTYPEERROR;
    this.productTypeError = Constant.PRODUCTTYPEERROR;
    this.orderNameError = Constant.ORDERNAMEERROR;
    this.startDateError = Constant.STARTDATEERROR;
    this.endDateError = Constant.ENDDATEERROR;
    this.timezoneError = Constant.TIMEZONEERROR;
    this.hourlycapError = Constant.HOURLYCAPERROR;
    this.dailycapError = Constant.DAILYCAPERROR;
    this.leadSourceError = Constant.LEADSOURCEERROR;
    this.maxCallCurrencyError = Constant.MAX_CALL_CURRENCY_ERROR_MSG;
    this.phoneNumberError = Constant.PHONE_NUMBER_ERROR_MSG;
    this.startTimeError = Constant.START_ERROR_MESSAGE;
    this.endTimeError = Constant.END_ERROR_MESSAGE;
    this.getAccountBalance();
    this.setOrderSpecificationOnOrderType();
    //this.getStateData();
    this.addOrderForm.get('PhoneNumber').valueChanges.subscribe((val: string) => {
      if (val !== '') this.validatePhoneNumber(val.toString());
    });
  }

  setOrderSpecificationOnOrderType() {
    let LeadTypeValue = this._service.getLeadType();
    let OrderControl = this.addOrderForm.get('OrderType');
    if (LeadTypeValue === 'Calls') {
      this.hideLocationCountyZipCode = true;
      this.resizeTableOnCallTab = true;
      this.HideFields = false;
      this.HideDemographics = true;
      OrderControl.setValue('Calls');
      this.getPhoneDropDown();
    } else if (LeadTypeValue === 'Leads') {
      this.hideLocationCountyZipCode = false;
      this.resizeTableOnCallTab = false;
      this.HideFields = true;
      this.HideDemographics = false;
      OrderControl.setValue('Leads');
    }
    this.getOrderTypeValue(LeadTypeValue);
  }

  /* open get location data */
  getStateData() {
    this._orderService.getLocationData(this.hideLocationCountyZipCode).subscribe(
      res => {
        this.locationData = JSON.parse(res.toString());
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.stateLogMsg);
      }
    );
  }
  onMyContactClick() {
    this.isHideAlert = true;
    this.myContactAlert = {
      type: 'warning',
      message: Constant.Manage_MYCONTACT_Message
    };
  }
  getBrokerInformation() {
    let res: brokerInfo;
    this._orderService.getBrokerInformation().subscribe(
      brokerRes => {
        res = JSON.parse(brokerRes);
        this.textEmail = !res.brokerEmail ? Constant.BrokerEmptyEmailMessage : res.brokerEmail;
        if (res.brokerEmail) {
          this.SendEmailNotification = true;
          this.chkEmailNotification = false;
          //this.addOrderForm.controls['SendEmailNotification'].enable();
        } else {
          this.SendEmailNotification = false;
          this.chkEmailNotification = true;
          //this.addOrderForm.controls['SendEmailNotification'].disable();
        }

        this.textMobile = !res.brokerMobile ? Constant.BrokerEmptyMobileMessage : res.brokerMobile;

        this.SendMobileNotification = false;
        this.chkMobileNotification = false;
        this.addOrderForm.controls['SendMobileNotification'].disable();

        /*
        if (res.brokerMobile) {
          this.SendMobileNotification = true;
          this.chkMobileNotification = false;
          // this.addOrderForm.controls['SendMobileNotification'].enable();
        } else {
          this.SendMobileNotification = false;
          this.chkMobileNotification = true;
          //this.addOrderForm.controls['SendMobileNotification'].disable();
        }
        */
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.BrokerEmailPhoneLogMsg);
      }
    );
  }
  getPhoneDropDown() {
    this._orderService.getPhoneDropDown(this.accessToken).subscribe(
      res => {
        this.iprolistphone = this.extractPhone(JSON.parse(res));
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.stateLogMsg);
      }
    );
  }
  extractPhone(res: brokerInfo) {
    let phonelist: string[] = [];
    let temphone = phoneFormat(res.brokerMobile);
    let phone = `Cell : ${temphone}`;
    phonelist.push(phone);
    temphone = phoneFormat(res.brokerPhone);
    phone = `Phone : ${temphone}`;
    phonelist.push(phone);
    return phonelist;
  }
  checkListPhone() {
    if (this.iprolistphone == null || this.iprolistphone.length == 0) {
      this.getPhoneDropDown();
    }
  }
  /**set flag**/
  clearTimeflag() {
    this.startTimeFlagErr = '';
    this.endTimeFlagErr = '';
  }
  /* end get location data */

  /* open unselect apply all checkbox */
  unSelectApplyAll(i: number) {
    var data = this.addOrderForm.get('timeSlots') as FormArray;
    var group = data.at(i) as FormGroup;
    var applyAll = group.get('AllWeek');
    if (applyAll.value == true) {
      var AllWeekControl = group.get('AllWeek');
      AllWeekControl.setValue(false);
    }
  }

  /* end unselect apply all checkbox */

  /* open set checkbox value on apply all */
  setCheckboxValue(checkBoxValue: boolean, i: number) {
    var timeslotControl = null;
    var data = this.addOrderForm.get('timeSlots') as FormArray;
    var group = data.at(i) as FormGroup;
    if (checkBoxValue == true) {
      timeslotControl = group.get('sunday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('monday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('tuesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('wednesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('thursday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('friday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
      timeslotControl = group.get('saturday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors(null);
    } else {
      timeslotControl = group.get('sunday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('monday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('tuesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('wednesday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('thursday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('friday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
      timeslotControl = group.get('saturday');
      timeslotControl.setValue(checkBoxValue);
      timeslotControl.setErrors({ required: true });
    }

    group.updateValueAndValidity();
  }

  selectAllDay(i: number): void {
    var data = this.addOrderForm.get('timeSlots') as FormArray;
    var group = data.at(i) as FormGroup;
    var applyAll = group.get('AllWeek');

    if (this.isIE == false) {
      if (applyAll.value == true) {
        this.setCheckboxValue(false, i);
      } else {
        this.setCheckboxValue(true, i);
      }
    } else {
      if (applyAll.value == false) {
        this.setCheckboxValue(false, i);
      } else {
        this.setCheckboxValue(true, i);
      }
    }
  }

  LeadSources() {
    this.enableSpinner();
    this._orderService.getFilteredLeadSources(this.accessToken, this.orderTypeValue, this.productTypeValue).subscribe(
      res => {
        this.leadSources = JSON.parse(res.toString());
        this.disableSpinner();
      },
      err => {
        this._logger.error(Constant.leadsourceLogMsg);
      }
    );
  }

  itemsPerPage(event: Event) {
    this.selectednumber = +(<HTMLInputElement>event.target).value;
  }

  deleteTimeSlot(i: number) {
    var data = this.addOrderForm.get('timeSlots') as FormArray;
    if (data.length > 1) {
      data.removeAt(i);
      this.checkBoxValidation('none');
    } else {
      this.openValidationDialog(Constant.RULE_ERROR_MESSAGE);
    }
  }
  /* active/inctive using spacebar press*/
  handleCheckBoxKeyUp(e: any) {
    if (e.keyCode === 32) {
      let checkBoxElement = e.target.previousElementSibling;
      checkBoxElement.click();
      return false;
    }
  }
  /* handle event of enter key*/
  handlekeyPressEvent(e: any) {
    if (e.keyCode === 13) {
      e.target.click();
      e.preventDefault();
    }
  }
  /* handle event of enter key*/
  checkNumber(e: any, flag: string) {
    const hourly = this.addOrderForm.get('HourlyCallCap');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'hourly'
    ) {
      hourly.setValue(Number(e.target.value) + 1);
      hourly.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'hourly'
    ) {
      hourly.setValue(1);
      hourly.updateValueAndValidity();
    } else if (e.keyCode === 38 && Number(e.target.value) >= 100 && flag == 'hourly') {
      hourly.setValue(100);
      hourly.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'hourly'
    ) {
      hourly.setValue(Number(e.target.value) - 1);
      hourly.updateValueAndValidity();
    }
  }

  checkDailyCapNumber(e: any, flag: string) {
    const daily = this.addOrderForm.get('DailyCallCap');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'daily'
    ) {
      daily.setValue(Number(e.target.value) + 1);
      daily.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1)) &&
      flag == 'daily'
    ) {
      daily.setValue(1);
      daily.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'daily') {
      daily.setValue(100);
      daily.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'daily'
    ) {
      daily.setValue(Number(e.target.value) - 1);
      daily.updateValueAndValidity();
    }
  }

  checkMaxCallNumber(e: any, flag: string) {
    const maxcall = this.addOrderForm.get('MaxCallCurrency');
    if (
      e.keyCode === 38 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) < 100 &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(Number(e.target.value) + 1);
      maxcall.updateValueAndValidity();
    } else if (
      (((e.target.value == null || e.target.value == '') && e.keyCode === 38) ||
        (e.keyCode === 40 && Number(e.target.value) <= 1 && flag == 'maxCall')) &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(1);
      maxcall.updateValueAndValidity();
    } else if (Number(e.target.value) >= 100 && e.keyCode === 38 && flag == 'maxCall') {
      maxcall.setValue(100);
      maxcall.updateValueAndValidity();
    } else if (
      e.keyCode === 40 &&
      (e.target.value != null || e.target.value != '') &&
      Number(e.target.value) > 1 &&
      flag == 'maxCall'
    ) {
      maxcall.setValue(Number(e.target.value) - 1);
      maxcall.updateValueAndValidity();
    }
  }
  /* Start- Method For Detecting browser-Shivang_10-12-19*/
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  /* Start - Method to check date validity */
  public isValidDate(dateString: any): boolean {
    let dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
    if (dateString.match(dateformat)) {
      if (moment(dateString, 'MM/DD/YYYY').isValid()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /* Start - Method to check date input chars */
  checkDateInput(event: any) {
    const dateLength = event.target.value.length;
    let charCode = event.which ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 47 || charCode > 57)) || dateLength > 9) {
      return false;
    }

    if (event.which !== 8 || event.which !== 46) {
      let dateInput = <HTMLInputElement>document.getElementById(event.target.id);
      let dateValue = dateInput.value;
      if (dateValue.length === 2 || dateValue.length === 5) {
        event.target.value = event.target.value + '/';
      }
    }
    return true;
  }
  /* Start - Method to check time input chars-Shivang_24-12-19 */
  checkTimeInput(event: any) {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
  /* Start - Method to transform date format for IE-Shivang_12-20-19 */
  transformDateFormat() {
    this.sDate = this._datePipe.transform(this.StartDate, 'yyyy-MM-dd');
    this.eDate = this._datePipe.transform(this.EndDate, 'yyyy-MM-dd');
    const startDateControl = this.addOrderForm.get('StartDate');
    const endDateControl = this.addOrderForm.get('EndDate');
    startDateControl.setValue(this.sDate);
    endDateControl.setValue(this.eDate);
    startDateControl.updateValueAndValidity();
    endDateControl.updateValueAndValidity();
  }
  /* End - Method to transform date format for IE-Shivang 12-20-19 */
  convertTime() {
    var timeSlots = this.addOrderForm.get('timeSlots') as FormArray;
    var startTime, endTime, group;
    for (let i = 0; i < timeSlots.length; i++) {
      group = timeSlots.at(i) as FormGroup;
      startTime = group.get('Start').value;
      if (startTime !== '') {
        startTime = startTime.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
        startTime = this.time24Format(startTime);
      }

      endTime = group.get('End').value;
      if (endTime !== '') {
        endTime = endTime.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
        endTime = this.time24Format(endTime);
      }

      group.patchValue({ Start: startTime, End: endTime });
      group.get('Start').setErrors(null);
      group.get('End').setErrors(null);
    }
  }

  startPickerChange(index: any) {
    this.compareTimevalidator();
  }

  endPickerChange(index: any) {
    this.compareTimevalidator();
  }

  time24Format(input: string): string {
    let suff = input.split(' ')[1];
    let hour = +input.split(':')[0];
    let min = suff != undefined ? input.split(':')[1].substr(0, 2) : input.split(':')[1];
    let hour24;
    if (suff == undefined) {
      suff = hour >= 12 ? 'PM' : 'AM';
    }
    if (suff === 'AM') {
      hour = hour == 12 ? 0 : hour;
      hour24 = hour;
    } else {
      hour24 = hour >= 12 ? hour : hour + 12;
    }
    return `${hour24}:${min}`;
  }

  compareTimevalidator() {
    var data = null;
    var dataLength = null;

    this.compareTimeErrorFlag = true;
    data = this.addOrderForm.get('timeSlots') as FormArray;
    dataLength = data.length;

    var compareTimeCount = 0;
    for (compareTimeCount = 0; compareTimeCount <= dataLength - 1; compareTimeCount++) {
      var group = data.at(compareTimeCount) as FormGroup;
      var endTime = group.get('End').value;
      if (endTime == '') {
        group.get('End').setErrors({ required: true });
      }
      var startTime = group.get('Start').value;
      if (startTime == '') {
        group.get('Start').setErrors({ required: true });
      }
      var sampleDate = '04/12/2019 ';
      var endTimeValue = new Date(sampleDate + endTime);
      var startTimeValue = new Date(sampleDate + startTime);
      var compareTimeErrorDiv = null;
      compareTimeErrorDiv = document.getElementById('errorEndTime' + compareTimeCount);
      if (endTime != '' && startTimeValue >= endTimeValue) {
        compareTimeErrorDiv.innerHTML = Constant.TIME_COMPARE_ERROR_MESSAGE;
        this.compareTimeErrorFlag = true;
      } else {
        this.compareTimeErrorFlag = false;
        compareTimeErrorDiv.innerHTML = '';
      }
    }
  }

  checkPhoneNumberKeyInput(event: any) {
    let keyCode = event.keyCode;
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      keyCode == 8 ||
      (keyCode >= 35 && keyCode <= 40) ||
      keyCode == 46 ||
      (keyCode >= 96 && keyCode <= 105)
    ) {
      if (
        event.key === '-' ||
        event.key === '@' ||
        event.key === '!' ||
        event.key === '#' ||
        event.key === '$' ||
        event.key === '%' ||
        event.key === '*' ||
        event.key === '(' ||
        event.key === '^' ||
        event.key === '_' ||
        event.key === '&' ||
        event.key === '~' ||
        event.key === ')' ||
        event.key === '_' ||
        event.key === '`' ||
        event.key === '='
      )
        return false;
      return true;
    } //
    else {
      return false;
    }
  }
  /* Start Method - Checks key input value for call caps,restricting greater than 3 digit */
  checkCallCapValueOnInput(event: any) {
    let callCapValue = event.target.value;
    const maxLength = event.target.maxLength;
    if (callCapValue.length > maxLength) {
      event.target.value = callCapValue.slice(0, maxLength);
    }
  }
  /* End Method - Checks key input value for call caps,restricting greater than 3 digit */
  setCountyEvent() {
    this.CountySelector$.registerOnChange((values: string[]) => {
      this.isAddCountyReady = false;
      if (values.indexOf('Select All') > -1) {
        this.isReadySend = false;
        this.isSelectAll = true;
        let items = this.countyData.map(a => a.countyName);
        this.ListCountySelected = items;
        if (values.length > 1) {
          values.forEach(k => {
            let index = this.ListCountySelected.findIndex(a => a == k);
            if (index > -1) this.ListCountySelected.splice(index, 1);
          });
          this.updatePanel();
        } else {
          this.updatePanel();
        }
      } else {
        this.isReadySend = true;
        this.isSelectAll = false;
        this.ListCountySelected = values;
      }
    });
  }

  private updatePanel() {
    this.CountySelector$.writeValue(this.ListCountySelected);
    this.CountySelector$.closeDropDown();
    setTimeout(() => {
      this.CountySelector$.toggleDropDown();
      this.isReadySend = true;
    }, null);
  }

  private clearCountyDropDown() {
    this.ListCountySelected = [];
    this.CountySelector$.writeValue([]);
  }
  getFormControl(name: string) {
    return this.addOrderForm.get(name);
  }
  getFormArrayControl(name: string, arrayName: string, index: number) {
    const array = this.addOrderForm.get(arrayName) as FormArray;
    return array.at(index).get(name);
  }

  isValid(name: string) {
    var e = this.getFormControl(name);
    return e && e.valid;
  }
  hasError(name: string, prop?: string) {
    let e = this.getFormControl(name);

    if (prop) {
      if (e.getError(prop) != null) return e && (this.submitted || e.dirty || e.touched) && e.getError(prop);
      else return false;
    } else {
      return e && (this.submitted || e.dirty || e.touched) && !e.valid;
    }
  }
  hasArrayError(arrayName: string, name: string, prop: string, index: number) {
    let e = this.getFormArrayControl(name, arrayName, index);

    if (prop) {
      if (e.getError(prop) != null) return e && (this.submitted || e.dirty || e.touched) && e.getError(prop);
      else return false;
    } else {
      return e && (this.submitted || e.dirty || e.touched) && !e.valid;
    }
  }
}

class LeadSourceIntegration {
  IntegrationId: string;
  LeadSourceName: string;
  TransactionCost: string;
  TransactionPrice: string;
}
