import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeadMarketplaceComponent } from './lead-marketplace.component';

import { extract } from '@app/core';

const routes: Routes = [
  {
    path: 'lead-marketplace/:accessToken',
    component: LeadMarketplaceComponent,
    data: { title: extract('lead-marketplace') }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadMarketRoutingModule {}
