// A function to format text to look like a phone number
export function phoneFormat(input: string) {
  if (input == null) return '';
  // Strip all characters from the input except digits
  input = input.replace(/\D/g, '');

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);

  // Based upon the length of the string, we add formatting as necessary
  var size = input.length;
  if (size == 0) {
    input = input;
  } else if (size < 4) {
    input = input;
  } else if (size < 7) {
    input = input.substring(0, 3) + input.substring(3, 6);
  } else {
    input = input.substring(0, 3) + ' - ' + input.substring(3, 6) + ' - ' + input.substring(6, 10);
  }
  return input;
}
export function reformatePhone(number: string) {
  let phone: string;
  if (number.indexOf(':') != -1) {
    phone = number.split(':')[1];
  } else {
    phone = number;
  }
  phone = phone.replace(/[()\s\-]+/gi, '');
  if (phone.length >= 10) {
    phone = phone.substr(0, 10);
  }

  return phone;
}
