import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class UserProvider {
  constructor(private userService: UserService) {}

  getUser(userId: string): Observable<Models.UserModel> {
    return this.userService.getUser(userId);
  }

  getUsers(): Observable<Array<Models.UserModel>> {
    return this.userService.getUsers();
  }
}
