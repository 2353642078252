import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Response } from 'selenium-webdriver/http';
import * as Constant from '../../constants/api';
import { EnvironmentService } from '@app/environment.service';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';

@Component({
  selector: 'app-ipro-test-request',
  templateUrl: './lead-marketplace-test-request.component.html',
  styleUrls: ['./lead-marketplace-test-request.component.scss']
})
export class LeadMarketTestRequestComponent implements OnInit {
  constructor(private http: HttpClient, private fb: FormBuilder, private router: Router) {}
  responseurl: any;
  Response: any;
  authUrl: any;

  iproForm = this.fb.group({
    remoteKey: [''],
    userBrokerId: ['']
  });

  ngOnInit() {
    console.log('LeadMarketTestRequestComponent here');
    //this.cfg = this.envsrv.getConfig();
    var x = 1;

    this.iproForm.patchValue({
      remoteKey: '41C82623-60AE-47AE-A983-30B15C5100A1',
      userBrokerId: '23477559'
    });

    if (EnvironmentService.config.development == true) {
      this.iproForm.patchValue({
        remoteKey: '41C82623-60AE-47AE-A983-30B15C5100A1',
        userBrokerId: '23477559'
      });
    }
  }

  isGuid(str: string): boolean {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(str);
  }

  isInteger(input: string): boolean {
    return /^\d+$/.test(input) && !isNaN(parseInt(input, 10));
  }

  leadMarketPlace() {
    var RemoteAccessKey = this.iproForm.value.remoteKey ? this.iproForm.value.remoteKey : null;
    var userBrokerId = this.iproForm.value.userBrokerId ? this.iproForm.value.userBrokerId : null;

    var data = RemoteAccessKey + '/' + userBrokerId;

    if (this.isGuid(RemoteAccessKey) && this.isInteger(userBrokerId)) {
      this.http.get(EnvironmentService.config.userControllerUrl + data).subscribe(
        responseData => {
          if (responseData['URL'] != null && responseData['AccessToken'] != null) {
            this.Response = 'Api Response';
            this.responseurl = JSON.stringify(responseData);
            this.authUrl = responseData['URL'];
          } else {
            this.Response = 'Api Response';
            this.responseurl = JSON.stringify(responseData);
            this.authUrl = '';
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
    }
  }
}
