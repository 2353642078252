export const NORECORDSTODISPLAY = 'No Records to display';
export const SAVEORDER = 'order/SaveUpdateOrder/';
export const UPDATESTATUS = 'order/UpdateOrderStatus/';
export const ORDERGETBYID = 'order/GetOrderById';
export const LEADSOURCES = 'order/GetLeadSourcess/';
export const GetOrderType = 'order/GetOrderType/';
export const LOCATIONDATA = 'order/GetStates/';
export const GETCOUNTY = 'order/GetCounty/';
export const GETSTATECOUNTY = 'order/GetStateCounty/';
export const GETZIP = 'order/GetZip/';
export const ORDER_SUMMARY_URL = 'order/GetOrderSummaryData/';
export const ACCOUNT_BALANCE_URL = 'order/GetAccountFundingBalance/';
export const ACCOUNT_PHONE_URL = 'order/GetOrderBrokerInfo/';
export const ORDER_URL = 'order/GetUserOrders/';
export const CHECK_AGREEMENT = 'order/CheckAgreement/';
export const SAVE_AGREEMENT = 'order/SaveAgreement/';
export const GETPRODUCTTYPE = 'order/GetProductType/';
export const GetFilteredLeadSources = 'order/GetFilteredLeadSources/';
export const AUTHENTICATETOKEN = 'order/AuthenticateToken/';
export const GETCLIENTLOGO = 'order/GetClientLogo/';
export const DEFAULTLOGO = '../assets/images/q-pro-large.png';
export const ISALLSTATEMASTERACCOUNT = 'order/IsAllstateMasterAccount/';
export const GETLOADINGLOGO = 'order/GetLoadingLogo/';
export const DEFAULTLOADINGLOGO = '../assets/images/quotit-logo-100px.png';
export const ALLSTATELOADINGLOGO = '../assets/images/ahsLogoBlue.png';
export const ORDERTYPEERROR = 'Order Type is required';
export const PRODUCTTYPEERROR = 'Product Type is required';
export const ORDERNAMEERROR = 'Order name is required';
export const STARTDATEERROR = 'Start date is required';
export const ENDDATEERROR = 'Please enter a valid date';
export const DATELIMITERROR = 'Past date cannot be entered';
export const TIMEZONEERROR = 'Time Zone is required';
export const HOURLYCAPERROR = 'Hourly Call Cap is required';
export const DAILYCAPERROR = 'Daily Call Cap is required';
export const LEADSOURCEERROR = 'At Least one Lead Source is required';
export const ORDERIDNOTVALID = 'Order Id is not valid';
export const errorMessageStartDate = 'Start date should be less than End date';
export const errorMessageEndDate = 'End date should be greater than start date';
export const limitCapError = 'Please Enter Number Between 1 to 100';
export const ACTIVE_STATUS = 'Active';
export const TOKEN_MISSING_ERROR_MSG = 'Please login to access it';
export const GUID_INCORRECT_ERROR_MSG = 'Access token should be of Guid type';
export const START_DATE_ERROR_MSG = 'Start date should be less than End date';
export const END_DATE_ERROR_MSG = 'End date should be greater than start date';
export const STATE_COUNTY_REQ_ERROR_MSG = 'Please select state';
export const SELECT_COUNTY_ERROR_MSG = 'Please select county';
export const LOCATION_DATA_EXIST_ERROR_MSG = 'Data already exist';
export const ENTER_VALID_ZIPCODE_ERROR_MSG = 'Please enter valid zipcode';
export const STATE_COUNTY_EXIST_ERROR_MSG = 'State and county already in the record';
export const ZIPCODE_EXIST_ERROR_MSG = 'Zipcode already exist';
export const LOCATION_EXIST_ERROR_MSG = 'Location already exist';
export const ORDER_NAME_MSG = 'Please Enter a valid order Name';
export const HOURLY_CALL_CAP_ERROR_MSG = 'Hourly Call Cap value should be less than Daily Lead Cap and between (1-100)';
export const DAILY_CALL_CAP_ERROR_MSG =
  'Daily Call Cap value should be greater than or equal to Hourly Lead Cap and between (1-100)';
export const ERROR_ON_LOCATION = 'Please add at least one Location';
export const PHONE_NUMBER_ERROR_MSG = 'Phone Number is required';
export const PHONE_NUMBER_LENGTH_ERROR_MSG = 'Atleast 10 digits are required';
export const MAX_CALL_CURRENCY_ERROR_MSG = 'Max Call Currency is required';
export const RULE_ERROR_MESSAGE = 'Atleast one rule is required';
export const START_ERROR_MESSAGE = 'Start Time is required';
export const END_ERROR_MESSAGE = 'End Time is required';
export const ORDER_COPIED_SUCCESSFULLY = 'Copy Order Created successfully';
export const ORDER_COPIED_UPDATED_SUCCESSFULLY = 'Copy Order Updated Successfully';
export const TIME_SLOT_ERROR_MESSAGE = 'Atleast One day must be selected';
export const TIME_COMPARE_ERROR_MESSAGE = 'End time should be greater';
export const SHOW_ALL_STATUS = 'Show ALL';
export const SHOW_ACTIVE_STATUS = 'Show Active Only';
export const DECIMAL_MESSAGE = 'Please do not enter Decimal values';
export const SERVER_ERROR = 'There is some problem while processing this request. Please try after sometime';
export const RECORD_PER_PAGE = 'Records Per Page:';
export const TIME_ERROR_IE = 'Please Enter a valid time';
export const ZIPCODE_NOT_EXIST_ERROR = 'Please select the valid zipcode';
export const deliver_Data_Message = 'Delivery Configurations are missing in this order';
export const Manage_Funds_Message = `To manage your account balance, go to "My Account" in iPro and in the displayed page select "Manage Account Funds"`;
export const Manage_MYCONTACT_Message = `To updated your contact information, go to "My Account" in iPro and in the displayed page select "My Contact Information"`;
export const BrokerEmptyMobileMessage =
  'To receive text notification of new lead, add a cell phone number to your contact information.';
export const BrokerEmptyEmailMessage =
  'To receive email notification of new lead, add an email address to your contact information.';
export const Age_Filter_Message = `"Please note, when choosing an age range for your leads, the age of the lead will be greater or lesser than the age limits of the range you select. For example, if you select 63-65, you will receive leads for age 64."`;
/* open  Sucess flash messages */

/* open location error messages */

export const STATE_ALREADY_EXIST = 'State is already in the record';
/* end location error messages */

/*logs messages for Application Insight start*/
export const leadsourceLogMsg = 'Error while fetching Leadsources';
export const productTypeLogMsg = 'Error while fetching product type';
export const submitLogMsg = 'Error while submit order';
export const countyLogMsg = 'Unable to get county';
export const zipcodeLogMsg = 'Unable to get zipCode';
export const zipCodeCountyLogMsg = 'Unable to fetch the Zipcodes for selected Counties';
export const stateLogMsg = 'Unable to get state';
export const BrokerEmailPhoneLogMsg = 'Unable to get broker email and phone';
export const stateZipCodeLogMsg = 'Unable to fetch the state for selected zipcode';
export const startDateLogMsg = 'Start Date value is invalid';

export const locationErrorForOrderType = 'Please select Order Type first';

/*logs messages for Application Insight end*/

export const MESSAGES = {
  ORDER_ADDED_SUCCESSFULLY: { class: 'show-success', msg: 'Order Saved Successfully' },
  ORDER_UPDATED_SUCCESSFULLY: { class: 'show-success', msg: 'Order Updated Successfully' },
  ORDER_COPIED_SUCCESSFULLY: { class: 'show-success', msg: 'Copy Order Created successfully' },
  ORDER_COPIED_UPDATED_SUCCESSFULLY: { class: 'show-success', msg: 'Copy Order Updated Successfully' }
};

/* end  Sucess flash messages */

/* Setting object for date format */

export const DATE_DISPLAY_FORMAT = 'MM/dd/yyyy';

export const MAT_DATE_FORMAT = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY'
  }
};
/* Setting object for date format */
