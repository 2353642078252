import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@app/core';
import { ShellModule } from './shell/shell.module';
import { OrderSummaryModule } from './orders/order-summary.module';
import { OrderAddModule } from './orders/order-add.module';
import { OrderEditModule } from './orders/order-edit/order-edit.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderAddRoutingModule } from './orders/order-add-routing.module';
import { OrderEditRoutingModule } from './orders/order-edit/order-edit-routing.module';
import { OrderSummaryRoutingModule } from './orders/order-summary-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatDialogModule
} from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ErrorPageModule } from './error-page/error-page.module';
import { LeadMarketRoutingModule } from './lead-marketplace/lead-marketplace-routing.module';
import { LeadMarketplaceComponent } from './lead-marketplace/lead-marketplace.component';
import { LeadMarketTestRequestComponent } from './lead-marketplace-test-request/lead-marketplace-test-request.component';
import { LeadMarketTestRequestRoutingModule } from './lead-marketplace-test-request/lead-marketplace-test-request-routing.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HeaderComponent } from './header/header.component';
import { ErrorDailogComponent } from './error-dailog/error-dailog.component';
import { ValidationErrorDialogComponent } from './validation-error-dialog/validation-error-dialog.component';
import { InterceptorService } from './services/interceptor.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as Constant from 'constants/api';
import { SharedModule } from './shared/shared.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { en_US, NzTimePickerModule, NZ_I18N, NzToolTipModule } from 'ng-zorro-antd';
import { EnvironmentService } from '@app/environment.service';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    BrowserModule,
    OrderEditRoutingModule,
    MatTableModule,
    FormsModule,
    ErrorPageModule,
    LeadMarketRoutingModule,
    LeadMarketTestRequestRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    OrderSummaryRoutingModule,
    ShellModule,
    NgxPaginationModule,
    OrderSummaryModule,
    OrderAddModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatDialogModule,
    OrderAddRoutingModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OrderEditModule,
    MatDatepickerModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    NzTimePickerModule,
    NzToolTipModule,
    BrowserAnimationsModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [
    AppComponent,
    ErrorPageComponent,
    LeadMarketplaceComponent,
    LeadMarketTestRequestComponent,
    HeaderComponent,
    FooterComponent,
    ErrorDailogComponent,
    ValidationErrorDialogComponent
  ],
  providers: [
    DatePipe,
    MatSlideToggleModule,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: Constant.MAT_DATE_FORMAT },
    {
      provide: APP_INITIALIZER,
      useFactory: environmentConfigLoader,
      deps: [EnvironmentService],
      multi: true
    }
  ],

  entryComponents: [ErrorDailogComponent, ValidationErrorDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function environmentConfigLoader(environmentService: EnvironmentService) {
  return () => environmentService.getEnvironmentConfiguration();
}
